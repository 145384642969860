import { gql } from '@apollo/client';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// helpers
import { changePositionStatus } from 'helpers/Position';
import { changeSubmissionStatus, getSubmissionCurrentStatus } from 'helpers/Submission';
// constants
import { POSITION_TYPE } from 'constants';
import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
// queries
import { PositionStatus } from 'queries/Fragments';

const UPDATE_POSITION_DUE_DATE = gql`
  mutation updatePositionDueDate($position: ID!, $dueDate: DateTime!) {
    positionUpdate(filter: { id: $position }, data: { dueOn: $dueDate }) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
    }
  }
  ${PositionStatus}
`;

export const reopenPosition = async (position, dueDate) => {
  try {
    const status = position.getStatus();
    const submissions = position.getSubmissions();

    const promises = [
      client.mutate({
        mutation: UPDATE_POSITION_DUE_DATE,
        variables: {
          position: position.id,
          dueDate,
        },
      }),
      changePositionStatus({
        positionId: position.id,
        oldStatusId: status.id,
        status: POSITION_STATUS.UNPUBLISHED,
        note: 'Re-open request',
      }),
    ];

    if (submissions.length > 0) {
      submissions.forEach(submission => {
        const submissionStatus = getSubmissionCurrentStatus(submission.statuses.items);

        if (submissionStatus.name === SUBMISSION_STATUS.NOT_SUBMITTED) {
          promises.push(
            changeSubmissionStatus({
              submissionId: submission.id,
              status: SUBMISSION_STATUS.NEW,
              oldStatusId: submissionStatus.id,
              note: 'Re-open request',
              isProposal: position.contractType.value === POSITION_TYPE.FIXED_PRICE,
            })
          );
        }
      });
    }

    await Promise.all(promises);
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};
