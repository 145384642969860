import React, { useRef } from 'react';
import type { IconType } from 'react-icons';
import { MdClose } from 'react-icons/md';
import InputBase from '@mui/material/InputBase';
import Icon from '@components/Icon';
import IconButton from '@components/IconButton';
import styles from './textInput.module.scss';

interface TextInputProps {
  id?: string;
  value: string;
  placeholder?: string;
  name?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  ariaLabel?: string;
  minRows?: number;
  icon?: IconType;
  onChange: (value: string) => void;
}

const TextInput = ({
  id,
  value,
  placeholder,
  ariaLabel,
  name,
  autoFocus = false,
  readOnly = false,
  disabled = false,
  multiline = false,
  minRows = 3,
  icon,
  onChange,
}: TextInputProps) => {
  const inputElement = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event.currentTarget.value);
  };

  const handleClear = () => {
    onChange('');

    if (inputElement.current) {
      inputElement.current.focus();
    }
  };

  return (
    <InputBase
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      autoFocus={autoFocus}
      readOnly={readOnly}
      disabled={disabled}
      multiline={multiline}
      minRows={minRows}
      onChange={handleChange}
      inputRef={inputElement}
      fullWidth
      inputProps={{ 'aria-label': ariaLabel }}
      classes={{
        root: styles.container,
        focused: styles.focused,
        disabled: styles.disabled,
        adornedEnd: styles.clearButtonVisible,
        input: styles.input,
        inputAdornedStart: styles.hasIcon,
      }}
      startAdornment={
        icon && (
          <span className={styles.icon}>
            <Icon component={icon} size="small" />
          </span>
        )
      }
      endAdornment={
        !disabled &&
        !readOnly &&
        !multiline &&
        value && <IconButton label="Clear" icon={MdClose} onClick={handleClear} size="small" />
      }
    />
  );
};

export default TextInput;
