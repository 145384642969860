import { gql } from '@apollo/client';

export const projectFragment = gql`
  fragment Project on Project {
    id
    name
    shortName
    description
    currency
    status {
      items {
        id
        status
        startDate
        endDate
      }
    }
    projectRole {
      count
      items {
        id
        role
        user {
          id
          person {
            id
            fullName
          }
          organization {
            id
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const projectShortFragment = gql`
  fragment ProjectShort on Project {
    id
    status {
      count
      items {
        id
      }
    }
    client {
      id
    }
    projectRole {
      count
      items {
        id
        projects {
          count
        }
      }
    }
  }
`;

const projectPositionFragment = gql`
  fragment ProjectPositionInfo on Project {
    id
    name
    currency
    client {
      id
      config
    }
    clearanceSpecificToPosition
    levels(sort: { order: ASC }) {
      items {
        id
        order
        yearsOfExperience
        title
      }
    }
    shProjectId
    projectOccupations {
      id
      occupations(sort: { number: ASC }) {
        items {
          id
          name
          number
          skills {
            items {
              id
              label
              description
            }
          }
          activities {
            items {
              id
              label
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_PEOPLE = gql`
  query peopleList($inputValue: String!, $first: Int!, $skip: Int!) {
    peopleList(
      first: $first
      skip: $skip
      orderBy: [fullName_ASC]
      filter: {
        AND: [
          { personUser: { id: { is_not_empty: true } } }
          { personUser: { status: { not_equals: "DELETED" } } }
          { fullName: { contains: $inputValue } }
          { personUser: { supplier: { company: { name: { contains: $inputValue } } } } }
        ]
      }
    ) {
      count
      items {
        id
        fullName
        personUser {
          id
          supplier {
            id
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query project($id: ID!) {
    project(id: $id) {
      showTargetRatesToPartners
      showNteRatesToPartners
      allowSubmissionAboveTargetRates
      ...Project
    }
  }
  ${projectFragment}
`;

export const GET_PROJECT_SHORT = gql`
  query project($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectShort
    }
  }
  ${projectShortFragment}
`;

export const GET_PROJECT_INFO = gql`
  query project($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectPositionInfo
    }
  }
  ${projectPositionFragment}
`;

export const CREATE_PROJECT = gql`
  mutation createProject(
    $shortName: String!
    $name: String!
    $client: ID!
    $description: String
    $currency: String!
    $startDate: DateTime!
    $endDate: DateTime
    $shProjectId: String!
    $showTargetRatesToPartners: Boolean!
    $showNteRatesToPartners: Boolean!
    $allowSubmissionAboveTargetRates: Boolean!
    $clearanceCollectionId: ID!
    $occupationCollectionId: ID!
    $experienceLevels: [ExperienceLevelKeyFilter!]
    $config: JSON!
  ) {
    projectCreate(
      data: {
        shProjectId: $shProjectId
        shortName: $shortName
        name: $name
        description: $description
        currency: $currency
        status: { create: { status: "ACTIVE", startDate: $startDate, endDate: $endDate } }
        client: { connect: { id: $client } }
        showTargetRatesToPartners: $showTargetRatesToPartners
        showNteRatesToPartners: $showNteRatesToPartners
        allowSubmissionAboveTargetRates: $allowSubmissionAboveTargetRates
        clearanceLevelCollection: { connect: { id: $clearanceCollectionId } }
        projectOccupations: { connect: { id: $occupationCollectionId } }
        levels: { connect: $experienceLevels }
        config: $config
      }
    ) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $id: ID!
    $shortName: String
    $name: String
    $description: String
    $currency: String
    $showTargetRatesToPartners: Boolean!
    $showNteRatesToPartners: Boolean!
    $allowSubmissionAboveTargetRates: Boolean!
  ) {
    projectUpdate(
      data: {
        shortName: $shortName
        name: $name
        description: $description
        currency: $currency
        showTargetRatesToPartners: $showTargetRatesToPartners
        showNteRatesToPartners: $showNteRatesToPartners
        allowSubmissionAboveTargetRates: $allowSubmissionAboveTargetRates
      }
      filter: { id: $id }
    ) {
      ...Project
    }
  }
  ${projectFragment}
`;

export const GET_PROJECT_ROLES = gql`
  query projectRoles($filter: ProjectRoleFilter) {
    projectRolesList(filter: $filter) {
      count
      items {
        id
        role
        projects {
          count
          items {
            id
            name
          }
        }
        user {
          id
          person {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const CREATE_PROJECT_ROLE = gql`
  mutation projectRoleCreate($role: String!, $userId: ID!, $projectId: ID!) {
    projectRoleCreate(
      data: {
        role: $role
        projects: { connect: { id: $projectId } }
        user: { connect: { id: $userId } }
      }
    ) {
      id
    }
  }
`;

export const createProjectRoleMutation = (projectId, role, userId) => {
  return `
    create_${role}_${userId}: projectRoleCreate(
        data: {
          role: "${role}"
          projects: { connect: { id: "${projectId}" } }
          user: { connect: { id: "${userId}" } }
        }
      ) {
        id
      }
    `;
};

export const ADD_PROJECT_TO_PROJECT_ROLE = gql`
  mutation projectRoleUpdate($projectRoleId: ID!, $projectId: ID!) {
    projectRoleUpdate(
      filter: { id: $projectRoleId }
      data: { projects: { connect: { id: $projectId } } }
    ) {
      id
    }
  }
`;

export const addProjectRoleMutation = (projectId, projectRoleId) => {
  return `
    update_${projectRoleId}: projectRoleUpdate(
          filter: { id: "${projectRoleId}" }
          data: { projects: { connect: { id: "${projectId}" } } }
        ) {
          id
        }
    `;
};

export const removeProjectFromProjectRole = (projectId, projectRoleId) => {
  return `
    removeProject_${projectRoleId}: projectRoleUpdate(
          filter: { id: "${projectRoleId}" }
          data: { projects: { disconnect: { id: "${projectId}" } } }
        ) {
          id
        }
    `;
};

export const deleteProjectRole = id => {
  return `
    deleteProjectRole_${id}: projectRoleDelete(
          filter: { id: "${id}" }
        ) {
          success
        }
    `;
};

export const deleteProjectStatusMutation = id => {
  return `
  deleteProjectStatus_${id}:
    projectStatusDelete(filter: { id: "${id}" }) {
      success
    }
  `;
};

export const deleteProjectMutation = id => {
  return `
  deleteProject_${id}:
    projectDelete(filter: { id: "${id}" }) {
      success
    }
  `;
};

export const DELETE_PROJECT = gql`
  mutation projectDelete($id: ID!) {
    projectDelete(filter: { id: $id }) {
      success
    }
  }
`;
