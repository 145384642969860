import getProperty from 'lodash/get';
import { boxFileDir } from 'helpers/Box';
// services
import { upload } from 'services/Box';
// constants
import { REVISION_TYPES } from 'constants';

const uploadFiles = async (formData, revision) => {
  const rfq = getProperty(revision, 'position.rfq.name', '');
  const clientName = getProperty(revision, 'submission.position.project.client.name', '');
  const project = getProperty(revision, 'submission.position.project.name', '');
  const fileDir = boxFileDir({ client: clientName, project, rfq });
  const uploadedFiles = {};
  const fileValue = getProperty(formData, [
    REVISION_TYPES.ATTESTATION,
    'revisedFile',
    'value',
    'file',
    '0',
  ]);
  const existingBoxId = getProperty(formData, [REVISION_TYPES.ATTESTATION, 'boxFileId']);

  if (existingBoxId) {
    return {
      [REVISION_TYPES.ATTESTATION]: {
        type: REVISION_TYPES.ATTESTATION,
        boxFileId: existingBoxId,
        fileName: getProperty(fileValue, 'file.name', ''),
      },
    };
  }

  if (fileValue) {
    const fileName = getProperty(fileValue, 'file.name', '');
    const file = getProperty(fileValue, 'file', {});
    const boxFileId = await upload(file, fileName, fileDir);

    uploadedFiles[REVISION_TYPES.ATTESTATION] = {
      type: REVISION_TYPES.ATTESTATION,
      boxFileId,
      fileName,
    };
  }

  return uploadedFiles;
};

export default uploadFiles;
