import { gql } from '@apollo/client';

export const getAutocompleteQuery = type => {
  return `query all($inputValue: String!, $first: Int!, $skip: Int!){
    ${type}(first: $first, skip: $skip,  orderBy: [name_ASC], filter: { name: { contains: $inputValue } }){
      items{
        id
        name
      }
      count
    }
  }`;
};

export const GET_LOCATION = gql`
  query locationsList($inputValue: String!, $first: Int!, $skip: Int!) {
    locationsList(
      first: $first
      skip: $skip
      sort: { city: ASC }
      filter: { city: { contains: $inputValue } }
    ) {
      count
      items {
        id
        city
        country {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_PARTNERS = gql`
  query getProjectPartners($projects: [ID!]!, $first: Int!, $skip: Int!, $inputValue: String!) {
    suppliersList(
      first: $first
      skip: $skip
      sort: { company: { name: ASC } }
      filter: {
        company: { name: { contains: $inputValue } }
        users: {
          some: {
            projectRoles: {
              some: {
                AND: [
                  { projects: { some: { id: { in: $projects } } } }
                  { role: { equals: "SUPPLIER" } }
                ]
              }
            }
          }
        }
      }
    ) {
      items {
        id
        company {
          id
          name
        }
      }
    }
  }
`;
