import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import moment from 'moment';
// helpers
import parsePosition from 'helpers/PositionParser';
// queries
import { PositionStatus } from 'queries/Fragments';
import { GET_POSITION_BY_ID } from 'queries/PositionQueries';
import { GET_POSITION_STATUS } from 'queries/PositionSettingQueries';
// constants
import { POSITION_STATUS } from 'constants/positionStatus';
import { STAFF_ROLE, ACTOR_REQUESTER, NOTE_POSITION_STATUS } from 'constants';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const UPDATE_POSITION_STATUS = gql`
  mutation updatePositionStatus($positionId: ID!, $data: PositionUpdateInput!) {
    positionUpdate(filter: { id: $positionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
    }
  }
  ${PositionStatus}
`;

/**
 * Change position status
 * @param {object} params
 * @param {string} params.positionId
 * @param {string} params.status
 * @param {string} params.oldStatusId
 * @param {string} [params.type]
 * @param {string} [params.note]
 * @param {object} [options] Options for graphql mutation
 */
export const changePositionStatus = (params, options = {}) => {
  const data = {
    statuses: {
      update: {
        filter: { id: params.oldStatusId },
        data: { end: moment().toISOString() },
      },
      create: { name: params.status, start: moment().toISOString() },
    },
  };

  if (params.note) {
    data.statuses.create.note = {
      create: {
        noteType: NOTE_POSITION_STATUS,
        note: params.note,
      },
    };
  }

  return client.mutate({
    mutation: UPDATE_POSITION_STATUS,
    variables: {
      positionId: params.positionId,
      data,
    },
    update: cache => {
      // remove cached position lists
      cache.evict({ fieldName: 'positionsList' });
      cache.gc();
    },
    ...options,
  });
};

export const formatLocation = position => {
  if (position.isOffsite()) {
    return position.delivery.label;
  }

  if (!position.location.country || !position.location.city) {
    return '';
  }

  return `${position.location.country}, ${position.location.city}`;
};

/**
 * Get current statuses of position from DB
 * @param {string} id - Position id
 * @returns {string} Position current status
 */
export const fetchPositionStatus = async id => {
  try {
    const response = await client.query({
      query: GET_POSITION_STATUS,
      variables: { id },
      fetchPolicy: 'network-only',
    });

    const statuses = getProperty(response, 'data.position.statuses.items', []);
    const currentStatus = statuses.find(status => !status.end);

    return currentStatus ? currentStatus.name : '';
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

/**
 * Check in DB if position is still published
 * @param {string} id Position id
 */
export const isPositionPublished = async id => {
  const status = await fetchPositionStatus(id);

  return status === POSITION_STATUS.PUBLISHED;
};

/**
 * Check in DB if position is still open
 * @param {string} id Position id
 */
export const isPositionOpen = async id => {
  const status = await fetchPositionStatus(id);

  return [POSITION_STATUS.PUBLISHED, POSITION_STATUS.UNPUBLISHED].includes(status);
};

export const isCandidateSubmittableForPosition = (positionId, role) => {
  // For MM role allow user to submit candidate
  // when position is open regardless of publish status
  return role === STAFF_ROLE ? isPositionOpen(positionId) : isPositionPublished(positionId);
};

/**
 * Remove position labels from search text if it includes any of them
 * @param {string} searchText - text from search input
 * @param {Client[]} clients - array of clients that consist of objects with config values
 */
export const getParsedSearchText = (searchText, clients = []) => {
  const positionLabels = clients
    .map(clientItem => getProperty(clientItem, 'config.positionLabel'))
    .filter(Boolean);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < positionLabels.length; i++) {
    const label = positionLabels[i].toLowerCase();
    if (searchText.toLowerCase().startsWith(label)) {
      return searchText.substring(label.length + 1, searchText.length);
    }
  }

  return searchText;
};

export const getPositionActors = position => {
  const actors = [position.getPositionRole(ACTOR_REQUESTER)];

  return actors.filter(Boolean);
};

/**
 * Fetch and parser position
 * @param {string} id Position id
 * @param {object} [fetchOptions={}]
 * @returns {Promise<?object>}
 */
export const fetchPosition = async (id, fetchOptions = {}) => {
  try {
    const response = await client.query({
      query: GET_POSITION_BY_ID,
      variables: { id },
      ...fetchOptions,
    });

    if (!response.data.position) {
      return null;
    }

    return parsePosition(response.data.position);
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

/**
 * Get RFQ label. ID label + RFQ number
 * @param {string} rfq RFQ number
 * @param {object} config Position client or project config
 * @returns {string}
 */
export const getRfqLabel = (rfq, config) => {
  const positionLabel = getProperty(config, 'positionLabel', '');

  return positionLabel ? `${positionLabel} ${rfq}` : rfq;
};
