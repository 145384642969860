/* eslint-disable */
// Generated by 'graphql-codegen'. DO NOT EDIT.
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  JSON: { input: Record<string, any>; output: Record<string, any> };
};

export type AggregationFunctionType =
  | 'ANY_VALUE'
  | 'AVG'
  | 'COUNT'
  | 'GROUP_CONCAT'
  | 'MAX'
  | 'MIN'
  | 'STDDEV_POP'
  | 'STDDEV_SAMP'
  | 'SUM'
  | 'VAR_POP'
  | 'VAR_SAMP';

export type AlakaUser = {
  __typename?: 'AlakaUser';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  language: Maybe<Scalars['String']['output']>;
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  lastViewed: Maybe<Scalars['JSON']['output']>;
  organization: Maybe<Organization>;
  person: Maybe<Person>;
  status: Maybe<Scalars['String']['output']>;
  supplier: Maybe<Supplier>;
  systemRole: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<User>;
};

export type AlakaUserFilter = {
  AND?: InputMaybe<Array<AlakaUserFilter>>;
  OR?: InputMaybe<Array<AlakaUserFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  language?: InputMaybe<StringPredicate>;
  lastLogin?: InputMaybe<DateTimePredicate>;
  lastRoleUsed?: InputMaybe<ProjectRoleFilter>;
  notes?: InputMaybe<NoteRelationFilter>;
  organization?: InputMaybe<OrganizationFilter>;
  person?: InputMaybe<PersonFilter>;
  positionRole?: InputMaybe<PositionRoleRelationFilter>;
  projectRoles?: InputMaybe<ProjectRoleRelationFilter>;
  revision?: InputMaybe<RevisionFilter>;
  status?: InputMaybe<StringPredicate>;
  supplier?: InputMaybe<SupplierFilter>;
  systemRole?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
  userCandidateSubmission?: InputMaybe<SubmissionRoleRelationFilter>;
  userDraft?: InputMaybe<DraftRelationFilter>;
  userGroup?: InputMaybe<UserGroupRelationFilter>;
};

export type AlakaUserGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  attachments?: InputMaybe<AttachmentGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  language?: InputMaybe<Array<GroupByField>>;
  lastLogin?: InputMaybe<Array<GroupByField>>;
  lastRoleUsed?: InputMaybe<ProjectRoleGroupByQuery>;
  lastViewed?: InputMaybe<Array<GroupByField>>;
  notes?: InputMaybe<NoteGroupByQuery>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  person?: InputMaybe<PersonGroupByQuery>;
  positionRole?: InputMaybe<PositionRoleGroupByQuery>;
  projectRoles?: InputMaybe<ProjectRoleGroupByQuery>;
  revision?: InputMaybe<RevisionGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  systemRole?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
  userCandidateSubmission?: InputMaybe<SubmissionRoleGroupByQuery>;
  userDraft?: InputMaybe<DraftGroupByQuery>;
  userGroup?: InputMaybe<UserGroupGroupByQuery>;
};

export type AlakaUserRelationFilter = {
  every?: InputMaybe<AlakaUserFilter>;
  none?: InputMaybe<AlakaUserFilter>;
  some?: InputMaybe<AlakaUserFilter>;
};

export type AlakaUserSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  lastRoleUsed?: InputMaybe<ProjectRoleSort>;
  organization?: InputMaybe<OrganizationSort>;
  person?: InputMaybe<PersonSort>;
  revision?: InputMaybe<RevisionSort>;
  status?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierSort>;
  systemRole?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

export type ApiTokenFilter = {
  AND?: InputMaybe<Array<ApiTokenFilter>>;
  OR?: InputMaybe<Array<ApiTokenFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<RoleRelationFilter>;
  token?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ApiTokenGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  roles?: InputMaybe<RoleGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ApiTokenRelationFilter = {
  every?: InputMaybe<ApiTokenFilter>;
  none?: InputMaybe<ApiTokenFilter>;
  some?: InputMaybe<ApiTokenFilter>;
};

export type Attachment = {
  __typename?: 'Attachment';
  _description: Maybe<Scalars['String']['output']>;
  author: Maybe<AlakaUser>;
  boxId: Maybe<Scalars['String']['output']>;
  candidateReport: Maybe<CandidateReport>;
  contractor: Maybe<Contractor>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  hiringAttachments: Maybe<HiringProcess>;
  id: Maybe<Scalars['ID']['output']>;
  import: Maybe<Import>;
  name: Maybe<Scalars['String']['output']>;
  newBoxId: Maybe<Scalars['String']['output']>;
  pendingReport: Maybe<PendingReportListResponse>;
  positions: Maybe<Position>;
  proposal: Maybe<Proposal>;
  purchaseOrderFile: Maybe<PurchaseOrder>;
  reportCV: Maybe<Report>;
  revisionType: Maybe<RevisionType>;
  submission: Maybe<Submission>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type AttachmentpendingReportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PendingReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<PendingReportGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PendingReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PendingReportSort>>;
};

export type AttachmentFilter = {
  AND?: InputMaybe<Array<AttachmentFilter>>;
  OR?: InputMaybe<Array<AttachmentFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<AlakaUserFilter>;
  boxId?: InputMaybe<StringPredicate>;
  candidateReport?: InputMaybe<CandidateReportFilter>;
  contractor?: InputMaybe<ContractorFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  hiringAttachments?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  import?: InputMaybe<ImportFilter>;
  name?: InputMaybe<StringPredicate>;
  newBoxId?: InputMaybe<StringPredicate>;
  pendingReport?: InputMaybe<PendingReportRelationFilter>;
  positions?: InputMaybe<PositionFilter>;
  proposal?: InputMaybe<ProposalFilter>;
  purchaseOrderFile?: InputMaybe<PurchaseOrderFilter>;
  reportCV?: InputMaybe<ReportFilter>;
  revisionType?: InputMaybe<RevisionTypeFilter>;
  submission?: InputMaybe<SubmissionFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AttachmentGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: AttachmentGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AttachmentGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  author?: InputMaybe<AlakaUserGroupByQuery>;
  boxId?: InputMaybe<Array<GroupByField>>;
  candidateReport?: InputMaybe<CandidateReportGroupByQuery>;
  contractor?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  hiringAttachments?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  import?: InputMaybe<ImportGroupByQuery>;
  name?: InputMaybe<Array<GroupByField>>;
  newBoxId?: InputMaybe<Array<GroupByField>>;
  pendingReport?: InputMaybe<PendingReportGroupByQuery>;
  positions?: InputMaybe<PositionGroupByQuery>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  purchaseOrderFile?: InputMaybe<PurchaseOrderGroupByQuery>;
  reportCV?: InputMaybe<ReportGroupByQuery>;
  revisionType?: InputMaybe<RevisionTypeGroupByQuery>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type AttachmentListResponse = {
  __typename?: 'AttachmentListResponse';
  count: Scalars['Int']['output'];
  items: Array<Attachment>;
};

export type AttachmentOrderBy =
  | 'boxId_ASC'
  | 'boxId_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'newBoxId_ASC'
  | 'newBoxId_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type AttachmentRelationFilter = {
  every?: InputMaybe<AttachmentFilter>;
  none?: InputMaybe<AttachmentFilter>;
  some?: InputMaybe<AttachmentFilter>;
};

export type AttachmentSort = {
  author?: InputMaybe<AlakaUserSort>;
  boxId?: InputMaybe<SortOrder>;
  candidateReport?: InputMaybe<CandidateReportSort>;
  contractor?: InputMaybe<ContractorSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  hiringAttachments?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  import?: InputMaybe<ImportSort>;
  name?: InputMaybe<SortOrder>;
  newBoxId?: InputMaybe<SortOrder>;
  positions?: InputMaybe<PositionSort>;
  proposal?: InputMaybe<ProposalSort>;
  purchaseOrderFile?: InputMaybe<PurchaseOrderSort>;
  reportCV?: InputMaybe<ReportSort>;
  revisionType?: InputMaybe<RevisionTypeSort>;
  submission?: InputMaybe<SubmissionSort>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AuthenticationProfileFilter = {
  AND?: InputMaybe<Array<AuthenticationProfileFilter>>;
  OR?: InputMaybe<Array<AuthenticationProfileFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  databaseName?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  domain?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  managementDomain?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<RoleRelationFilter>;
  secret?: InputMaybe<StringPredicate>;
  selfSignUpEnabled?: InputMaybe<BoolPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AuthenticationProfileGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  audiences?: InputMaybe<Array<GroupByField>>;
  clientId?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  databaseName?: InputMaybe<Array<GroupByField>>;
  domain?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  managementDomain?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  roles?: InputMaybe<RoleGroupByQuery>;
  secret?: InputMaybe<Array<GroupByField>>;
  selfSignUpEmailDomains?: InputMaybe<Array<GroupByField>>;
  selfSignUpEnabled?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type AuthenticationProfileRelationFilter = {
  every?: InputMaybe<AuthenticationProfileFilter>;
  none?: InputMaybe<AuthenticationProfileFilter>;
  some?: InputMaybe<AuthenticationProfileFilter>;
};

export type Availability = {
  __typename?: 'Availability';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  leadTimeFromSelection: Maybe<Scalars['String']['output']>;
  proposal: Maybe<Proposal>;
  start: Maybe<Scalars['Date']['output']>;
  submission: Maybe<Submission>;
  timeUnits: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type AvailabilityFilter = {
  AND?: InputMaybe<Array<AvailabilityFilter>>;
  OR?: InputMaybe<Array<AvailabilityFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  leadTimeFromSelection?: InputMaybe<StringPredicate>;
  proposal?: InputMaybe<ProposalFilter>;
  start?: InputMaybe<DatePredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  timeUnits?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AvailabilityGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  leadTimeFromSelection?: InputMaybe<Array<GroupByField>>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  start?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  timeUnits?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type AvailabilitySort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  leadTimeFromSelection?: InputMaybe<SortOrder>;
  proposal?: InputMaybe<ProposalSort>;
  start?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SubmissionSort>;
  timeUnits?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BigIntPredicateHaving = {
  AND?: InputMaybe<Array<BigIntPredicateHaving>>;
  OR?: InputMaybe<Array<BigIntPredicateHaving>>;
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not_equals?: InputMaybe<Scalars['BigInt']['input']>;
  not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type BoolPredicate = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolPredicateHaving = {
  AND?: InputMaybe<Array<BoolPredicateHaving>>;
  OR?: InputMaybe<Array<BoolPredicateHaving>>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Candidate = {
  __typename?: 'Candidate';
  _description: Maybe<Scalars['String']['output']>;
  availableFrom: Maybe<Scalars['Date']['output']>;
  bulkMatchFailed: Maybe<Scalars['Boolean']['output']>;
  bulkMatchProcessId: Maybe<Scalars['String']['output']>;
  consentDate: Maybe<Scalars['DateTime']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deleted: Maybe<Scalars['Boolean']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  expectedRate: Maybe<Rate>;
  id: Maybe<Scalars['ID']['output']>;
  isInPool: Maybe<Scalars['Boolean']['output']>;
  lastUpdate: Maybe<Scalars['DateTime']['output']>;
  positionMatches: Maybe<CandidateMatchListResponse>;
  profile: Maybe<Profile>;
  reports: Maybe<CandidateReportListResponse>;
  status: Maybe<Scalars['String']['output']>;
  submissions: Maybe<SubmissionListResponse>;
  supplier: Maybe<Supplier>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CandidatepositionMatchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CandidateMatchFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<CandidateMatchGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<CandidateMatchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CandidateMatchSort>>;
};

export type CandidatereportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CandidateReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<CandidateReportGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<CandidateReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CandidateReportSort>>;
};

export type CandidatesubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SubmissionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<SubmissionGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SubmissionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SubmissionSort>>;
};

export type CandidateFilter = {
  AND?: InputMaybe<Array<CandidateFilter>>;
  OR?: InputMaybe<Array<CandidateFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  availableFrom?: InputMaybe<DatePredicate>;
  bulkMatchFailed?: InputMaybe<BoolPredicate>;
  bulkMatchProcessId?: InputMaybe<StringPredicate>;
  consentDate?: InputMaybe<DateTimePredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deleted?: InputMaybe<BoolPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  expectedRate?: InputMaybe<RateFilter>;
  id?: InputMaybe<IDPredicate>;
  isInPool?: InputMaybe<BoolPredicate>;
  lastUpdate?: InputMaybe<DateTimePredicate>;
  positionMatches?: InputMaybe<CandidateMatchRelationFilter>;
  profile?: InputMaybe<ProfileFilter>;
  reports?: InputMaybe<CandidateReportRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  submissions?: InputMaybe<SubmissionRelationFilter>;
  supplier?: InputMaybe<SupplierFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type CandidateGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  availableFrom?: InputMaybe<Array<GroupByField>>;
  bulkMatchFailed?: InputMaybe<Array<GroupByField>>;
  bulkMatchProcessId?: InputMaybe<Array<GroupByField>>;
  consentDate?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  deleted?: InputMaybe<Array<GroupByField>>;
  expectedRate?: InputMaybe<RateGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isInPool?: InputMaybe<Array<GroupByField>>;
  lastUpdate?: InputMaybe<Array<GroupByField>>;
  positionMatches?: InputMaybe<CandidateMatchGroupByQuery>;
  profile?: InputMaybe<ProfileGroupByQuery>;
  reports?: InputMaybe<CandidateReportGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  submissions?: InputMaybe<SubmissionGroupByQuery>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type CandidateMatch = {
  __typename?: 'CandidateMatch';
  _description: Maybe<Scalars['String']['output']>;
  candidate: Maybe<Candidate>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  metrics: Maybe<MetricListResponse>;
  position: Maybe<Position>;
  report: Maybe<MatchReport>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CandidateMatchmetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MetricFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<MetricGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<MetricOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MetricSort>>;
};

export type CandidateMatchFilter = {
  AND?: InputMaybe<Array<CandidateMatchFilter>>;
  OR?: InputMaybe<Array<CandidateMatchFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  candidate?: InputMaybe<CandidateFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  metrics?: InputMaybe<MetricRelationFilter>;
  position?: InputMaybe<PositionFilter>;
  report?: InputMaybe<MatchReportFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type CandidateMatchGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: CandidateMatchGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type CandidateMatchGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  candidate?: InputMaybe<CandidateGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  metrics?: InputMaybe<MetricGroupByQuery>;
  position?: InputMaybe<PositionGroupByQuery>;
  report?: InputMaybe<MatchReportGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type CandidateMatchListResponse = {
  __typename?: 'CandidateMatchListResponse';
  count: Scalars['Int']['output'];
  items: Array<CandidateMatch>;
};

export type CandidateMatchOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type CandidateMatchRelationFilter = {
  every?: InputMaybe<CandidateMatchFilter>;
  none?: InputMaybe<CandidateMatchFilter>;
  some?: InputMaybe<CandidateMatchFilter>;
};

export type CandidateMatchSort = {
  candidate?: InputMaybe<CandidateSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  report?: InputMaybe<MatchReportSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CandidateRelationFilter = {
  every?: InputMaybe<CandidateFilter>;
  none?: InputMaybe<CandidateFilter>;
  some?: InputMaybe<CandidateFilter>;
};

export type CandidateReport = {
  __typename?: 'CandidateReport';
  _description: Maybe<Scalars['String']['output']>;
  candidate: Maybe<Candidate>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  cv: Maybe<Attachment>;
  cvReadabilityLevel: Maybe<CvReadability>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  isCandidateCv: Maybe<Scalars['Boolean']['output']>;
  matchReports: Maybe<MatchReportListResponse>;
  shCandidateId: Maybe<Scalars['String']['output']>;
  shPersonId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CandidateReportmatchReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MatchReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<MatchReportGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<MatchReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MatchReportSort>>;
};

export type CandidateReportFilter = {
  AND?: InputMaybe<Array<CandidateReportFilter>>;
  OR?: InputMaybe<Array<CandidateReportFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  candidate?: InputMaybe<CandidateFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  cv?: InputMaybe<AttachmentFilter>;
  cvReadabilityLevel?: InputMaybe<CvReadabilityFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  isCandidateCv?: InputMaybe<BoolPredicate>;
  matchReports?: InputMaybe<MatchReportRelationFilter>;
  shCandidateId?: InputMaybe<StringPredicate>;
  shPersonId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type CandidateReportGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: CandidateReportGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type CandidateReportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  candidate?: InputMaybe<CandidateGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  cv?: InputMaybe<AttachmentGroupByQuery>;
  cvReadabilityLevel?: InputMaybe<CvReadabilityGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isCandidateCv?: InputMaybe<Array<GroupByField>>;
  matchReports?: InputMaybe<MatchReportGroupByQuery>;
  shCandidateId?: InputMaybe<Array<GroupByField>>;
  shPersonId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type CandidateReportListResponse = {
  __typename?: 'CandidateReportListResponse';
  count: Scalars['Int']['output'];
  items: Array<CandidateReport>;
};

export type CandidateReportOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isCandidateCv_ASC'
  | 'isCandidateCv_DESC'
  | 'shCandidateId_ASC'
  | 'shCandidateId_DESC'
  | 'shPersonId_ASC'
  | 'shPersonId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type CandidateReportRelationFilter = {
  every?: InputMaybe<CandidateReportFilter>;
  none?: InputMaybe<CandidateReportFilter>;
  some?: InputMaybe<CandidateReportFilter>;
};

export type CandidateReportSort = {
  candidate?: InputMaybe<CandidateSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  cv?: InputMaybe<AttachmentSort>;
  cvReadabilityLevel?: InputMaybe<CvReadabilitySort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCandidateCv?: InputMaybe<SortOrder>;
  shCandidateId?: InputMaybe<SortOrder>;
  shPersonId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CandidateSort = {
  availableFrom?: InputMaybe<SortOrder>;
  bulkMatchFailed?: InputMaybe<SortOrder>;
  bulkMatchProcessId?: InputMaybe<SortOrder>;
  consentDate?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deleted?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expectedRate?: InputMaybe<RateSort>;
  id?: InputMaybe<SortOrder>;
  isInPool?: InputMaybe<SortOrder>;
  lastUpdate?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileSort>;
  status?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Clearance = {
  __typename?: 'Clearance';
  _description: Maybe<Scalars['String']['output']>;
  contractor: Maybe<Contractor>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  holdingCompany: Maybe<Organization>;
  id: Maybe<Scalars['ID']['output']>;
  notes: Maybe<NoteListResponse>;
  profile: Maybe<ProfileListResponse>;
  proposal: Maybe<Proposal>;
  transferRequired: Maybe<Scalars['Boolean']['output']>;
  type: Maybe<ClearanceLevel>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  validFrom: Maybe<Scalars['DateTime']['output']>;
  validUntil: Maybe<Scalars['DateTime']['output']>;
};

export type ClearancenotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<NoteGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<NoteOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<NoteSort>>;
};

export type ClearanceprofileArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ProfileGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ProfileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProfileSort>>;
};

export type ClearanceFilter = {
  AND?: InputMaybe<Array<ClearanceFilter>>;
  OR?: InputMaybe<Array<ClearanceFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  contractor?: InputMaybe<ContractorFilter>;
  country?: InputMaybe<CountryFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  holdingCompany?: InputMaybe<OrganizationFilter>;
  id?: InputMaybe<IDPredicate>;
  notes?: InputMaybe<NoteRelationFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  proposal?: InputMaybe<ProposalFilter>;
  transferRequired?: InputMaybe<BoolPredicate>;
  type?: InputMaybe<ClearanceLevelFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  validFrom?: InputMaybe<DateTimePredicate>;
  validUntil?: InputMaybe<DateTimePredicate>;
};

export type ClearanceGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: ClearanceGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ClearanceGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  contractor?: InputMaybe<ContractorGroupByQuery>;
  country?: InputMaybe<CountryGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  holdingCompany?: InputMaybe<OrganizationGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  notes?: InputMaybe<NoteGroupByQuery>;
  profile?: InputMaybe<ProfileGroupByQuery>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  transferRequired?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<ClearanceLevelGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  validFrom?: InputMaybe<Array<GroupByField>>;
  validUntil?: InputMaybe<Array<GroupByField>>;
};

export type ClearanceLevel = {
  __typename?: 'ClearanceLevel';
  _description: Maybe<Scalars['String']['output']>;
  aliases: Maybe<Scalars['JSON']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  priority: Maybe<Scalars['Int']['output']>;
  shClearance: Maybe<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ClearanceLevelCollection = {
  __typename?: 'ClearanceLevelCollection';
  _description: Maybe<Scalars['String']['output']>;
  clearanceLevels: Maybe<ClearanceLevelListResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  default: Maybe<Scalars['Boolean']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  projects: Maybe<ProjectListResponse>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ClearanceLevelCollectionclearanceLevelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClearanceLevelFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ClearanceLevelGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClearanceLevelOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClearanceLevelSort>>;
};

export type ClearanceLevelCollectionprojectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ProjectGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectSort>>;
};

export type ClearanceLevelCollectionFilter = {
  AND?: InputMaybe<Array<ClearanceLevelCollectionFilter>>;
  OR?: InputMaybe<Array<ClearanceLevelCollectionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  clearanceLevels?: InputMaybe<ClearanceLevelRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  default?: InputMaybe<BoolPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  projects?: InputMaybe<ProjectRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ClearanceLevelCollectionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  clearanceLevels?: InputMaybe<ClearanceLevelGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  default?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  projects?: InputMaybe<ProjectGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ClearanceLevelCollectionRelationFilter = {
  every?: InputMaybe<ClearanceLevelCollectionFilter>;
  none?: InputMaybe<ClearanceLevelCollectionFilter>;
  some?: InputMaybe<ClearanceLevelCollectionFilter>;
};

export type ClearanceLevelCollectionSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  default?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ClearanceLevelFilter = {
  AND?: InputMaybe<Array<ClearanceLevelFilter>>;
  OR?: InputMaybe<Array<ClearanceLevelFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  clearances?: InputMaybe<ClearanceRelationFilter>;
  client?: InputMaybe<OrganizationFilter>;
  collections?: InputMaybe<ClearanceLevelCollectionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  positions?: InputMaybe<PositionRelationFilter>;
  priority?: InputMaybe<IntPredicate>;
  project?: InputMaybe<ProjectRelationFilter>;
  shClearance?: InputMaybe<StringPredicate>;
  shortName?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ClearanceLevelGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: ClearanceLevelGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ClearanceLevelGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  aliases?: InputMaybe<Array<GroupByField>>;
  clearances?: InputMaybe<ClearanceGroupByQuery>;
  client?: InputMaybe<OrganizationGroupByQuery>;
  collections?: InputMaybe<ClearanceLevelCollectionGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  positions?: InputMaybe<PositionGroupByQuery>;
  priority?: InputMaybe<Array<GroupByField>>;
  project?: InputMaybe<ProjectGroupByQuery>;
  shClearance?: InputMaybe<Array<GroupByField>>;
  shortName?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ClearanceLevelListResponse = {
  __typename?: 'ClearanceLevelListResponse';
  count: Scalars['Int']['output'];
  items: Array<ClearanceLevel>;
};

export type ClearanceLevelOrderBy =
  | 'aliases_ASC'
  | 'aliases_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'priority_ASC'
  | 'priority_DESC'
  | 'shClearance_ASC'
  | 'shClearance_DESC'
  | 'shortName_ASC'
  | 'shortName_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type ClearanceLevelRelationFilter = {
  every?: InputMaybe<ClearanceLevelFilter>;
  none?: InputMaybe<ClearanceLevelFilter>;
  some?: InputMaybe<ClearanceLevelFilter>;
};

export type ClearanceLevelSort = {
  client?: InputMaybe<OrganizationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  shClearance?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ClearanceListResponse = {
  __typename?: 'ClearanceListResponse';
  count: Scalars['Int']['output'];
  items: Array<Clearance>;
};

export type ClearanceOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'transferRequired_ASC'
  | 'transferRequired_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'validFrom_ASC'
  | 'validFrom_DESC'
  | 'validUntil_ASC'
  | 'validUntil_DESC';

export type ClearanceRelationFilter = {
  every?: InputMaybe<ClearanceFilter>;
  none?: InputMaybe<ClearanceFilter>;
  some?: InputMaybe<ClearanceFilter>;
};

export type ClearanceSort = {
  contractor?: InputMaybe<ContractorSort>;
  country?: InputMaybe<CountrySort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  holdingCompany?: InputMaybe<OrganizationSort>;
  id?: InputMaybe<SortOrder>;
  proposal?: InputMaybe<ProposalSort>;
  transferRequired?: InputMaybe<SortOrder>;
  type?: InputMaybe<ClearanceLevelSort>;
  updatedAt?: InputMaybe<SortOrder>;
  validFrom?: InputMaybe<SortOrder>;
  validUntil?: InputMaybe<SortOrder>;
};

export type Client = {
  __typename?: 'Client';
  _description: Maybe<Scalars['String']['output']>;
  config: Maybe<Scalars['JSON']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  projects: Maybe<ProjectListResponse>;
  templateGroup: Maybe<TemplateGroup>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  workingHours: Maybe<WorkingHourListResponse>;
};

export type ClientprojectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ProjectGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectSort>>;
};

export type ClientworkingHoursArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<WorkingHourFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<WorkingHourGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<WorkingHourOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkingHourSort>>;
};

export type ClientFilter = {
  AND?: InputMaybe<Array<ClientFilter>>;
  OR?: InputMaybe<Array<ClientFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  projects?: InputMaybe<ProjectRelationFilter>;
  templateGroup?: InputMaybe<TemplateGroupFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  workingHours?: InputMaybe<WorkingHourRelationFilter>;
};

export type ClientGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: ClientGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ClientGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  config?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  projects?: InputMaybe<ProjectGroupByQuery>;
  templateGroup?: InputMaybe<TemplateGroupGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workingHours?: InputMaybe<WorkingHourGroupByQuery>;
};

export type ClientListResponse = {
  __typename?: 'ClientListResponse';
  count: Scalars['Int']['output'];
  items: Array<Client>;
};

export type ClientOrderBy =
  | 'config_ASC'
  | 'config_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type ClientRelationFilter = {
  every?: InputMaybe<ClientFilter>;
  none?: InputMaybe<ClientFilter>;
  some?: InputMaybe<ClientFilter>;
};

export type ClientSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  templateGroup?: InputMaybe<TemplateGroupSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Company = {
  __typename?: 'Company';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyFilter = {
  AND?: InputMaybe<Array<CompanyFilter>>;
  OR?: InputMaybe<Array<CompanyFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  incumbencies?: InputMaybe<IncumbentRelationFilter>;
  name?: InputMaybe<StringPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type CompanyGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  incumbencies?: InputMaybe<IncumbentGroupByQuery>;
  name?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type CompanySort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContractType = 'FIXED_PRICE' | 'TIME_AND_MATERIALS';

export type ContractTypeFilterValue = 'FIXED_PRICE' | 'TIME_AND_MATERIALS';

export type Contractor = {
  __typename?: 'Contractor';
  _description: Maybe<Scalars['String']['output']>;
  clearances: Maybe<ClearanceListResponse>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  documents: Maybe<AttachmentListResponse>;
  hiringProcess: Maybe<HiringProcess>;
  id: Maybe<Scalars['ID']['output']>;
  incumbent: Maybe<IncumbentListResponse>;
  notes: Maybe<NoteListResponse>;
  person: Maybe<Person>;
  position: Maybe<Position>;
  rates: Maybe<RateListResponse>;
  supplier: Maybe<Supplier>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ContractorclearancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClearanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ClearanceGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClearanceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClearanceSort>>;
};

export type ContractordocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AttachmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<AttachmentGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AttachmentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AttachmentSort>>;
};

export type ContractorincumbentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IncumbentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<IncumbentGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<IncumbentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IncumbentSort>>;
};

export type ContractornotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<NoteGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<NoteOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<NoteSort>>;
};

export type ContractorratesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<RateGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<RateOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RateSort>>;
};

export type ContractorFilter = {
  AND?: InputMaybe<Array<ContractorFilter>>;
  OR?: InputMaybe<Array<ContractorFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  clearances?: InputMaybe<ClearanceRelationFilter>;
  country?: InputMaybe<CountryFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  documents?: InputMaybe<AttachmentRelationFilter>;
  hiringProcess?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  incumbent?: InputMaybe<IncumbentRelationFilter>;
  notes?: InputMaybe<NoteRelationFilter>;
  person?: InputMaybe<PersonFilter>;
  position?: InputMaybe<PositionFilter>;
  rates?: InputMaybe<RateRelationFilter>;
  supplier?: InputMaybe<SupplierFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ContractorGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: ContractorGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ContractorGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  clearances?: InputMaybe<ClearanceGroupByQuery>;
  country?: InputMaybe<CountryGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  documents?: InputMaybe<AttachmentGroupByQuery>;
  hiringProcess?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  incumbent?: InputMaybe<IncumbentGroupByQuery>;
  notes?: InputMaybe<NoteGroupByQuery>;
  person?: InputMaybe<PersonGroupByQuery>;
  position?: InputMaybe<PositionGroupByQuery>;
  rates?: InputMaybe<RateGroupByQuery>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ContractorListResponse = {
  __typename?: 'ContractorListResponse';
  count: Scalars['Int']['output'];
  items: Array<Contractor>;
};

export type ContractorOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type ContractorRelationFilter = {
  every?: InputMaybe<ContractorFilter>;
  none?: InputMaybe<ContractorFilter>;
  some?: InputMaybe<ContractorFilter>;
};

export type ContractorSort = {
  country?: InputMaybe<CountrySort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  hiringProcess?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  person?: InputMaybe<PersonSort>;
  position?: InputMaybe<PositionSort>;
  supplier?: InputMaybe<SupplierSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Country = {
  __typename?: 'Country';
  _description: Maybe<Scalars['String']['output']>;
  clearanceCountry: Maybe<ClearanceListResponse>;
  contractors: Maybe<ContractorListResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  locations: Maybe<LocationListResponse>;
  name: Maybe<Scalars['String']['output']>;
  natoMember: Maybe<Scalars['Boolean']['output']>;
  people: Maybe<PersonListResponse>;
  profileCitizenship: Maybe<ProfileListResponse>;
  profilePermissionIssue: Maybe<ProfileListResponse>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  workingHoursSetting: Maybe<WorkingHourListResponse>;
};

export type CountryclearanceCountryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClearanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ClearanceGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClearanceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClearanceSort>>;
};

export type CountrycontractorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ContractorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ContractorGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContractorOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ContractorSort>>;
};

export type CountrylocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LocationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<LocationGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<LocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<LocationSort>>;
};

export type CountrypeopleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<PersonGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PersonOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonSort>>;
};

export type CountryprofileCitizenshipArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ProfileGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ProfileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProfileSort>>;
};

export type CountryprofilePermissionIssueArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ProfileGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ProfileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProfileSort>>;
};

export type CountryworkingHoursSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<WorkingHourFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<WorkingHourGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<WorkingHourOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkingHourSort>>;
};

export type CountryFilter = {
  AND?: InputMaybe<Array<CountryFilter>>;
  OR?: InputMaybe<Array<CountryFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  clearanceCountry?: InputMaybe<ClearanceRelationFilter>;
  contractors?: InputMaybe<ContractorRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  locations?: InputMaybe<LocationRelationFilter>;
  name?: InputMaybe<StringPredicate>;
  natoMember?: InputMaybe<BoolPredicate>;
  people?: InputMaybe<PersonRelationFilter>;
  profileCitizenship?: InputMaybe<ProfileRelationFilter>;
  profilePermissionIssue?: InputMaybe<ProfileRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  workingHoursSetting?: InputMaybe<WorkingHourRelationFilter>;
};

export type CountryGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  clearanceCountry?: InputMaybe<ClearanceGroupByQuery>;
  contractors?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  locations?: InputMaybe<LocationGroupByQuery>;
  name?: InputMaybe<Array<GroupByField>>;
  natoMember?: InputMaybe<Array<GroupByField>>;
  people?: InputMaybe<PersonGroupByQuery>;
  profileCitizenship?: InputMaybe<ProfileGroupByQuery>;
  profilePermissionIssue?: InputMaybe<ProfileGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workingHoursSetting?: InputMaybe<WorkingHourGroupByQuery>;
};

export type CountrySort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  natoMember?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CvReadability = {
  __typename?: 'CvReadability';
  _description: Maybe<Scalars['String']['output']>;
  candidateReports: Maybe<CandidateReportListResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  level: Maybe<Scalars['String']['output']>;
  priority: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CvReadabilitycandidateReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CandidateReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<CandidateReportGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<CandidateReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CandidateReportSort>>;
};

export type CvReadabilityFilter = {
  AND?: InputMaybe<Array<CvReadabilityFilter>>;
  OR?: InputMaybe<Array<CvReadabilityFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  candidateReports?: InputMaybe<CandidateReportRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  level?: InputMaybe<StringPredicate>;
  priority?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type CvReadabilityGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  candidateReports?: InputMaybe<CandidateReportGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  level?: InputMaybe<Array<GroupByField>>;
  priority?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type CvReadabilitySort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DatePartFunctionType =
  | 'DATE'
  | 'DAY'
  | 'DAY_NAME'
  | 'DAY_OF_MONTH'
  | 'DAY_OF_WEEK'
  | 'DAY_OF_YEAR'
  | 'HOUR'
  | 'LAST_DAY'
  | 'MICROSECOND'
  | 'MINUTE'
  | 'MONTH'
  | 'MONTH_NAME'
  | 'QUARTER'
  | 'SECOND'
  | 'TIME'
  | 'WEEK'
  | 'WEEK_DAY'
  | 'WEEK_OF_YEAR'
  | 'YEAR'
  | 'YEAR_WEEK';

export type DatePredicate = {
  equals?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  not_equals?: InputMaybe<Scalars['Date']['input']>;
  not_in?: InputMaybe<Array<Scalars['Date']['input']>>;
  relative?: InputMaybe<DateRelativePredicates>;
};

export type DatePredicateHaving = {
  AND?: InputMaybe<Array<DatePredicateHaving>>;
  OR?: InputMaybe<Array<DatePredicateHaving>>;
  equals?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  not_equals?: InputMaybe<Scalars['Date']['input']>;
  not_in?: InputMaybe<Array<Scalars['Date']['input']>>;
};

export type DateRelativePredicateType = {
  op?: InputMaybe<RelativePredicateOpEnum>;
  unit?: InputMaybe<RelativePredicateUnitEnum>;
  value: Scalars['String']['input'];
};

export type DateRelativePredicates = {
  gt?: InputMaybe<DateRelativePredicateType>;
  gte?: InputMaybe<DateRelativePredicateType>;
  lt?: InputMaybe<DateRelativePredicateType>;
  lte?: InputMaybe<DateRelativePredicateType>;
};

export type DateTimePredicate = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
  not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  relative?: InputMaybe<DateRelativePredicates>;
};

export type DateTimePredicateHaving = {
  AND?: InputMaybe<Array<DateTimePredicateHaving>>;
  OR?: InputMaybe<Array<DateTimePredicateHaving>>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
  not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DeliverableFilter = {
  AND?: InputMaybe<Array<DeliverableFilter>>;
  OR?: InputMaybe<Array<DeliverableFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  end?: InputMaybe<DatePredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  position?: InputMaybe<PositionFilter>;
  start?: InputMaybe<DatePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DeliverableGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  end?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  start?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type DeliverableRelationFilter = {
  every?: InputMaybe<DeliverableFilter>;
  none?: InputMaybe<DeliverableFilter>;
  some?: InputMaybe<DeliverableFilter>;
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  mode: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type DeliveryOptionFilter = {
  AND?: InputMaybe<Array<DeliveryOptionFilter>>;
  OR?: InputMaybe<Array<DeliveryOptionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  mode?: InputMaybe<StringPredicate>;
  org?: InputMaybe<OrganizationFilter>;
  positions?: InputMaybe<PositionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DeliveryOptionFilterValue = 'OFFSITE' | 'ONSITE';

export type DeliveryOptionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  mode?: InputMaybe<Array<GroupByField>>;
  org?: InputMaybe<OrganizationGroupByQuery>;
  positions?: InputMaybe<PositionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type DeliveryOptionRelationFilter = {
  every?: InputMaybe<DeliveryOptionFilter>;
  none?: InputMaybe<DeliveryOptionFilter>;
  some?: InputMaybe<DeliveryOptionFilter>;
};

export type DeliveryOptionSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mode?: InputMaybe<SortOrder>;
  org?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DepartmentFilter = {
  AND?: InputMaybe<Array<DepartmentFilter>>;
  OR?: InputMaybe<Array<DepartmentFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  org?: InputMaybe<OrganizationFilter>;
  positions?: InputMaybe<PositionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DepartmentGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  org?: InputMaybe<OrganizationGroupByQuery>;
  positions?: InputMaybe<PositionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type DepartmentRelationFilter = {
  every?: InputMaybe<DepartmentFilter>;
  none?: InputMaybe<DepartmentFilter>;
  some?: InputMaybe<DepartmentFilter>;
};

export type DepartmentSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  org?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Draft = {
  __typename?: 'Draft';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  draft: Maybe<Scalars['JSON']['output']>;
  form: Maybe<Form>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  params: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<AlakaUser>;
};

export type DraftFilter = {
  AND?: InputMaybe<Array<DraftFilter>>;
  OR?: InputMaybe<Array<DraftFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  form?: InputMaybe<FormFilter>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  params?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<AlakaUserFilter>;
};

export type DraftGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: DraftGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type DraftGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  draft?: InputMaybe<Array<GroupByField>>;
  form?: InputMaybe<FormGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  params?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<AlakaUserGroupByQuery>;
};

export type DraftListResponse = {
  __typename?: 'DraftListResponse';
  count: Scalars['Int']['output'];
  items: Array<Draft>;
};

export type DraftOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'draft_ASC'
  | 'draft_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'params_ASC'
  | 'params_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type DraftRelationFilter = {
  every?: InputMaybe<DraftFilter>;
  none?: InputMaybe<DraftFilter>;
  some?: InputMaybe<DraftFilter>;
};

export type DraftSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  form?: InputMaybe<FormSort>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  params?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<AlakaUserSort>;
};

export type DueDateFilterValue =
  | 'DUE_NEXT_7_DAYS'
  | 'DUE_TODAY'
  | 'DUE_TOMORROW'
  | 'EXPIRED'
  | 'NOT_EXPIRED';

export type Duration = {
  __typename?: 'Duration';
  _description: Maybe<Scalars['String']['output']>;
  category: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  end: Maybe<Scalars['DateTime']['output']>;
  hours: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  start: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type DurationFilter = {
  AND?: InputMaybe<Array<DurationFilter>>;
  OR?: InputMaybe<Array<DurationFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  end?: InputMaybe<DateTimePredicate>;
  hours?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  location?: InputMaybe<LocationFilter>;
  organization?: InputMaybe<OrganizationFilter>;
  position?: InputMaybe<PositionFilter>;
  rfq?: InputMaybe<RFQFilter>;
  start?: InputMaybe<DateTimePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DurationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  category?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  end?: InputMaybe<Array<GroupByField>>;
  hours?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  location?: InputMaybe<LocationGroupByQuery>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  position?: InputMaybe<PositionGroupByQuery>;
  rfq?: InputMaybe<RFQGroupByQuery>;
  start?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type DurationSort = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  hours?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationSort>;
  organization?: InputMaybe<OrganizationSort>;
  position?: InputMaybe<PositionSort>;
  rfq?: InputMaybe<RFQSort>;
  start?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EmailTemplateFilter = {
  AND?: InputMaybe<Array<EmailTemplateFilter>>;
  OR?: InputMaybe<Array<EmailTemplateFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  isDefault?: InputMaybe<BoolPredicate>;
  projects?: InputMaybe<ProjectRelationFilter>;
  templateId?: InputMaybe<StringPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userGroup?: InputMaybe<UserGroupFilter>;
};

export type EmailTemplateGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isDefault?: InputMaybe<Array<GroupByField>>;
  projects?: InputMaybe<ProjectGroupByQuery>;
  templateId?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userGroup?: InputMaybe<UserGroupGroupByQuery>;
};

export type EmailTemplateRelationFilter = {
  every?: InputMaybe<EmailTemplateFilter>;
  none?: InputMaybe<EmailTemplateFilter>;
  some?: InputMaybe<EmailTemplateFilter>;
};

export type ExperienceLevel = {
  __typename?: 'ExperienceLevel';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  order: Maybe<Scalars['Int']['output']>;
  shLevel: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  yearsOfExperience: Maybe<Scalars['String']['output']>;
};

export type ExperienceLevelCollectionFilter = {
  AND?: InputMaybe<Array<ExperienceLevelCollectionFilter>>;
  OR?: InputMaybe<Array<ExperienceLevelCollectionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  isDefault?: InputMaybe<BoolPredicate>;
  levels?: InputMaybe<ExperienceLevelRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ExperienceLevelCollectionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isDefault?: InputMaybe<Array<GroupByField>>;
  levels?: InputMaybe<ExperienceLevelGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ExperienceLevelCollectionSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ExperienceLevelFilter = {
  AND?: InputMaybe<Array<ExperienceLevelFilter>>;
  OR?: InputMaybe<Array<ExperienceLevelFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  collection?: InputMaybe<ExperienceLevelCollectionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  order?: InputMaybe<IntPredicate>;
  org?: InputMaybe<OrganizationFilter>;
  positions?: InputMaybe<PositionRelationFilter>;
  profiles?: InputMaybe<ProfileRelationFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  shLevel?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  yearsOfExperience?: InputMaybe<StringPredicate>;
};

export type ExperienceLevelGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  collection?: InputMaybe<ExperienceLevelCollectionGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  order?: InputMaybe<Array<GroupByField>>;
  org?: InputMaybe<OrganizationGroupByQuery>;
  positions?: InputMaybe<PositionGroupByQuery>;
  profiles?: InputMaybe<ProfileGroupByQuery>;
  project?: InputMaybe<ProjectGroupByQuery>;
  shLevel?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  yearsOfExperience?: InputMaybe<Array<GroupByField>>;
};

export type ExperienceLevelRelationFilter = {
  every?: InputMaybe<ExperienceLevelFilter>;
  none?: InputMaybe<ExperienceLevelFilter>;
  some?: InputMaybe<ExperienceLevelFilter>;
};

export type ExperienceLevelSort = {
  collection?: InputMaybe<ExperienceLevelCollectionSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  org?: InputMaybe<OrganizationSort>;
  shLevel?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  yearsOfExperience?: InputMaybe<SortOrder>;
};

export type FileFilter = {
  AND?: InputMaybe<Array<FileFilter>>;
  OR?: InputMaybe<Array<FileFilter>>;
  Settings_landingPageImage?: InputMaybe<SettingRelationFilter>;
  Settings_menuBarLogo?: InputMaybe<SettingRelationFilter>;
  Users_avatar?: InputMaybe<UserRelationFilter>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  downloadUrl?: InputMaybe<StringPredicate>;
  fileId?: InputMaybe<StringPredicate>;
  filename?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  provider?: InputMaybe<StringPredicate>;
  public?: InputMaybe<BoolPredicate>;
  shareUrl?: InputMaybe<StringPredicate>;
  teamMembers_avatar?: InputMaybe<TeamMemberRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  uploadUrl?: InputMaybe<StringPredicate>;
  uploaded?: InputMaybe<BoolPredicate>;
};

export type FileGroupByQuery = {
  Settings_landingPageImage?: InputMaybe<SettingGroupByQuery>;
  Settings_menuBarLogo?: InputMaybe<SettingGroupByQuery>;
  Users_avatar?: InputMaybe<UserGroupByQuery>;
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  downloadUrl?: InputMaybe<Array<GroupByField>>;
  fields?: InputMaybe<Array<GroupByField>>;
  fileId?: InputMaybe<Array<GroupByField>>;
  filename?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  meta?: InputMaybe<Array<GroupByField>>;
  mods?: InputMaybe<Array<GroupByField>>;
  provider?: InputMaybe<Array<GroupByField>>;
  public?: InputMaybe<Array<GroupByField>>;
  shareUrl?: InputMaybe<Array<GroupByField>>;
  teamMembers_avatar?: InputMaybe<TeamMemberGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  uploadUrl?: InputMaybe<Array<GroupByField>>;
  uploaded?: InputMaybe<Array<GroupByField>>;
};

export type FileSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  downloadUrl?: InputMaybe<SortOrder>;
  fileId?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  public?: InputMaybe<SortOrder>;
  shareUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadUrl?: InputMaybe<SortOrder>;
  uploaded?: InputMaybe<SortOrder>;
};

export type FloatPredicate = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
  not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatPredicateHaving = {
  AND?: InputMaybe<Array<FloatPredicateHaving>>;
  OR?: InputMaybe<Array<FloatPredicateHaving>>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
  not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Form = {
  __typename?: 'Form';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  enabledFields: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  form: Maybe<Scalars['JSON']['output']>;
  formDraft: Maybe<DraftListResponse>;
  id: Maybe<Scalars['ID']['output']>;
  importProject: Maybe<Project>;
  importedPosition: Maybe<ImportListResponse>;
  isFormDefault: Maybe<Scalars['Boolean']['output']>;
  project: Maybe<Project>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type FormformDraftArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DraftFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<DraftGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<DraftOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DraftSort>>;
};

export type FormimportedPositionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ImportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ImportGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ImportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ImportSort>>;
};

export type FormFilter = {
  AND?: InputMaybe<Array<FormFilter>>;
  OR?: InputMaybe<Array<FormFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  formDraft?: InputMaybe<DraftRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  importProject?: InputMaybe<ProjectFilter>;
  importedPosition?: InputMaybe<ImportRelationFilter>;
  isFormDefault?: InputMaybe<BoolPredicate>;
  project?: InputMaybe<ProjectFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type FormGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: FormGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type FormGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  enabledFields?: InputMaybe<Array<GroupByField>>;
  form?: InputMaybe<Array<GroupByField>>;
  formDraft?: InputMaybe<DraftGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  importProject?: InputMaybe<ProjectGroupByQuery>;
  importedPosition?: InputMaybe<ImportGroupByQuery>;
  isFormDefault?: InputMaybe<Array<GroupByField>>;
  project?: InputMaybe<ProjectGroupByQuery>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type FormListResponse = {
  __typename?: 'FormListResponse';
  count: Scalars['Int']['output'];
  items: Array<Form>;
};

export type FormOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'form_ASC'
  | 'form_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isFormDefault_ASC'
  | 'isFormDefault_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type FormRelationFilter = {
  every?: InputMaybe<FormFilter>;
  none?: InputMaybe<FormFilter>;
  some?: InputMaybe<FormFilter>;
};

export type FormSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  importProject?: InputMaybe<ProjectSort>;
  isFormDefault?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupByField = {
  as?: InputMaybe<Scalars['String']['input']>;
  fn?: InputMaybe<Array<InputMaybe<GroupByFieldFunction>>>;
};

export type GroupByFieldFunction = {
  abs?: InputMaybe<Scalars['Boolean']['input']>;
  aggregate?: InputMaybe<AggregationFunctionType>;
  ascii?: InputMaybe<Scalars['Boolean']['input']>;
  bitLength?: InputMaybe<Scalars['Boolean']['input']>;
  ceil?: InputMaybe<Scalars['Boolean']['input']>;
  charLength?: InputMaybe<Scalars['Boolean']['input']>;
  crc32?: InputMaybe<Scalars['Boolean']['input']>;
  datePart?: InputMaybe<DatePartFunctionType>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  floor?: InputMaybe<Scalars['Boolean']['input']>;
  hex?: InputMaybe<Scalars['Boolean']['input']>;
  ifNull?: InputMaybe<Scalars['String']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  left?: InputMaybe<Scalars['Int']['input']>;
  length?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PatternFunctionArguments>;
  locate?: InputMaybe<LocateFunctionArguments>;
  lower?: InputMaybe<Scalars['Boolean']['input']>;
  lpad?: InputMaybe<StringPadFunctionArguments>;
  ltrim?: InputMaybe<Scalars['Boolean']['input']>;
  mod?: InputMaybe<Scalars['Int']['input']>;
  notLike?: InputMaybe<PatternFunctionArguments>;
  nullIf?: InputMaybe<Scalars['String']['input']>;
  replace?: InputMaybe<ReplaceFunctionArguments>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  right?: InputMaybe<Scalars['Int']['input']>;
  round?: InputMaybe<Scalars['Int']['input']>;
  rpad?: InputMaybe<StringPadFunctionArguments>;
  rtrim?: InputMaybe<Scalars['Boolean']['input']>;
  sign?: InputMaybe<Scalars['Boolean']['input']>;
  substring?: InputMaybe<SubstringFunctionArguments>;
  trim?: InputMaybe<TrimFunctionArguments>;
  truncate?: InputMaybe<Scalars['Int']['input']>;
  upper?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupBySort = {
  alias: Scalars['String']['input'];
  direction: SortOrder;
};

export type GroupIdentifiersGroupByField = {
  as: Scalars['String']['input'];
};

export type Having = {
  AND?: InputMaybe<Array<Having>>;
  OR?: InputMaybe<Array<Having>>;
  alias?: InputMaybe<Scalars['String']['input']>;
  bigint?: InputMaybe<BigIntPredicateHaving>;
  bool?: InputMaybe<BoolPredicateHaving>;
  date?: InputMaybe<DatePredicateHaving>;
  datetime?: InputMaybe<DateTimePredicateHaving>;
  float?: InputMaybe<FloatPredicateHaving>;
  id?: InputMaybe<IDPredicateHaving>;
  int?: InputMaybe<IntPredicateHaving>;
  string?: InputMaybe<StringPredicateHaving>;
};

export type HiringProcess = {
  __typename?: 'HiringProcess';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  purchaseOrder: Maybe<PurchaseOrder>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  withdrawalNotified: Maybe<Scalars['Boolean']['output']>;
};

export type HiringProcessFilter = {
  AND?: InputMaybe<Array<HiringProcessFilter>>;
  OR?: InputMaybe<Array<HiringProcessFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  cancelledStep?: InputMaybe<SubmissionStatusFilter>;
  contractor?: InputMaybe<ContractorFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  dates?: InputMaybe<StartDateRelationFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  files?: InputMaybe<AttachmentRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  notes?: InputMaybe<NoteRelationFilter>;
  proposal?: InputMaybe<ProposalFilter>;
  purchaseOrder?: InputMaybe<PurchaseOrderFilter>;
  status?: InputMaybe<StringPredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  withdrawalNotified?: InputMaybe<BoolPredicate>;
};

export type HiringProcessGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  cancelledStep?: InputMaybe<SubmissionStatusGroupByQuery>;
  contractor?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  dates?: InputMaybe<StartDateGroupByQuery>;
  files?: InputMaybe<AttachmentGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  notes?: InputMaybe<NoteGroupByQuery>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  purchaseOrder?: InputMaybe<PurchaseOrderGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  withdrawalNotified?: InputMaybe<Array<GroupByField>>;
};

export type HiringProcessSort = {
  cancelledStep?: InputMaybe<SubmissionStatusSort>;
  contractor?: InputMaybe<ContractorSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  proposal?: InputMaybe<ProposalSort>;
  purchaseOrder?: InputMaybe<PurchaseOrderSort>;
  status?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SubmissionSort>;
  updatedAt?: InputMaybe<SortOrder>;
  withdrawalNotified?: InputMaybe<SortOrder>;
};

export type IDPredicate = {
  contains?: InputMaybe<Scalars['ID']['input']>;
  ends_with?: InputMaybe<Scalars['ID']['input']>;
  equals?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  not_contains?: InputMaybe<Scalars['ID']['input']>;
  not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  not_equals?: InputMaybe<Scalars['ID']['input']>;
  not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export type IDPredicateHaving = {
  AND?: InputMaybe<Array<IDPredicateHaving>>;
  OR?: InputMaybe<Array<IDPredicateHaving>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  ends_with?: InputMaybe<Scalars['ID']['input']>;
  equals?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  not_contains?: InputMaybe<Scalars['ID']['input']>;
  not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  not_equals?: InputMaybe<Scalars['ID']['input']>;
  not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export type Import = {
  __typename?: 'Import';
  _description: Maybe<Scalars['String']['output']>;
  attachments: Maybe<AttachmentListResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  data: Maybe<Scalars['JSON']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  dueOn: Maybe<Scalars['DateTime']['output']>;
  form: Maybe<Form>;
  id: Maybe<Scalars['ID']['output']>;
  mail: Maybe<Scalars['String']['output']>;
  missingFields: Maybe<Scalars['JSON']['output']>;
  name: Maybe<Scalars['String']['output']>;
  ratesParsed: Maybe<Scalars['Boolean']['output']>;
  rfq: Maybe<RFQ>;
  sowParsed: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  validated: Maybe<Scalars['Boolean']['output']>;
};

export type ImportattachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AttachmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<AttachmentGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AttachmentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AttachmentSort>>;
};

export type ImportFilter = {
  AND?: InputMaybe<Array<ImportFilter>>;
  OR?: InputMaybe<Array<ImportFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  dueOn?: InputMaybe<DateTimePredicate>;
  form?: InputMaybe<FormFilter>;
  id?: InputMaybe<IDPredicate>;
  mail?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  ratesParsed?: InputMaybe<BoolPredicate>;
  rfq?: InputMaybe<RFQFilter>;
  sowParsed?: InputMaybe<BoolPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  validated?: InputMaybe<BoolPredicate>;
};

export type ImportGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: ImportGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ImportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  attachments?: InputMaybe<AttachmentGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  data?: InputMaybe<Array<GroupByField>>;
  dueOn?: InputMaybe<Array<GroupByField>>;
  form?: InputMaybe<FormGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  mail?: InputMaybe<Array<GroupByField>>;
  missingFields?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  ratesParsed?: InputMaybe<Array<GroupByField>>;
  rfq?: InputMaybe<RFQGroupByQuery>;
  sowParsed?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  validated?: InputMaybe<Array<GroupByField>>;
};

export type ImportListResponse = {
  __typename?: 'ImportListResponse';
  count: Scalars['Int']['output'];
  items: Array<Import>;
};

export type ImportMetadata = {
  __typename?: 'ImportMetadata';
  contractType: ContractType;
};

export type ImportOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'data_ASC'
  | 'data_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'dueOn_ASC'
  | 'dueOn_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'mail_ASC'
  | 'mail_DESC'
  | 'missingFields_ASC'
  | 'missingFields_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'ratesParsed_ASC'
  | 'ratesParsed_DESC'
  | 'sowParsed_ASC'
  | 'sowParsed_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'validated_ASC'
  | 'validated_DESC';

export type ImportRecord = {
  __typename?: 'ImportRecord';
  /** Date when document was imported */
  createdAt: Scalars['DateTime']['output'];
  /** Download link for uploaded document */
  downloadLink: Scalars['String']['output'];
  /** Filename of the uploaded document */
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Additional data related to position */
  metadata: ImportMetadata;
  /** Parsed data available when document was successfully parsed */
  parsedData: Maybe<Scalars['JSON']['output']>;
  /** Current parsing process state of the imported record */
  status: ImportStatus;
};

export type ImportRecordsFilter = {
  /** Search by document filename, RFQ number or position title */
  fullText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ImportStatus>;
};

export type ImportRecordsResponse = {
  __typename?: 'ImportRecordsResponse';
  count: Scalars['Int']['output'];
  items: Array<ImportRecord>;
  pageInfo: PageInfo;
};

export type ImportRelationFilter = {
  every?: InputMaybe<ImportFilter>;
  none?: InputMaybe<ImportFilter>;
  some?: InputMaybe<ImportFilter>;
};

export type ImportSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  dueOn?: InputMaybe<SortOrder>;
  form?: InputMaybe<FormSort>;
  id?: InputMaybe<SortOrder>;
  mail?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ratesParsed?: InputMaybe<SortOrder>;
  rfq?: InputMaybe<RFQSort>;
  sowParsed?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validated?: InputMaybe<SortOrder>;
};

export type ImportStatus = 'CREATED' | 'FAILED' | 'PARSED' | 'PARTIALLY_PARSED' | 'PENDING';

export type Incumbent = {
  __typename?: 'Incumbent';
  _description: Maybe<Scalars['String']['output']>;
  company: Maybe<Company>;
  competitorContractorName: Maybe<Scalars['String']['output']>;
  contractor: Maybe<Contractor>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  position: Maybe<Position>;
  purchaseOrder: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type IncumbentFilter = {
  AND?: InputMaybe<Array<IncumbentFilter>>;
  OR?: InputMaybe<Array<IncumbentFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyFilter>;
  competitorContractorName?: InputMaybe<StringPredicate>;
  contractor?: InputMaybe<ContractorFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IDPredicate>;
  position?: InputMaybe<PositionFilter>;
  purchaseOrder?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type IncumbentGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: IncumbentGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type IncumbentGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  company?: InputMaybe<CompanyGroupByQuery>;
  competitorContractorName?: InputMaybe<Array<GroupByField>>;
  contractor?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  endDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  purchaseOrder?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type IncumbentListResponse = {
  __typename?: 'IncumbentListResponse';
  count: Scalars['Int']['output'];
  items: Array<Incumbent>;
};

export type IncumbentOrderBy =
  | 'competitorContractorName_ASC'
  | 'competitorContractorName_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'endDate_ASC'
  | 'endDate_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'purchaseOrder_ASC'
  | 'purchaseOrder_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type IncumbentRelationFilter = {
  every?: InputMaybe<IncumbentFilter>;
  none?: InputMaybe<IncumbentFilter>;
  some?: InputMaybe<IncumbentFilter>;
};

export type IncumbentSort = {
  company?: InputMaybe<CompanySort>;
  competitorContractorName?: InputMaybe<SortOrder>;
  contractor?: InputMaybe<ContractorSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  purchaseOrder?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type IntPredicate = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not_equals?: InputMaybe<Scalars['Int']['input']>;
  not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntPredicateHaving = {
  AND?: InputMaybe<Array<IntPredicateHaving>>;
  OR?: InputMaybe<Array<IntPredicateHaving>>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not_equals?: InputMaybe<Scalars['Int']['input']>;
  not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LocateFunctionArguments = {
  pos?: InputMaybe<Scalars['Int']['input']>;
  str: Scalars['String']['input'];
};

export type Location = {
  __typename?: 'Location';
  _description: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  coordinates: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  street2: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type LocationFilter = {
  AND?: InputMaybe<Array<LocationFilter>>;
  OR?: InputMaybe<Array<LocationFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<StringPredicate>;
  coordinates?: InputMaybe<StringPredicate>;
  country?: InputMaybe<CountryFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  duration?: InputMaybe<DurationFilter>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  notes?: InputMaybe<NoteRelationFilter>;
  people?: InputMaybe<PersonRelationFilter>;
  positions?: InputMaybe<PositionRelationFilter>;
  profiles?: InputMaybe<ProfileRelationFilter>;
  street?: InputMaybe<StringPredicate>;
  street2?: InputMaybe<StringPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  zip?: InputMaybe<StringPredicate>;
};

export type LocationGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: LocationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type LocationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  city?: InputMaybe<Array<GroupByField>>;
  coordinates?: InputMaybe<Array<GroupByField>>;
  country?: InputMaybe<CountryGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  duration?: InputMaybe<DurationGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  notes?: InputMaybe<NoteGroupByQuery>;
  people?: InputMaybe<PersonGroupByQuery>;
  positions?: InputMaybe<PositionGroupByQuery>;
  profiles?: InputMaybe<ProfileGroupByQuery>;
  street?: InputMaybe<Array<GroupByField>>;
  street2?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  zip?: InputMaybe<Array<GroupByField>>;
};

export type LocationListResponse = {
  __typename?: 'LocationListResponse';
  count: Scalars['Int']['output'];
  items: Array<Location>;
};

export type LocationOrderBy =
  | 'city_ASC'
  | 'city_DESC'
  | 'coordinates_ASC'
  | 'coordinates_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'street2_ASC'
  | 'street2_DESC'
  | 'street_ASC'
  | 'street_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'zip_ASC'
  | 'zip_DESC';

export type LocationRelationFilter = {
  every?: InputMaybe<LocationFilter>;
  none?: InputMaybe<LocationFilter>;
  some?: InputMaybe<LocationFilter>;
};

export type LocationSort = {
  city?: InputMaybe<SortOrder>;
  coordinates?: InputMaybe<SortOrder>;
  country?: InputMaybe<CountrySort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  duration?: InputMaybe<DurationSort>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  street2?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type MatchReport = {
  __typename?: 'MatchReport';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  score: Maybe<Scalars['Float']['output']>;
  shMatchId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type MatchReportFilter = {
  AND?: InputMaybe<Array<MatchReportFilter>>;
  OR?: InputMaybe<Array<MatchReportFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  candidateMatch?: InputMaybe<CandidateMatchFilter>;
  candidateReport?: InputMaybe<CandidateReportFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  positionReport?: InputMaybe<PositionReportFilter>;
  score?: InputMaybe<FloatPredicate>;
  shMatchId?: InputMaybe<StringPredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  supplier?: InputMaybe<SupplierFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type MatchReportGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: MatchReportGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type MatchReportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  candidateMatch?: InputMaybe<CandidateMatchGroupByQuery>;
  candidateReport?: InputMaybe<CandidateReportGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  positionReport?: InputMaybe<PositionReportGroupByQuery>;
  score?: InputMaybe<Array<GroupByField>>;
  shMatchId?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type MatchReportListResponse = {
  __typename?: 'MatchReportListResponse';
  count: Scalars['Int']['output'];
  items: Array<MatchReport>;
};

export type MatchReportOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'score_ASC'
  | 'score_DESC'
  | 'shMatchId_ASC'
  | 'shMatchId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type MatchReportRelationFilter = {
  every?: InputMaybe<MatchReportFilter>;
  none?: InputMaybe<MatchReportFilter>;
  some?: InputMaybe<MatchReportFilter>;
};

export type MatchReportSort = {
  candidateMatch?: InputMaybe<CandidateMatchSort>;
  candidateReport?: InputMaybe<CandidateReportSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  positionReport?: InputMaybe<PositionReportSort>;
  score?: InputMaybe<SortOrder>;
  shMatchId?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SubmissionSort>;
  supplier?: InputMaybe<SupplierSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Metric = {
  __typename?: 'Metric';
  _description: Maybe<Scalars['String']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dataPoints: Maybe<Scalars['JSON']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  max: Maybe<Scalars['Float']['output']>;
  mean: Maybe<Scalars['Float']['output']>;
  median: Maybe<Scalars['Float']['output']>;
  min: Maybe<Scalars['Float']['output']>;
  percentile: Maybe<Scalars['Float']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type MetricFilter = {
  AND?: InputMaybe<Array<MetricFilter>>;
  OR?: InputMaybe<Array<MetricFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  candidateMatch?: InputMaybe<CandidateMatchFilter>;
  count?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  max?: InputMaybe<FloatPredicate>;
  mean?: InputMaybe<FloatPredicate>;
  median?: InputMaybe<FloatPredicate>;
  min?: InputMaybe<FloatPredicate>;
  percentile?: InputMaybe<FloatPredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type MetricGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: MetricGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type MetricGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  candidateMatch?: InputMaybe<CandidateMatchGroupByQuery>;
  count?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  dataPoints?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  max?: InputMaybe<Array<GroupByField>>;
  mean?: InputMaybe<Array<GroupByField>>;
  median?: InputMaybe<Array<GroupByField>>;
  min?: InputMaybe<Array<GroupByField>>;
  percentile?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type MetricListResponse = {
  __typename?: 'MetricListResponse';
  count: Scalars['Int']['output'];
  items: Array<Metric>;
};

export type MetricOrderBy =
  | 'count_ASC'
  | 'count_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'dataPoints_ASC'
  | 'dataPoints_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'max_ASC'
  | 'max_DESC'
  | 'mean_ASC'
  | 'mean_DESC'
  | 'median_ASC'
  | 'median_DESC'
  | 'min_ASC'
  | 'min_DESC'
  | 'percentile_ASC'
  | 'percentile_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type MetricRelationFilter = {
  every?: InputMaybe<MetricFilter>;
  none?: InputMaybe<MetricFilter>;
  some?: InputMaybe<MetricFilter>;
};

export type MetricSort = {
  candidateMatch?: InputMaybe<CandidateMatchSort>;
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  max?: InputMaybe<SortOrder>;
  mean?: InputMaybe<SortOrder>;
  median?: InputMaybe<SortOrder>;
  min?: InputMaybe<SortOrder>;
  percentile?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SubmissionSort>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewPositionFilter = {
  clearances?: InputMaybe<Array<Scalars['String']['input']>>;
  contractType?: InputMaybe<ContractTypeFilterValue>;
  deliveryOption?: InputMaybe<DeliveryOptionFilterValue>;
  dueDates?: InputMaybe<Array<DueDateFilterValue>>;
  fullText?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['String']['input']>;
  publishStatus?: InputMaybe<PublishStatusFilterValue>;
  statuses?: InputMaybe<Array<PositionStatusFilterValue>>;
  submissions?: InputMaybe<Array<PositionSubmissionFilterValue>>;
};

export type NewSubmissionFilter = {
  dueDates?: InputMaybe<Array<DueDateFilterValue>>;
  fullText?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  partner?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<SubmissionStatusFilterValue>>;
};

export type Note = {
  __typename?: 'Note';
  _description: Maybe<Scalars['String']['output']>;
  author: Maybe<AlakaUser>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  note: Maybe<Scalars['String']['output']>;
  noteType: Maybe<Scalars['String']['output']>;
  proposal: Maybe<Proposal>;
  submission: Maybe<Submission>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type NoteFilter = {
  AND?: InputMaybe<Array<NoteFilter>>;
  OR?: InputMaybe<Array<NoteFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  additionalNoteRevissionType?: InputMaybe<RevisionTypeFilter>;
  author?: InputMaybe<AlakaUserFilter>;
  candidateStatus?: InputMaybe<SubmissionStatusFilter>;
  clearance?: InputMaybe<ClearanceFilter>;
  contractor?: InputMaybe<ContractorFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  dateNote?: InputMaybe<StartDateFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  hiringProcess?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  location?: InputMaybe<LocationFilter>;
  note?: InputMaybe<StringPredicate>;
  noteType?: InputMaybe<StringPredicate>;
  positionStatus?: InputMaybe<PositionStatusFilter>;
  positions?: InputMaybe<PositionFilter>;
  proposal?: InputMaybe<ProposalFilter>;
  rejectedRevisionType?: InputMaybe<RevisionTypeFilter>;
  revisionType?: InputMaybe<RevisionTypeFilter>;
  submission?: InputMaybe<SubmissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type NoteGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: NoteGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type NoteGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  additionalNoteRevissionType?: InputMaybe<RevisionTypeGroupByQuery>;
  author?: InputMaybe<AlakaUserGroupByQuery>;
  candidateStatus?: InputMaybe<SubmissionStatusGroupByQuery>;
  clearance?: InputMaybe<ClearanceGroupByQuery>;
  contractor?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  dateNote?: InputMaybe<StartDateGroupByQuery>;
  hiringProcess?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  location?: InputMaybe<LocationGroupByQuery>;
  note?: InputMaybe<Array<GroupByField>>;
  noteType?: InputMaybe<Array<GroupByField>>;
  positionStatus?: InputMaybe<PositionStatusGroupByQuery>;
  positions?: InputMaybe<PositionGroupByQuery>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  rejectedRevisionType?: InputMaybe<RevisionTypeGroupByQuery>;
  revisionType?: InputMaybe<RevisionTypeGroupByQuery>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type NoteListResponse = {
  __typename?: 'NoteListResponse';
  count: Scalars['Int']['output'];
  items: Array<Note>;
};

export type NoteOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'noteType_ASC'
  | 'noteType_DESC'
  | 'note_ASC'
  | 'note_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type NoteRelationFilter = {
  every?: InputMaybe<NoteFilter>;
  none?: InputMaybe<NoteFilter>;
  some?: InputMaybe<NoteFilter>;
};

export type NoteSort = {
  additionalNoteRevissionType?: InputMaybe<RevisionTypeSort>;
  author?: InputMaybe<AlakaUserSort>;
  candidateStatus?: InputMaybe<SubmissionStatusSort>;
  clearance?: InputMaybe<ClearanceSort>;
  contractor?: InputMaybe<ContractorSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  dateNote?: InputMaybe<StartDateSort>;
  deletedAt?: InputMaybe<SortOrder>;
  hiringProcess?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationSort>;
  note?: InputMaybe<SortOrder>;
  noteType?: InputMaybe<SortOrder>;
  positionStatus?: InputMaybe<PositionStatusSort>;
  positions?: InputMaybe<PositionSort>;
  proposal?: InputMaybe<ProposalSort>;
  rejectedRevisionType?: InputMaybe<RevisionTypeSort>;
  revisionType?: InputMaybe<RevisionTypeSort>;
  submission?: InputMaybe<SubmissionSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OccupationCollectionFilter = {
  AND?: InputMaybe<Array<OccupationCollectionFilter>>;
  OR?: InputMaybe<Array<OccupationCollectionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  collectionName?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  default?: InputMaybe<BoolPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  occupations?: InputMaybe<OccupationTemplateRelationFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type OccupationCollectionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  collectionName?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  default?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  occupations?: InputMaybe<OccupationTemplateGroupByQuery>;
  project?: InputMaybe<ProjectGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type OccupationCollectionRelationFilter = {
  every?: InputMaybe<OccupationCollectionFilter>;
  none?: InputMaybe<OccupationCollectionFilter>;
  some?: InputMaybe<OccupationCollectionFilter>;
};

export type OccupationCollectionSort = {
  collectionName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  default?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OccupationTemplateFilter = {
  AND?: InputMaybe<Array<OccupationTemplateFilter>>;
  OR?: InputMaybe<Array<OccupationTemplateFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  activities?: InputMaybe<RequirementRelationFilter>;
  category?: InputMaybe<StringPredicate>;
  collection?: InputMaybe<OccupationCollectionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  domain?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  number?: InputMaybe<FloatPredicate>;
  positionOccupation?: InputMaybe<PositionRelationFilter>;
  skills?: InputMaybe<RequirementRelationFilter>;
  translationKey?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type OccupationTemplateGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  activities?: InputMaybe<RequirementGroupByQuery>;
  category?: InputMaybe<Array<GroupByField>>;
  collection?: InputMaybe<OccupationCollectionGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  domain?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  number?: InputMaybe<Array<GroupByField>>;
  positionOccupation?: InputMaybe<PositionGroupByQuery>;
  skills?: InputMaybe<RequirementGroupByQuery>;
  translationKey?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type OccupationTemplateRelationFilter = {
  every?: InputMaybe<OccupationTemplateFilter>;
  none?: InputMaybe<OccupationTemplateFilter>;
  some?: InputMaybe<OccupationTemplateFilter>;
};

export type OccupationTemplateSort = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  translationKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Organization = {
  __typename?: 'Organization';
  _description: Maybe<Scalars['String']['output']>;
  company: Maybe<Company>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationFilter = {
  AND?: InputMaybe<Array<OrganizationFilter>>;
  OR?: InputMaybe<Array<OrganizationFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  activePeriod?: InputMaybe<DurationFilter>;
  clearanceLevels?: InputMaybe<ClearanceLevelRelationFilter>;
  clearances?: InputMaybe<ClearanceRelationFilter>;
  client?: InputMaybe<ClientFilter>;
  company?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  deliveryOptions?: InputMaybe<DeliveryOptionRelationFilter>;
  departments?: InputMaybe<DepartmentRelationFilter>;
  description?: InputMaybe<StringPredicate>;
  experienceLevels?: InputMaybe<ExperienceLevelRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  supplier?: InputMaybe<SupplierFilter>;
  travelRequirements?: InputMaybe<TravelRequirementRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<AlakaUserRelationFilter>;
  workArrangements?: InputMaybe<WorkArrangementRelationFilter>;
};

export type OrganizationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  activePeriod?: InputMaybe<DurationGroupByQuery>;
  clearanceLevels?: InputMaybe<ClearanceLevelGroupByQuery>;
  clearances?: InputMaybe<ClearanceGroupByQuery>;
  client?: InputMaybe<ClientGroupByQuery>;
  company?: InputMaybe<CompanyGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  deliveryOptions?: InputMaybe<DeliveryOptionGroupByQuery>;
  departments?: InputMaybe<DepartmentGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  experienceLevels?: InputMaybe<ExperienceLevelGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  travelRequirements?: InputMaybe<TravelRequirementGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  users?: InputMaybe<AlakaUserGroupByQuery>;
  workArrangements?: InputMaybe<WorkArrangementGroupByQuery>;
};

export type OrganizationSort = {
  activePeriod?: InputMaybe<DurationSort>;
  client?: InputMaybe<ClientSort>;
  company?: InputMaybe<CompanySort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Maybe<Scalars['String']['output']>;
};

export type PatternFunctionArguments = {
  escape?: InputMaybe<Scalars['String']['input']>;
  pattern: Scalars['String']['input'];
};

export type PendingReport = {
  __typename?: 'PendingReport';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  cv: Maybe<Attachment>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  positionReport: Maybe<PositionReport>;
  score: Maybe<Scalars['Float']['output']>;
  shCandidateId: Maybe<Scalars['String']['output']>;
  shMatchId: Maybe<Scalars['String']['output']>;
  shPersonId: Maybe<Scalars['String']['output']>;
  supplier: Maybe<Supplier>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type PendingReportFilter = {
  AND?: InputMaybe<Array<PendingReportFilter>>;
  OR?: InputMaybe<Array<PendingReportFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  cv?: InputMaybe<AttachmentFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  positionReport?: InputMaybe<PositionReportFilter>;
  score?: InputMaybe<FloatPredicate>;
  shCandidateId?: InputMaybe<StringPredicate>;
  shMatchId?: InputMaybe<StringPredicate>;
  shPersonId?: InputMaybe<StringPredicate>;
  supplier?: InputMaybe<SupplierFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PendingReportGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: PendingReportGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type PendingReportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  cv?: InputMaybe<AttachmentGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  positionReport?: InputMaybe<PositionReportGroupByQuery>;
  score?: InputMaybe<Array<GroupByField>>;
  shCandidateId?: InputMaybe<Array<GroupByField>>;
  shMatchId?: InputMaybe<Array<GroupByField>>;
  shPersonId?: InputMaybe<Array<GroupByField>>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type PendingReportListResponse = {
  __typename?: 'PendingReportListResponse';
  count: Scalars['Int']['output'];
  items: Array<PendingReport>;
};

export type PendingReportOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'score_ASC'
  | 'score_DESC'
  | 'shCandidateId_ASC'
  | 'shCandidateId_DESC'
  | 'shMatchId_ASC'
  | 'shMatchId_DESC'
  | 'shPersonId_ASC'
  | 'shPersonId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type PendingReportRelationFilter = {
  every?: InputMaybe<PendingReportFilter>;
  none?: InputMaybe<PendingReportFilter>;
  some?: InputMaybe<PendingReportFilter>;
};

export type PendingReportSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  cv?: InputMaybe<AttachmentSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  positionReport?: InputMaybe<PositionReportSort>;
  score?: InputMaybe<SortOrder>;
  shCandidateId?: InputMaybe<SortOrder>;
  shMatchId?: InputMaybe<SortOrder>;
  shPersonId?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PermissionFilter = {
  AND?: InputMaybe<Array<PermissionFilter>>;
  OR?: InputMaybe<Array<PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  appId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  resource?: InputMaybe<StringPredicate>;
  resourceType?: InputMaybe<StringPredicate>;
  role?: InputMaybe<RoleFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PermissionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  appId?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  permission?: InputMaybe<Array<GroupByField>>;
  resource?: InputMaybe<Array<GroupByField>>;
  resourceType?: InputMaybe<Array<GroupByField>>;
  role?: InputMaybe<RoleGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type PermissionRelationFilter = {
  every?: InputMaybe<PermissionFilter>;
  none?: InputMaybe<PermissionFilter>;
  some?: InputMaybe<PermissionFilter>;
};

export type Person = {
  __typename?: 'Person';
  _description: Maybe<Scalars['String']['output']>;
  category: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  linkedIn: Maybe<Scalars['String']['output']>;
  mobilePhone: Maybe<Scalars['String']['output']>;
  personalEmail: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  preferredName: Maybe<Scalars['String']['output']>;
  skype: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  workEmail: Maybe<Scalars['String']['output']>;
};

export type PersonFilter = {
  AND?: InputMaybe<Array<PersonFilter>>;
  OR?: InputMaybe<Array<PersonFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<StringPredicate>;
  contractor?: InputMaybe<ContractorFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  fullName?: InputMaybe<StringPredicate>;
  gender?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  lastName?: InputMaybe<StringPredicate>;
  linkedIn?: InputMaybe<StringPredicate>;
  location?: InputMaybe<LocationRelationFilter>;
  mobilePhone?: InputMaybe<StringPredicate>;
  nationality?: InputMaybe<CountryFilter>;
  personUser?: InputMaybe<AlakaUserFilter>;
  personalEmail?: InputMaybe<StringPredicate>;
  phone?: InputMaybe<StringPredicate>;
  preferredName?: InputMaybe<StringPredicate>;
  profile?: InputMaybe<ProfileFilter>;
  skype?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  workEmail?: InputMaybe<StringPredicate>;
};

export type PersonGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: PersonGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type PersonGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  category?: InputMaybe<Array<GroupByField>>;
  contractor?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  firstName?: InputMaybe<Array<GroupByField>>;
  fullName?: InputMaybe<Array<GroupByField>>;
  gender?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  lastName?: InputMaybe<Array<GroupByField>>;
  linkedIn?: InputMaybe<Array<GroupByField>>;
  location?: InputMaybe<LocationGroupByQuery>;
  mobilePhone?: InputMaybe<Array<GroupByField>>;
  nationality?: InputMaybe<CountryGroupByQuery>;
  personUser?: InputMaybe<AlakaUserGroupByQuery>;
  personalEmail?: InputMaybe<Array<GroupByField>>;
  phone?: InputMaybe<Array<GroupByField>>;
  preferredName?: InputMaybe<Array<GroupByField>>;
  profile?: InputMaybe<ProfileGroupByQuery>;
  skype?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workEmail?: InputMaybe<Array<GroupByField>>;
};

export type PersonListResponse = {
  __typename?: 'PersonListResponse';
  count: Scalars['Int']['output'];
  items: Array<Person>;
};

export type PersonOrderBy =
  | 'category_ASC'
  | 'category_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'firstName_ASC'
  | 'firstName_DESC'
  | 'fullName_ASC'
  | 'fullName_DESC'
  | 'gender_ASC'
  | 'gender_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'lastName_ASC'
  | 'lastName_DESC'
  | 'linkedIn_ASC'
  | 'linkedIn_DESC'
  | 'mobilePhone_ASC'
  | 'mobilePhone_DESC'
  | 'personalEmail_ASC'
  | 'personalEmail_DESC'
  | 'phone_ASC'
  | 'phone_DESC'
  | 'preferredName_ASC'
  | 'preferredName_DESC'
  | 'skype_ASC'
  | 'skype_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'workEmail_ASC'
  | 'workEmail_DESC';

export type PersonRelationFilter = {
  every?: InputMaybe<PersonFilter>;
  none?: InputMaybe<PersonFilter>;
  some?: InputMaybe<PersonFilter>;
};

export type PersonSort = {
  category?: InputMaybe<SortOrder>;
  contractor?: InputMaybe<ContractorSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  linkedIn?: InputMaybe<SortOrder>;
  mobilePhone?: InputMaybe<SortOrder>;
  nationality?: InputMaybe<CountrySort>;
  personUser?: InputMaybe<AlakaUserSort>;
  personalEmail?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  preferredName?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileSort>;
  skype?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workEmail?: InputMaybe<SortOrder>;
};

export type Position = {
  __typename?: 'Position';
  allowSubmissionAboveTargetRates: Maybe<Scalars['Boolean']['output']>;
  clearance: Maybe<ClearanceLevel>;
  contractType: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  deliveryOption: Maybe<DeliveryOption>;
  description: Maybe<Scalars['String']['output']>;
  dueOn: Maybe<Scalars['DateTime']['output']>;
  duration: Maybe<Duration>;
  evaluationMethod: Maybe<Scalars['String']['output']>;
  hasBeenPublishedTo: Maybe<SupplierListResponse>;
  id: Maybe<Scalars['ID']['output']>;
  languages: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  level: Maybe<ExperienceLevel>;
  location: Maybe<LocationListResponse>;
  maximumCandidatesAllowed: Maybe<Scalars['Int']['output']>;
  notes: Maybe<NoteListResponse>;
  project: Maybe<Project>;
  proposals: Maybe<ProposalListResponse>;
  publishProgress: Maybe<Scalars['JSON']['output']>;
  publishedTo: Maybe<SupplierListResponse>;
  publishedToAll: Maybe<Scalars['Boolean']['output']>;
  rates: Maybe<RateListResponse>;
  report: Maybe<PositionReport>;
  rfq: Maybe<RFQ>;
  shOccupation: Maybe<Scalars['String']['output']>;
  shOccupations: Maybe<Scalars['JSON']['output']>;
  showNteRatesToPartners: Maybe<Scalars['Boolean']['output']>;
  showTargetRatesToPartners: Maybe<Scalars['Boolean']['output']>;
  statuses: Maybe<PositionStatusListResponse>;
  submissions: Maybe<SubmissionListResponse>;
  supplierDueDate: Maybe<Scalars['DateTime']['output']>;
  title: Maybe<Scalars['String']['output']>;
  totalEvaluatedPrice: Maybe<Scalars['Float']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type PositionratesArgs = {
  filter?: InputMaybe<RateFilter>;
};

export type PositionstatusesArgs = {
  filter?: InputMaybe<PositionStatusFilter>;
  sort?: InputMaybe<Array<PositionStatusSort>>;
};

export type PositionFilter = {
  AND?: InputMaybe<Array<PositionFilter>>;
  OR?: InputMaybe<Array<PositionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  actors?: InputMaybe<PositionRoleRelationFilter>;
  allowSubmissionAboveTargetRates?: InputMaybe<BoolPredicate>;
  awardedTo?: InputMaybe<SupplierRelationFilter>;
  bids?: InputMaybe<SupplierRelationFilter>;
  candidateMatches?: InputMaybe<CandidateMatchRelationFilter>;
  clearance?: InputMaybe<ClearanceLevelFilter>;
  contractType?: InputMaybe<StringPredicate>;
  contractors?: InputMaybe<ContractorRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  deliverables?: InputMaybe<DeliverableRelationFilter>;
  deliveryOption?: InputMaybe<DeliveryOptionFilter>;
  department?: InputMaybe<DepartmentFilter>;
  description?: InputMaybe<StringPredicate>;
  documents?: InputMaybe<AttachmentRelationFilter>;
  dueOn?: InputMaybe<DateTimePredicate>;
  duration?: InputMaybe<DurationFilter>;
  evaluationMethod?: InputMaybe<StringPredicate>;
  extensionPossible?: InputMaybe<StringPredicate>;
  hasBeenPublishedTo?: InputMaybe<SupplierRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  incumbent?: InputMaybe<IncumbentRelationFilter>;
  level?: InputMaybe<ExperienceLevelFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  maximumCandidatesAllowed?: InputMaybe<IntPredicate>;
  notes?: InputMaybe<NoteRelationFilter>;
  occupationTemplate?: InputMaybe<OccupationTemplateFilter>;
  priority?: InputMaybe<StringPredicate>;
  project?: InputMaybe<ProjectFilter>;
  proposals?: InputMaybe<ProposalRelationFilter>;
  publishedTo?: InputMaybe<SupplierRelationFilter>;
  publishedToAll?: InputMaybe<BoolPredicate>;
  rates?: InputMaybe<RateRelationFilter>;
  report?: InputMaybe<PositionReportFilter>;
  reports?: InputMaybe<ReportRelationFilter>;
  requirements?: InputMaybe<RequirementRelationFilter>;
  rfq?: InputMaybe<RFQFilter>;
  shOccupation?: InputMaybe<StringPredicate>;
  showNteRatesToPartners?: InputMaybe<BoolPredicate>;
  showTargetRatesToPartners?: InputMaybe<BoolPredicate>;
  statuses?: InputMaybe<PositionStatusRelationFilter>;
  submissions?: InputMaybe<SubmissionRelationFilter>;
  supplierDueDate?: InputMaybe<DateTimePredicate>;
  title?: InputMaybe<StringPredicate>;
  totalEvaluatedPrice?: InputMaybe<FloatPredicate>;
  travel?: InputMaybe<TravelRequirementFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  workArrangement?: InputMaybe<WorkArrangementFilter>;
  workingHours?: InputMaybe<WorkingHourFilter>;
};

export type PositionGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: PositionGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type PositionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  actors?: InputMaybe<PositionRoleGroupByQuery>;
  allowSubmissionAboveTargetRates?: InputMaybe<Array<GroupByField>>;
  awardedTo?: InputMaybe<SupplierGroupByQuery>;
  bids?: InputMaybe<SupplierGroupByQuery>;
  candidateMatches?: InputMaybe<CandidateMatchGroupByQuery>;
  candidatesSubmissionProgress?: InputMaybe<Array<GroupByField>>;
  clearance?: InputMaybe<ClearanceLevelGroupByQuery>;
  contractType?: InputMaybe<Array<GroupByField>>;
  contractors?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  deliverables?: InputMaybe<DeliverableGroupByQuery>;
  deliveryOption?: InputMaybe<DeliveryOptionGroupByQuery>;
  department?: InputMaybe<DepartmentGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  documents?: InputMaybe<AttachmentGroupByQuery>;
  dueOn?: InputMaybe<Array<GroupByField>>;
  duration?: InputMaybe<DurationGroupByQuery>;
  evaluationMethod?: InputMaybe<Array<GroupByField>>;
  extensionPossible?: InputMaybe<Array<GroupByField>>;
  hasBeenPublishedTo?: InputMaybe<SupplierGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  incumbent?: InputMaybe<IncumbentGroupByQuery>;
  languages?: InputMaybe<Array<GroupByField>>;
  level?: InputMaybe<ExperienceLevelGroupByQuery>;
  location?: InputMaybe<LocationGroupByQuery>;
  maximumCandidatesAllowed?: InputMaybe<Array<GroupByField>>;
  notes?: InputMaybe<NoteGroupByQuery>;
  occupationTemplate?: InputMaybe<OccupationTemplateGroupByQuery>;
  priority?: InputMaybe<Array<GroupByField>>;
  project?: InputMaybe<ProjectGroupByQuery>;
  proposals?: InputMaybe<ProposalGroupByQuery>;
  publishProgress?: InputMaybe<Array<GroupByField>>;
  publishedTo?: InputMaybe<SupplierGroupByQuery>;
  publishedToAll?: InputMaybe<Array<GroupByField>>;
  rates?: InputMaybe<RateGroupByQuery>;
  report?: InputMaybe<PositionReportGroupByQuery>;
  reports?: InputMaybe<ReportGroupByQuery>;
  requirements?: InputMaybe<RequirementGroupByQuery>;
  rfq?: InputMaybe<RFQGroupByQuery>;
  shOccupation?: InputMaybe<Array<GroupByField>>;
  shOccupations?: InputMaybe<Array<GroupByField>>;
  showNteRatesToPartners?: InputMaybe<Array<GroupByField>>;
  showTargetRatesToPartners?: InputMaybe<Array<GroupByField>>;
  statuses?: InputMaybe<PositionStatusGroupByQuery>;
  submissions?: InputMaybe<SubmissionGroupByQuery>;
  supplierDueDate?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  totalEvaluatedPrice?: InputMaybe<Array<GroupByField>>;
  travel?: InputMaybe<TravelRequirementGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workArrangement?: InputMaybe<WorkArrangementGroupByQuery>;
  workingHours?: InputMaybe<WorkingHourGroupByQuery>;
};

export type PositionListResponse = {
  __typename?: 'PositionListResponse';
  count: Scalars['Int']['output'];
  items: Array<Position>;
};

export type PositionOrderBy =
  | 'allowSubmissionAboveTargetRates_ASC'
  | 'allowSubmissionAboveTargetRates_DESC'
  | 'candidatesSubmissionProgress_ASC'
  | 'candidatesSubmissionProgress_DESC'
  | 'contractType_ASC'
  | 'contractType_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'dueOn_ASC'
  | 'dueOn_DESC'
  | 'evaluationMethod_ASC'
  | 'evaluationMethod_DESC'
  | 'extensionPossible_ASC'
  | 'extensionPossible_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'maximumCandidatesAllowed_ASC'
  | 'maximumCandidatesAllowed_DESC'
  | 'priority_ASC'
  | 'priority_DESC'
  | 'publishProgress_ASC'
  | 'publishProgress_DESC'
  | 'publishedToAll_ASC'
  | 'publishedToAll_DESC'
  | 'shOccupation_ASC'
  | 'shOccupation_DESC'
  | 'shOccupations_ASC'
  | 'shOccupations_DESC'
  | 'showNteRatesToPartners_ASC'
  | 'showNteRatesToPartners_DESC'
  | 'showTargetRatesToPartners_ASC'
  | 'showTargetRatesToPartners_DESC'
  | 'supplierDueDate_ASC'
  | 'supplierDueDate_DESC'
  | 'title_ASC'
  | 'title_DESC'
  | 'totalEvaluatedPrice_ASC'
  | 'totalEvaluatedPrice_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type PositionRelationFilter = {
  every?: InputMaybe<PositionFilter>;
  none?: InputMaybe<PositionFilter>;
  some?: InputMaybe<PositionFilter>;
};

export type PositionReport = {
  __typename?: 'PositionReport';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  shPositionId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type PositionReportFilter = {
  AND?: InputMaybe<Array<PositionReportFilter>>;
  OR?: InputMaybe<Array<PositionReportFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  matchReports?: InputMaybe<MatchReportRelationFilter>;
  pendingReports?: InputMaybe<PendingReportRelationFilter>;
  position?: InputMaybe<PositionFilter>;
  shPositionId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PositionReportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  matchReports?: InputMaybe<MatchReportGroupByQuery>;
  pendingReports?: InputMaybe<PendingReportGroupByQuery>;
  position?: InputMaybe<PositionGroupByQuery>;
  shPositionId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type PositionReportSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  shPositionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PositionRoleFilter = {
  AND?: InputMaybe<Array<PositionRoleFilter>>;
  OR?: InputMaybe<Array<PositionRoleFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IDPredicate>;
  position?: InputMaybe<PositionFilter>;
  role?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DateTimePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<AlakaUserFilter>;
};

export type PositionRoleGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  endDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  role?: InputMaybe<Array<GroupByField>>;
  startDate?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<AlakaUserGroupByQuery>;
};

export type PositionRoleRelationFilter = {
  every?: InputMaybe<PositionRoleFilter>;
  none?: InputMaybe<PositionRoleFilter>;
  some?: InputMaybe<PositionRoleFilter>;
};

export type PositionSort = {
  allowSubmissionAboveTargetRates?: InputMaybe<SortOrder>;
  clearance?: InputMaybe<ClearanceLevelSort>;
  contractType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  deliveryOption?: InputMaybe<DeliveryOptionSort>;
  department?: InputMaybe<DepartmentSort>;
  description?: InputMaybe<SortOrder>;
  dueOn?: InputMaybe<SortOrder>;
  duration?: InputMaybe<DurationSort>;
  evaluationMethod?: InputMaybe<SortOrder>;
  extensionPossible?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  level?: InputMaybe<ExperienceLevelSort>;
  maximumCandidatesAllowed?: InputMaybe<SortOrder>;
  occupationTemplate?: InputMaybe<OccupationTemplateSort>;
  priority?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  publishedToAll?: InputMaybe<SortOrder>;
  report?: InputMaybe<PositionReportSort>;
  rfq?: InputMaybe<RFQSort>;
  shOccupation?: InputMaybe<SortOrder>;
  showNteRatesToPartners?: InputMaybe<SortOrder>;
  showTargetRatesToPartners?: InputMaybe<SortOrder>;
  supplierDueDate?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalEvaluatedPrice?: InputMaybe<SortOrder>;
  travel?: InputMaybe<TravelRequirementSort>;
  updatedAt?: InputMaybe<SortOrder>;
  workArrangement?: InputMaybe<WorkArrangementSort>;
  workingHours?: InputMaybe<WorkingHourSort>;
};

export type PositionStatus = {
  __typename?: 'PositionStatus';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  end: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  note: Maybe<Note>;
  start: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type PositionStatusFilter = {
  AND?: InputMaybe<Array<PositionStatusFilter>>;
  OR?: InputMaybe<Array<PositionStatusFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  end?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  note?: InputMaybe<NoteFilter>;
  position?: InputMaybe<PositionFilter>;
  start?: InputMaybe<DateTimePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PositionStatusFilterValue =
  | 'CANCELLED'
  | 'CLOSED'
  | 'DELIVERY'
  | 'NO_SELECTION'
  | 'NO_SUBMISSIONS'
  | 'OFFER'
  | 'ONBOARDING'
  | 'ONBOARDING_CANCELLED'
  | 'ONBOARDING_COMPLETED'
  | 'ONBOARDING_IN_PROGRESS'
  | 'PENDING_SELECTION'
  | 'RECRUITING'
  | 'SELECTION';

export type PositionStatusGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  end?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  note?: InputMaybe<NoteGroupByQuery>;
  position?: InputMaybe<PositionGroupByQuery>;
  start?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type PositionStatusListResponse = {
  __typename?: 'PositionStatusListResponse';
  count: Scalars['Int']['output'];
  items: Array<PositionStatus>;
};

export type PositionStatusRelationFilter = {
  every?: InputMaybe<PositionStatusFilter>;
  none?: InputMaybe<PositionStatusFilter>;
  some?: InputMaybe<PositionStatusFilter>;
};

export type PositionStatusSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  note?: InputMaybe<NoteSort>;
  position?: InputMaybe<PositionSort>;
  start?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PositionSubmissionFilterValue =
  | 'CANDIDATE_RECEIVED'
  | 'CANDIDATE_SUBMITTED'
  | 'NO_CANDIDATE_RECEIVED'
  | 'NO_CANDIDATE_SUBMITTED';

export type Profile = {
  __typename?: 'Profile';
  _description: Maybe<Scalars['String']['output']>;
  candidate: Maybe<Candidate>;
  citizenship: Maybe<Country>;
  clearance: Maybe<Clearance>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  exIBM: Maybe<Scalars['Boolean']['output']>;
  experienceLevel: Maybe<ExperienceLevel>;
  fullName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  isSnapshot: Maybe<Scalars['Boolean']['output']>;
  linkedIn: Maybe<Scalars['String']['output']>;
  location: Maybe<Location>;
  permIssuingCountry: Maybe<Country>;
  person: Maybe<Person>;
  phone: Maybe<Scalars['String']['output']>;
  preferredName: Maybe<Scalars['String']['output']>;
  profession: Maybe<Scalars['String']['output']>;
  skype: Maybe<Scalars['String']['output']>;
  submission: Maybe<Submission>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  willingToRelocate: Maybe<Scalars['String']['output']>;
};

export type ProfileFilter = {
  AND?: InputMaybe<Array<ProfileFilter>>;
  OR?: InputMaybe<Array<ProfileFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  candidate?: InputMaybe<CandidateFilter>;
  citizenship?: InputMaybe<CountryFilter>;
  clearance?: InputMaybe<ClearanceFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  email?: InputMaybe<StringPredicate>;
  exIBM?: InputMaybe<BoolPredicate>;
  experienceLevel?: InputMaybe<ExperienceLevelFilter>;
  fullName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  isSnapshot?: InputMaybe<BoolPredicate>;
  linkedIn?: InputMaybe<StringPredicate>;
  location?: InputMaybe<LocationFilter>;
  permIssuingCountry?: InputMaybe<CountryFilter>;
  person?: InputMaybe<PersonFilter>;
  phone?: InputMaybe<StringPredicate>;
  preferredName?: InputMaybe<StringPredicate>;
  profession?: InputMaybe<StringPredicate>;
  skype?: InputMaybe<StringPredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  willingToRelocate?: InputMaybe<StringPredicate>;
};

export type ProfileGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: ProfileGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ProfileGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  candidate?: InputMaybe<CandidateGroupByQuery>;
  citizenship?: InputMaybe<CountryGroupByQuery>;
  clearance?: InputMaybe<ClearanceGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  email?: InputMaybe<Array<GroupByField>>;
  exIBM?: InputMaybe<Array<GroupByField>>;
  experienceLevel?: InputMaybe<ExperienceLevelGroupByQuery>;
  fullName?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isSnapshot?: InputMaybe<Array<GroupByField>>;
  linkedIn?: InputMaybe<Array<GroupByField>>;
  location?: InputMaybe<LocationGroupByQuery>;
  permIssuingCountry?: InputMaybe<CountryGroupByQuery>;
  person?: InputMaybe<PersonGroupByQuery>;
  phone?: InputMaybe<Array<GroupByField>>;
  preferredName?: InputMaybe<Array<GroupByField>>;
  profession?: InputMaybe<Array<GroupByField>>;
  skype?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  willingToRelocate?: InputMaybe<Array<GroupByField>>;
};

export type ProfileListResponse = {
  __typename?: 'ProfileListResponse';
  count: Scalars['Int']['output'];
  items: Array<Profile>;
};

export type ProfileOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'email_ASC'
  | 'email_DESC'
  | 'exIBM_ASC'
  | 'exIBM_DESC'
  | 'fullName_ASC'
  | 'fullName_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isSnapshot_ASC'
  | 'isSnapshot_DESC'
  | 'linkedIn_ASC'
  | 'linkedIn_DESC'
  | 'phone_ASC'
  | 'phone_DESC'
  | 'preferredName_ASC'
  | 'preferredName_DESC'
  | 'profession_ASC'
  | 'profession_DESC'
  | 'skype_ASC'
  | 'skype_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'willingToRelocate_ASC'
  | 'willingToRelocate_DESC';

export type ProfileRelationFilter = {
  every?: InputMaybe<ProfileFilter>;
  none?: InputMaybe<ProfileFilter>;
  some?: InputMaybe<ProfileFilter>;
};

export type ProfileSort = {
  candidate?: InputMaybe<CandidateSort>;
  citizenship?: InputMaybe<CountrySort>;
  clearance?: InputMaybe<ClearanceSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  exIBM?: InputMaybe<SortOrder>;
  experienceLevel?: InputMaybe<ExperienceLevelSort>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSnapshot?: InputMaybe<SortOrder>;
  linkedIn?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationSort>;
  permIssuingCountry?: InputMaybe<CountrySort>;
  person?: InputMaybe<PersonSort>;
  phone?: InputMaybe<SortOrder>;
  preferredName?: InputMaybe<SortOrder>;
  profession?: InputMaybe<SortOrder>;
  skype?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SubmissionSort>;
  updatedAt?: InputMaybe<SortOrder>;
  willingToRelocate?: InputMaybe<SortOrder>;
};

export type Project = {
  __typename?: 'Project';
  _description: Maybe<Scalars['String']['output']>;
  allowSubmissionAboveTargetRates: Maybe<Scalars['Boolean']['output']>;
  clearanceLevelCollection: Maybe<ClearanceLevelCollection>;
  clearanceSpecificToPosition: Maybe<Scalars['Boolean']['output']>;
  clearances: Maybe<ClearanceLevelListResponse>;
  client: Maybe<Client>;
  config: Maybe<Scalars['JSON']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  importForm: Maybe<FormListResponse>;
  name: Maybe<Scalars['String']['output']>;
  projectForm: Maybe<FormListResponse>;
  shProjectId: Maybe<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  showNteRatesToPartners: Maybe<Scalars['Boolean']['output']>;
  showTargetRatesToPartners: Maybe<Scalars['Boolean']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ProjectclearancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClearanceLevelFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ClearanceLevelGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClearanceLevelOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClearanceLevelSort>>;
};

export type ProjectimportFormArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FormFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<FormGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FormSort>>;
};

export type ProjectprojectFormArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FormFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<FormGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FormSort>>;
};

export type ProjectFilter = {
  AND?: InputMaybe<Array<ProjectFilter>>;
  OR?: InputMaybe<Array<ProjectFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  allowSubmissionAboveTargetRates?: InputMaybe<BoolPredicate>;
  clearanceLevelCollection?: InputMaybe<ClearanceLevelCollectionFilter>;
  clearanceSpecificToPosition?: InputMaybe<BoolPredicate>;
  clearances?: InputMaybe<ClearanceLevelRelationFilter>;
  client?: InputMaybe<ClientFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  currency?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  emailTemplates?: InputMaybe<EmailTemplateRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  importForm?: InputMaybe<FormRelationFilter>;
  levels?: InputMaybe<ExperienceLevelRelationFilter>;
  name?: InputMaybe<StringPredicate>;
  positions?: InputMaybe<PositionRelationFilter>;
  projectForm?: InputMaybe<FormRelationFilter>;
  projectOccupations?: InputMaybe<OccupationCollectionFilter>;
  projectRole?: InputMaybe<ProjectRoleRelationFilter>;
  rfqs?: InputMaybe<RFQRelationFilter>;
  shProjectId?: InputMaybe<StringPredicate>;
  shortName?: InputMaybe<StringPredicate>;
  showNteRatesToPartners?: InputMaybe<BoolPredicate>;
  showTargetRatesToPartners?: InputMaybe<BoolPredicate>;
  status?: InputMaybe<ProjectStatusRelationFilter>;
  suppliers?: InputMaybe<SupplierRelationFilter>;
  templateGroup?: InputMaybe<TemplateGroupFilter>;
  timezone?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  workingHours?: InputMaybe<WorkingHourRelationFilter>;
};

export type ProjectGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: ProjectGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ProjectGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  allowSubmissionAboveTargetRates?: InputMaybe<Array<GroupByField>>;
  clearanceLevelCollection?: InputMaybe<ClearanceLevelCollectionGroupByQuery>;
  clearanceSpecificToPosition?: InputMaybe<Array<GroupByField>>;
  clearances?: InputMaybe<ClearanceLevelGroupByQuery>;
  client?: InputMaybe<ClientGroupByQuery>;
  config?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  currency?: InputMaybe<Array<GroupByField>>;
  description?: InputMaybe<Array<GroupByField>>;
  emailTemplates?: InputMaybe<EmailTemplateGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  importForm?: InputMaybe<FormGroupByQuery>;
  levels?: InputMaybe<ExperienceLevelGroupByQuery>;
  name?: InputMaybe<Array<GroupByField>>;
  positions?: InputMaybe<PositionGroupByQuery>;
  projectForm?: InputMaybe<FormGroupByQuery>;
  projectOccupations?: InputMaybe<OccupationCollectionGroupByQuery>;
  projectRole?: InputMaybe<ProjectRoleGroupByQuery>;
  rfqs?: InputMaybe<RFQGroupByQuery>;
  shProjectId?: InputMaybe<Array<GroupByField>>;
  shortName?: InputMaybe<Array<GroupByField>>;
  showNteRatesToPartners?: InputMaybe<Array<GroupByField>>;
  showTargetRatesToPartners?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<ProjectStatusGroupByQuery>;
  suppliers?: InputMaybe<SupplierGroupByQuery>;
  templateGroup?: InputMaybe<TemplateGroupGroupByQuery>;
  timezone?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workingHours?: InputMaybe<WorkingHourGroupByQuery>;
};

export type ProjectListResponse = {
  __typename?: 'ProjectListResponse';
  count: Scalars['Int']['output'];
  items: Array<Project>;
};

export type ProjectOrderBy =
  | 'allowSubmissionAboveTargetRates_ASC'
  | 'allowSubmissionAboveTargetRates_DESC'
  | 'clearanceSpecificToPosition_ASC'
  | 'clearanceSpecificToPosition_DESC'
  | 'config_ASC'
  | 'config_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'currency_ASC'
  | 'currency_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'shProjectId_ASC'
  | 'shProjectId_DESC'
  | 'shortName_ASC'
  | 'shortName_DESC'
  | 'showNteRatesToPartners_ASC'
  | 'showNteRatesToPartners_DESC'
  | 'showTargetRatesToPartners_ASC'
  | 'showTargetRatesToPartners_DESC'
  | 'timezone_ASC'
  | 'timezone_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type ProjectRelationFilter = {
  every?: InputMaybe<ProjectFilter>;
  none?: InputMaybe<ProjectFilter>;
  some?: InputMaybe<ProjectFilter>;
};

export type ProjectRoleFilter = {
  AND?: InputMaybe<Array<ProjectRoleFilter>>;
  OR?: InputMaybe<Array<ProjectRoleFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  projects?: InputMaybe<ProjectRelationFilter>;
  role?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<AlakaUserFilter>;
  userLastRole?: InputMaybe<AlakaUserFilter>;
};

export type ProjectRoleGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  projects?: InputMaybe<ProjectGroupByQuery>;
  role?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<AlakaUserGroupByQuery>;
  userLastRole?: InputMaybe<AlakaUserGroupByQuery>;
};

export type ProjectRoleRelationFilter = {
  every?: InputMaybe<ProjectRoleFilter>;
  none?: InputMaybe<ProjectRoleFilter>;
  some?: InputMaybe<ProjectRoleFilter>;
};

export type ProjectRoleSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<AlakaUserSort>;
  userLastRole?: InputMaybe<AlakaUserSort>;
};

export type ProjectSort = {
  allowSubmissionAboveTargetRates?: InputMaybe<SortOrder>;
  clearanceLevelCollection?: InputMaybe<ClearanceLevelCollectionSort>;
  clearanceSpecificToPosition?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  currency?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectOccupations?: InputMaybe<OccupationCollectionSort>;
  shProjectId?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  showNteRatesToPartners?: InputMaybe<SortOrder>;
  showTargetRatesToPartners?: InputMaybe<SortOrder>;
  templateGroup?: InputMaybe<TemplateGroupSort>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProjectStatusFilter = {
  AND?: InputMaybe<Array<ProjectStatusFilter>>;
  OR?: InputMaybe<Array<ProjectStatusFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IDPredicate>;
  project?: InputMaybe<ProjectRelationFilter>;
  startDate?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ProjectStatusGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  endDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  project?: InputMaybe<ProjectGroupByQuery>;
  startDate?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ProjectStatusRelationFilter = {
  every?: InputMaybe<ProjectStatusFilter>;
  none?: InputMaybe<ProjectStatusFilter>;
  some?: InputMaybe<ProjectStatusFilter>;
};

export type Proposal = {
  __typename?: 'Proposal';
  _description: Maybe<Scalars['String']['output']>;
  availability: Maybe<Availability>;
  clearance: Maybe<Clearance>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  documents: Maybe<AttachmentListResponse>;
  email: Maybe<Scalars['String']['output']>;
  hiringProcess: Maybe<HiringProcess>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  statuses: Maybe<SubmissionStatusListResponse>;
  submissionDate: Maybe<Scalars['DateTime']['output']>;
  supplier: Maybe<Supplier>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ProposaldocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AttachmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<AttachmentGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AttachmentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AttachmentSort>>;
};

export type ProposalstatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SubmissionStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<SubmissionStatusGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SubmissionStatusOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SubmissionStatusSort>>;
};

export type ProposalFilter = {
  AND?: InputMaybe<Array<ProposalFilter>>;
  OR?: InputMaybe<Array<ProposalFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  availability?: InputMaybe<AvailabilityFilter>;
  clearance?: InputMaybe<ClearanceFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  documents?: InputMaybe<AttachmentRelationFilter>;
  email?: InputMaybe<StringPredicate>;
  hiringProcess?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  notes?: InputMaybe<NoteRelationFilter>;
  phone?: InputMaybe<StringPredicate>;
  position?: InputMaybe<PositionFilter>;
  rates?: InputMaybe<RateRelationFilter>;
  roles?: InputMaybe<SubmissionRoleRelationFilter>;
  statuses?: InputMaybe<SubmissionStatusRelationFilter>;
  submissionDate?: InputMaybe<DateTimePredicate>;
  supplier?: InputMaybe<SupplierFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ProposalGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  availability?: InputMaybe<AvailabilityGroupByQuery>;
  clearance?: InputMaybe<ClearanceGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  documents?: InputMaybe<AttachmentGroupByQuery>;
  email?: InputMaybe<Array<GroupByField>>;
  hiringProcess?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  notes?: InputMaybe<NoteGroupByQuery>;
  phone?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  rates?: InputMaybe<RateGroupByQuery>;
  roles?: InputMaybe<SubmissionRoleGroupByQuery>;
  statuses?: InputMaybe<SubmissionStatusGroupByQuery>;
  submissionDate?: InputMaybe<Array<GroupByField>>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ProposalListResponse = {
  __typename?: 'ProposalListResponse';
  count: Scalars['Int']['output'];
  items: Array<Proposal>;
};

export type ProposalRelationFilter = {
  every?: InputMaybe<ProposalFilter>;
  none?: InputMaybe<ProposalFilter>;
  some?: InputMaybe<ProposalFilter>;
};

export type ProposalSort = {
  availability?: InputMaybe<AvailabilitySort>;
  clearance?: InputMaybe<ClearanceSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  hiringProcess?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  submissionDate?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PublishStatusFilterValue = 'PUBLISHED' | 'UNPUBLISHED';

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  _description: Maybe<Scalars['String']['output']>;
  amendment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  effort: Maybe<Scalars['Float']['output']>;
  end: Maybe<Scalars['Date']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  number: Maybe<Scalars['String']['output']>;
  start: Maybe<Scalars['Date']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type PurchaseOrderFilter = {
  AND?: InputMaybe<Array<PurchaseOrderFilter>>;
  OR?: InputMaybe<Array<PurchaseOrderFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  amendment?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  effort?: InputMaybe<FloatPredicate>;
  end?: InputMaybe<DatePredicate>;
  file?: InputMaybe<AttachmentFilter>;
  hiringProcess?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  number?: InputMaybe<StringPredicate>;
  rate?: InputMaybe<RateFilter>;
  start?: InputMaybe<DatePredicate>;
  totalBudget?: InputMaybe<RateFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PurchaseOrderGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  amendment?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  effort?: InputMaybe<Array<GroupByField>>;
  end?: InputMaybe<Array<GroupByField>>;
  file?: InputMaybe<AttachmentGroupByQuery>;
  hiringProcess?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  number?: InputMaybe<Array<GroupByField>>;
  rate?: InputMaybe<RateGroupByQuery>;
  start?: InputMaybe<Array<GroupByField>>;
  totalBudget?: InputMaybe<RateGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type PurchaseOrderSort = {
  amendment?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  effort?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  file?: InputMaybe<AttachmentSort>;
  hiringProcess?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  rate?: InputMaybe<RateSort>;
  start?: InputMaybe<SortOrder>;
  totalBudget?: InputMaybe<RateSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Query = {
  __typename?: 'Query';
  positionsList: PositionListResponse;
  record: Maybe<ImportRecord>;
  recordsList: ImportRecordsResponse;
  submissionsList: SubmissionListResponse;
};

export type QuerypositionsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NewPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PositionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PositionSort>>;
};

export type QueryrecordArgs = {
  id: Scalars['ID']['input'];
};

export type QueryrecordsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ImportRecordsFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerysubmissionsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NewSubmissionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SubmissionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SubmissionSort>>;
};

export type RFQ = {
  __typename?: 'RFQ';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RFQFilter = {
  AND?: InputMaybe<Array<RFQFilter>>;
  OR?: InputMaybe<Array<RFQFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  duration?: InputMaybe<DurationFilter>;
  id?: InputMaybe<IDPredicate>;
  import?: InputMaybe<ImportFilter>;
  name?: InputMaybe<StringPredicate>;
  positions?: InputMaybe<PositionRelationFilter>;
  project?: InputMaybe<ProjectFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type RFQGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  duration?: InputMaybe<DurationGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  import?: InputMaybe<ImportGroupByQuery>;
  name?: InputMaybe<Array<GroupByField>>;
  positions?: InputMaybe<PositionGroupByQuery>;
  project?: InputMaybe<ProjectGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type RFQRelationFilter = {
  every?: InputMaybe<RFQFilter>;
  none?: InputMaybe<RFQFilter>;
  some?: InputMaybe<RFQFilter>;
};

export type RFQSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  duration?: InputMaybe<DurationSort>;
  id?: InputMaybe<SortOrder>;
  import?: InputMaybe<ImportSort>;
  name?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Rate = {
  __typename?: 'Rate';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  endDate: Maybe<Scalars['Date']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  rateType: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['Date']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

export type RateFilter = {
  AND?: InputMaybe<Array<RateFilter>>;
  OR?: InputMaybe<Array<RateFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  candidateExpectedRate?: InputMaybe<CandidateFilter>;
  contractor?: InputMaybe<ContractorFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  currency?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DatePredicate>;
  group?: InputMaybe<RateGroupFilter>;
  id?: InputMaybe<IDPredicate>;
  position?: InputMaybe<PositionFilter>;
  proposal?: InputMaybe<ProposalFilter>;
  purchaseOrderBudget?: InputMaybe<PurchaseOrderFilter>;
  purchaseOrderRate?: InputMaybe<PurchaseOrderFilter>;
  rateType?: InputMaybe<StringPredicate>;
  revisionType?: InputMaybe<RevisionTypeFilter>;
  startDate?: InputMaybe<DatePredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  value?: InputMaybe<FloatPredicate>;
};

export type RateGroup = {
  __typename?: 'RateGroup';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  rates: Maybe<RateListResponse>;
  revision: Maybe<RevisionType>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RateGroupratesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<RateGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<RateOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RateSort>>;
};

export type RateGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: RateGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type RateGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  candidateExpectedRate?: InputMaybe<CandidateGroupByQuery>;
  contractor?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  currency?: InputMaybe<Array<GroupByField>>;
  endDate?: InputMaybe<Array<GroupByField>>;
  group?: InputMaybe<RateGroupGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  purchaseOrderBudget?: InputMaybe<PurchaseOrderGroupByQuery>;
  purchaseOrderRate?: InputMaybe<PurchaseOrderGroupByQuery>;
  rateType?: InputMaybe<Array<GroupByField>>;
  revisionType?: InputMaybe<RevisionTypeGroupByQuery>;
  startDate?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  value?: InputMaybe<Array<GroupByField>>;
};

export type RateGroupFilter = {
  AND?: InputMaybe<Array<RateGroupFilter>>;
  OR?: InputMaybe<Array<RateGroupFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  rates?: InputMaybe<RateRelationFilter>;
  revision?: InputMaybe<RevisionTypeFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type RateGroupGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  rates?: InputMaybe<RateGroupByQuery>;
  revision?: InputMaybe<RevisionTypeGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type RateGroupSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  revision?: InputMaybe<RevisionTypeSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RateListResponse = {
  __typename?: 'RateListResponse';
  count: Scalars['Int']['output'];
  items: Array<Rate>;
};

export type RateOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'currency_ASC'
  | 'currency_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'endDate_ASC'
  | 'endDate_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'rateType_ASC'
  | 'rateType_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'value_ASC'
  | 'value_DESC';

export type RateRelationFilter = {
  every?: InputMaybe<RateFilter>;
  none?: InputMaybe<RateFilter>;
  some?: InputMaybe<RateFilter>;
};

export type RateSort = {
  candidateExpectedRate?: InputMaybe<CandidateSort>;
  contractor?: InputMaybe<ContractorSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  currency?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  group?: InputMaybe<RateGroupSort>;
  id?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  proposal?: InputMaybe<ProposalSort>;
  purchaseOrderBudget?: InputMaybe<PurchaseOrderSort>;
  purchaseOrderRate?: InputMaybe<PurchaseOrderSort>;
  rateType?: InputMaybe<SortOrder>;
  revisionType?: InputMaybe<RevisionTypeSort>;
  startDate?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SubmissionSort>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RelativePredicateOpEnum = 'ADD' | 'SUB';

export type RelativePredicateUnitEnum =
  | 'DAY'
  | 'DAY_HOUR'
  | 'DAY_MICROSECOND'
  | 'DAY_MINUTE'
  | 'DAY_SECOND'
  | 'HOUR'
  | 'HOUR_MICROSECOND'
  | 'HOUR_MINUTE'
  | 'HOUR_SECOND'
  | 'MICROSECOND'
  | 'MINUTE'
  | 'MINUTE_MICROSECOND'
  | 'MINUTE_SECOND'
  | 'MONTH'
  | 'QUARTER'
  | 'SECOND'
  | 'SECOND_MICROSECOND'
  | 'WEEK'
  | 'YEAR'
  | 'YEAR_MONTH';

export type ReplaceFunctionArguments = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type Report = {
  __typename?: 'Report';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  cvFileId: Maybe<Attachment>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  engineVersion: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  result: Maybe<Scalars['JSON']['output']>;
  score: Maybe<Scalars['Float']['output']>;
  shCandidateId: Maybe<Scalars['String']['output']>;
  shId: Maybe<Scalars['String']['output']>;
  shMatchId: Maybe<Scalars['String']['output']>;
  shPositionId: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ReportFilter = {
  AND?: InputMaybe<Array<ReportFilter>>;
  OR?: InputMaybe<Array<ReportFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  cvFileId?: InputMaybe<AttachmentFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  engineVersion?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  position?: InputMaybe<PositionFilter>;
  revisionType?: InputMaybe<RevisionTypeFilter>;
  score?: InputMaybe<FloatPredicate>;
  shCandidateId?: InputMaybe<StringPredicate>;
  shId?: InputMaybe<StringPredicate>;
  shMatchId?: InputMaybe<StringPredicate>;
  shPositionId?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
  supplier?: InputMaybe<SupplierFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ReportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  cvFileId?: InputMaybe<AttachmentGroupByQuery>;
  engineVersion?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  result?: InputMaybe<Array<GroupByField>>;
  revisionType?: InputMaybe<RevisionTypeGroupByQuery>;
  score?: InputMaybe<Array<GroupByField>>;
  shCandidateId?: InputMaybe<Array<GroupByField>>;
  shId?: InputMaybe<Array<GroupByField>>;
  shMatchId?: InputMaybe<Array<GroupByField>>;
  shPositionId?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  supplier?: InputMaybe<SupplierGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ReportRelationFilter = {
  every?: InputMaybe<ReportFilter>;
  none?: InputMaybe<ReportFilter>;
  some?: InputMaybe<ReportFilter>;
};

export type ReportSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  cvFileId?: InputMaybe<AttachmentSort>;
  deletedAt?: InputMaybe<SortOrder>;
  engineVersion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  revisionType?: InputMaybe<RevisionTypeSort>;
  score?: InputMaybe<SortOrder>;
  shCandidateId?: InputMaybe<SortOrder>;
  shId?: InputMaybe<SortOrder>;
  shMatchId?: InputMaybe<SortOrder>;
  shPositionId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RequirementFilter = {
  AND?: InputMaybe<Array<RequirementFilter>>;
  OR?: InputMaybe<Array<RequirementFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  activitiesOccupation?: InputMaybe<OccupationTemplateFilter>;
  color?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  importance?: InputMaybe<StringPredicate>;
  label?: InputMaybe<StringPredicate>;
  occupation?: InputMaybe<OccupationTemplateFilter>;
  order?: InputMaybe<IntPredicate>;
  percentage?: InputMaybe<FloatPredicate>;
  position?: InputMaybe<PositionFilter>;
  requirementType?: InputMaybe<StringPredicate>;
  translationKey?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type RequirementGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  activitiesOccupation?: InputMaybe<OccupationTemplateGroupByQuery>;
  color?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  importance?: InputMaybe<Array<GroupByField>>;
  label?: InputMaybe<Array<GroupByField>>;
  occupation?: InputMaybe<OccupationTemplateGroupByQuery>;
  order?: InputMaybe<Array<GroupByField>>;
  percentage?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  requirementType?: InputMaybe<Array<GroupByField>>;
  translationKey?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type RequirementRelationFilter = {
  every?: InputMaybe<RequirementFilter>;
  none?: InputMaybe<RequirementFilter>;
  some?: InputMaybe<RequirementFilter>;
};

export type Revision = {
  __typename?: 'Revision';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  statuses: Maybe<RevisionStatusListResponse>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RevisionstatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RevisionStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<RevisionStatusGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<RevisionStatusOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RevisionStatusSort>>;
};

export type RevisionFilter = {
  AND?: InputMaybe<Array<RevisionFilter>>;
  OR?: InputMaybe<Array<RevisionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  revisedAt?: InputMaybe<DateTimePredicate>;
  revisedBy?: InputMaybe<AlakaUserFilter>;
  statuses?: InputMaybe<RevisionStatusRelationFilter>;
  submission?: InputMaybe<SubmissionFilter>;
  types?: InputMaybe<RevisionTypeRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type RevisionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  revisedAt?: InputMaybe<Array<GroupByField>>;
  revisedBy?: InputMaybe<AlakaUserGroupByQuery>;
  statuses?: InputMaybe<RevisionStatusGroupByQuery>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  types?: InputMaybe<RevisionTypeGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workInProgress?: InputMaybe<Array<GroupByField>>;
};

export type RevisionListResponse = {
  __typename?: 'RevisionListResponse';
  count: Scalars['Int']['output'];
  items: Array<Revision>;
};

export type RevisionRelationFilter = {
  every?: InputMaybe<RevisionFilter>;
  none?: InputMaybe<RevisionFilter>;
  some?: InputMaybe<RevisionFilter>;
};

export type RevisionSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  revisedAt?: InputMaybe<SortOrder>;
  revisedBy?: InputMaybe<AlakaUserSort>;
  submission?: InputMaybe<SubmissionSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RevisionStatus = {
  __typename?: 'RevisionStatus';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  end: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  revision: Maybe<Revision>;
  revisionType: Maybe<RevisionType>;
  start: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RevisionStatusFilter = {
  AND?: InputMaybe<Array<RevisionStatusFilter>>;
  OR?: InputMaybe<Array<RevisionStatusFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  end?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IDPredicate>;
  revision?: InputMaybe<RevisionFilter>;
  revisionType?: InputMaybe<RevisionTypeFilter>;
  start?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type RevisionStatusGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: RevisionStatusGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type RevisionStatusGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  end?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  revision?: InputMaybe<RevisionGroupByQuery>;
  revisionType?: InputMaybe<RevisionTypeGroupByQuery>;
  start?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type RevisionStatusListResponse = {
  __typename?: 'RevisionStatusListResponse';
  count: Scalars['Int']['output'];
  items: Array<RevisionStatus>;
};

export type RevisionStatusOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'end_ASC'
  | 'end_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'start_ASC'
  | 'start_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type RevisionStatusRelationFilter = {
  every?: InputMaybe<RevisionStatusFilter>;
  none?: InputMaybe<RevisionStatusFilter>;
  some?: InputMaybe<RevisionStatusFilter>;
};

export type RevisionStatusSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  revision?: InputMaybe<RevisionSort>;
  revisionType?: InputMaybe<RevisionTypeSort>;
  start?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RevisionType = {
  __typename?: 'RevisionType';
  _description: Maybe<Scalars['String']['output']>;
  additionalData: Maybe<Scalars['JSON']['output']>;
  additionalNote: Maybe<Note>;
  attachment: Maybe<Attachment>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  rates: Maybe<RateListResponse>;
  reason: Maybe<Note>;
  rejectionNote: Maybe<Note>;
  report: Maybe<Report>;
  revision: Maybe<Revision>;
  status: Maybe<RevisionStatus>;
  submittedRates: Maybe<RateGroup>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RevisionTyperatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<RateGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<RateOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RateSort>>;
};

export type RevisionTypeFilter = {
  AND?: InputMaybe<Array<RevisionTypeFilter>>;
  OR?: InputMaybe<Array<RevisionTypeFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  additionalNote?: InputMaybe<NoteFilter>;
  attachment?: InputMaybe<AttachmentFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  rates?: InputMaybe<RateRelationFilter>;
  reason?: InputMaybe<NoteFilter>;
  rejectionNote?: InputMaybe<NoteFilter>;
  report?: InputMaybe<ReportFilter>;
  revision?: InputMaybe<RevisionFilter>;
  status?: InputMaybe<RevisionStatusFilter>;
  submittedRates?: InputMaybe<RateGroupFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type RevisionTypeGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  additionalData?: InputMaybe<Array<GroupByField>>;
  additionalNote?: InputMaybe<NoteGroupByQuery>;
  attachment?: InputMaybe<AttachmentGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  rates?: InputMaybe<RateGroupByQuery>;
  reason?: InputMaybe<NoteGroupByQuery>;
  rejectionNote?: InputMaybe<NoteGroupByQuery>;
  report?: InputMaybe<ReportGroupByQuery>;
  revision?: InputMaybe<RevisionGroupByQuery>;
  status?: InputMaybe<RevisionStatusGroupByQuery>;
  submittedRates?: InputMaybe<RateGroupGroupByQuery>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type RevisionTypeRelationFilter = {
  every?: InputMaybe<RevisionTypeFilter>;
  none?: InputMaybe<RevisionTypeFilter>;
  some?: InputMaybe<RevisionTypeFilter>;
};

export type RevisionTypeSort = {
  additionalNote?: InputMaybe<NoteSort>;
  attachment?: InputMaybe<AttachmentSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<NoteSort>;
  rejectionNote?: InputMaybe<NoteSort>;
  report?: InputMaybe<ReportSort>;
  revision?: InputMaybe<RevisionSort>;
  status?: InputMaybe<RevisionStatusSort>;
  submittedRates?: InputMaybe<RateGroupSort>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RoleFilter = {
  AND?: InputMaybe<Array<RoleFilter>>;
  OR?: InputMaybe<Array<RoleFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  apiTokens?: InputMaybe<ApiTokenRelationFilter>;
  authenticationProfiles?: InputMaybe<AuthenticationProfileRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  permissions?: InputMaybe<PermissionRelationFilter>;
  teamMembers?: InputMaybe<TeamMemberRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<UserRelationFilter>;
};

export type RoleGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  apiTokens?: InputMaybe<ApiTokenGroupByQuery>;
  authenticationProfiles?: InputMaybe<AuthenticationProfileGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  permissions?: InputMaybe<PermissionGroupByQuery>;
  teamMembers?: InputMaybe<TeamMemberGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  users?: InputMaybe<UserGroupByQuery>;
};

export type RoleRelationFilter = {
  every?: InputMaybe<RoleFilter>;
  none?: InputMaybe<RoleFilter>;
  some?: InputMaybe<RoleFilter>;
};

export type SettingFilter = {
  AND?: InputMaybe<Array<SettingFilter>>;
  OR?: InputMaybe<Array<SettingFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  bgColor?: InputMaybe<StringPredicate>;
  buttonLinkColor?: InputMaybe<StringPredicate>;
  containerColor?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  currency?: InputMaybe<StringPredicate>;
  dateFormat?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  landingPageImage?: InputMaybe<FileFilter>;
  language?: InputMaybe<StringPredicate>;
  leftNavColor?: InputMaybe<StringPredicate>;
  menuBarBGColor?: InputMaybe<StringPredicate>;
  menuBarIconsColor?: InputMaybe<StringPredicate>;
  menuBarLogo?: InputMaybe<FileFilter>;
  passwordMinLength?: InputMaybe<IntPredicate>;
  passwordRequireLowercase?: InputMaybe<BoolPredicate>;
  passwordRequireNumbers?: InputMaybe<BoolPredicate>;
  passwordRequireSpecial?: InputMaybe<BoolPredicate>;
  passwordRequireUppercase?: InputMaybe<BoolPredicate>;
  passwordUpdateInterval?: InputMaybe<IntPredicate>;
  rememberDevice?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userInterfaceStyle?: InputMaybe<StringPredicate>;
  vanityUrl?: InputMaybe<StringPredicate>;
};

export type SettingGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  bgColor?: InputMaybe<Array<GroupByField>>;
  buttonLinkColor?: InputMaybe<Array<GroupByField>>;
  containerColor?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  currency?: InputMaybe<Array<GroupByField>>;
  dateFormat?: InputMaybe<Array<GroupByField>>;
  landingPageImage?: InputMaybe<FileGroupByQuery>;
  language?: InputMaybe<Array<GroupByField>>;
  leftNavColor?: InputMaybe<Array<GroupByField>>;
  menuBarBGColor?: InputMaybe<Array<GroupByField>>;
  menuBarIconsColor?: InputMaybe<Array<GroupByField>>;
  menuBarLogo?: InputMaybe<FileGroupByQuery>;
  passwordMinLength?: InputMaybe<Array<GroupByField>>;
  passwordRequireLowercase?: InputMaybe<Array<GroupByField>>;
  passwordRequireNumbers?: InputMaybe<Array<GroupByField>>;
  passwordRequireSpecial?: InputMaybe<Array<GroupByField>>;
  passwordRequireUppercase?: InputMaybe<Array<GroupByField>>;
  passwordUpdateInterval?: InputMaybe<Array<GroupByField>>;
  rememberDevice?: InputMaybe<Array<GroupByField>>;
  timezone?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userInterfaceStyle?: InputMaybe<Array<GroupByField>>;
  vanityUrl?: InputMaybe<Array<GroupByField>>;
};

export type SettingRelationFilter = {
  every?: InputMaybe<SettingFilter>;
  none?: InputMaybe<SettingFilter>;
  some?: InputMaybe<SettingFilter>;
};

export type SortOrder = 'ASC' | 'DESC';

export type StartDateFilter = {
  AND?: InputMaybe<Array<StartDateFilter>>;
  OR?: InputMaybe<Array<StartDateFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  date?: InputMaybe<DatePredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  hiringStartDates?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  note?: InputMaybe<NoteFilter>;
  status?: InputMaybe<StringPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type StartDateGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  date?: InputMaybe<Array<GroupByField>>;
  hiringStartDates?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  note?: InputMaybe<NoteGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type StartDateRelationFilter = {
  every?: InputMaybe<StartDateFilter>;
  none?: InputMaybe<StartDateFilter>;
  some?: InputMaybe<StartDateFilter>;
};

export type StartDateSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  date?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  hiringStartDates?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  note?: InputMaybe<NoteSort>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StatusUpdatedData = {
  __typename?: 'StatusUpdatedData';
  id: Scalars['ID']['output'];
  parsedData: Maybe<Scalars['JSON']['output']>;
  status: UpdatedImportStatus;
};

export type StringPadFunctionArguments = {
  len: Scalars['Int']['input'];
  str: Scalars['String']['input'];
};

export type StringPredicate = {
  contains?: InputMaybe<Scalars['String']['input']>;
  ends_with?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  not_contains?: InputMaybe<Scalars['String']['input']>;
  not_ends_with?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  not_starts_with?: InputMaybe<Scalars['String']['input']>;
  starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type StringPredicateHaving = {
  AND?: InputMaybe<Array<StringPredicateHaving>>;
  OR?: InputMaybe<Array<StringPredicateHaving>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  ends_with?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is_empty?: InputMaybe<Scalars['Boolean']['input']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']['input']>;
  not_contains?: InputMaybe<Scalars['String']['input']>;
  not_ends_with?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  not_starts_with?: InputMaybe<Scalars['String']['input']>;
  starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type StringTrimMode = 'BOTH' | 'LEADING' | 'TRAILING';

export type Submission = {
  __typename?: 'Submission';
  _description: Maybe<Scalars['String']['output']>;
  candidate: Maybe<Candidate>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  hiringProcess: Maybe<HiringProcess>;
  id: Maybe<Scalars['ID']['output']>;
  metrics: Maybe<MetricListResponse>;
  position: Maybe<Position>;
  profile: Maybe<Profile>;
  rates: Maybe<RateListResponse>;
  reports: Maybe<MatchReportListResponse>;
  revisions: Maybe<RevisionListResponse>;
  score: Maybe<Scalars['Float']['output']>;
  statuses: Maybe<SubmissionStatusListResponse>;
  submissionDate: Maybe<Scalars['DateTime']['output']>;
  submissionRoles: Maybe<SubmissionRoleListResponse>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type SubmissionratesArgs = {
  filter?: InputMaybe<RateFilter>;
};

export type SubmissionreportsArgs = {
  sort?: InputMaybe<Array<MatchReportSort>>;
};

export type SubmissionstatusesArgs = {
  sort?: InputMaybe<Array<SubmissionStatusSort>>;
};

export type SubmissionFilter = {
  AND?: InputMaybe<Array<SubmissionFilter>>;
  OR?: InputMaybe<Array<SubmissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  availability?: InputMaybe<AvailabilityFilter>;
  candidate?: InputMaybe<CandidateFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  documents?: InputMaybe<AttachmentRelationFilter>;
  hiringProcess?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  metrics?: InputMaybe<MetricRelationFilter>;
  notes?: InputMaybe<NoteRelationFilter>;
  position?: InputMaybe<PositionFilter>;
  profile?: InputMaybe<ProfileFilter>;
  rates?: InputMaybe<RateRelationFilter>;
  reports?: InputMaybe<MatchReportRelationFilter>;
  revisions?: InputMaybe<RevisionRelationFilter>;
  score?: InputMaybe<FloatPredicate>;
  statuses?: InputMaybe<SubmissionStatusRelationFilter>;
  submissionDate?: InputMaybe<DateTimePredicate>;
  submissionRoles?: InputMaybe<SubmissionRoleRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SubmissionGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: SubmissionGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SubmissionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  availability?: InputMaybe<AvailabilityGroupByQuery>;
  candidate?: InputMaybe<CandidateGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  documents?: InputMaybe<AttachmentGroupByQuery>;
  hiringProcess?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  metrics?: InputMaybe<MetricGroupByQuery>;
  notes?: InputMaybe<NoteGroupByQuery>;
  position?: InputMaybe<PositionGroupByQuery>;
  profile?: InputMaybe<ProfileGroupByQuery>;
  rates?: InputMaybe<RateGroupByQuery>;
  reports?: InputMaybe<MatchReportGroupByQuery>;
  revisions?: InputMaybe<RevisionGroupByQuery>;
  score?: InputMaybe<Array<GroupByField>>;
  statuses?: InputMaybe<SubmissionStatusGroupByQuery>;
  submissionDate?: InputMaybe<Array<GroupByField>>;
  submissionRoles?: InputMaybe<SubmissionRoleGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SubmissionListResponse = {
  __typename?: 'SubmissionListResponse';
  count: Scalars['Int']['output'];
  items: Array<Submission>;
};

export type SubmissionOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'score_ASC'
  | 'score_DESC'
  | 'submissionDate_ASC'
  | 'submissionDate_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type SubmissionRelationFilter = {
  every?: InputMaybe<SubmissionFilter>;
  none?: InputMaybe<SubmissionFilter>;
  some?: InputMaybe<SubmissionFilter>;
};

export type SubmissionRole = {
  __typename?: 'SubmissionRole';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  role: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<AlakaUser>;
};

export type SubmissionRoleFilter = {
  AND?: InputMaybe<Array<SubmissionRoleFilter>>;
  OR?: InputMaybe<Array<SubmissionRoleFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  proposal?: InputMaybe<ProposalFilter>;
  role?: InputMaybe<StringPredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<AlakaUserFilter>;
};

export type SubmissionRoleGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  role?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<AlakaUserGroupByQuery>;
};

export type SubmissionRoleListResponse = {
  __typename?: 'SubmissionRoleListResponse';
  count: Scalars['Int']['output'];
  items: Array<SubmissionRole>;
};

export type SubmissionRoleRelationFilter = {
  every?: InputMaybe<SubmissionRoleFilter>;
  none?: InputMaybe<SubmissionRoleFilter>;
  some?: InputMaybe<SubmissionRoleFilter>;
};

export type SubmissionSort = {
  availability?: InputMaybe<AvailabilitySort>;
  candidate?: InputMaybe<CandidateSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  hiringProcess?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  profile?: InputMaybe<ProfileSort>;
  score?: InputMaybe<SortOrder>;
  submissionDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubmissionStatus = {
  __typename?: 'SubmissionStatus';
  _description: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  end: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  note: Maybe<Note>;
  start: Maybe<Scalars['DateTime']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type SubmissionStatusFilter = {
  AND?: InputMaybe<Array<SubmissionStatusFilter>>;
  OR?: InputMaybe<Array<SubmissionStatusFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  end?: InputMaybe<DateTimePredicate>;
  hiringProcess?: InputMaybe<HiringProcessFilter>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  note?: InputMaybe<NoteFilter>;
  proposal?: InputMaybe<ProposalFilter>;
  start?: InputMaybe<DateTimePredicate>;
  submission?: InputMaybe<SubmissionFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SubmissionStatusFilterValue =
  | 'CONFIRMED_START_DATE'
  | 'DELIVERY'
  | 'NEW'
  | 'NOT_SELECTED_BY_CLIENT'
  | 'NOT_SUBMITTED_TO_CLIENT'
  | 'OFFER'
  | 'ONBOARDING'
  | 'ONBOARDING_CANCELLED'
  | 'ONBOARDING_COMPLETED'
  | 'PENDING_FORMS'
  | 'PENDING_PO'
  | 'PENDING_START_DATE'
  | 'SUBMITTED_TO_CLIENT'
  | 'WITHDRAWN';

export type SubmissionStatusGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: SubmissionStatusGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SubmissionStatusGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  end?: InputMaybe<Array<GroupByField>>;
  hiringProcess?: InputMaybe<HiringProcessGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  note?: InputMaybe<NoteGroupByQuery>;
  proposal?: InputMaybe<ProposalGroupByQuery>;
  start?: InputMaybe<Array<GroupByField>>;
  submission?: InputMaybe<SubmissionGroupByQuery>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SubmissionStatusListResponse = {
  __typename?: 'SubmissionStatusListResponse';
  count: Scalars['Int']['output'];
  items: Array<SubmissionStatus>;
};

export type SubmissionStatusOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'end_ASC'
  | 'end_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'start_ASC'
  | 'start_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type SubmissionStatusRelationFilter = {
  every?: InputMaybe<SubmissionStatusFilter>;
  none?: InputMaybe<SubmissionStatusFilter>;
  some?: InputMaybe<SubmissionStatusFilter>;
};

export type SubmissionStatusSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  hiringProcess?: InputMaybe<HiringProcessSort>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  note?: InputMaybe<NoteSort>;
  proposal?: InputMaybe<ProposalSort>;
  start?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SubmissionSort>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newRecord: ImportRecord;
  statusUpdated: StatusUpdatedData;
};

export type SubstringFunctionArguments = {
  len?: InputMaybe<Scalars['Int']['input']>;
  pos: Scalars['Int']['input'];
};

export type Supplier = {
  __typename?: 'Supplier';
  _description: Maybe<Scalars['String']['output']>;
  category: Maybe<Scalars['String']['output']>;
  company: Maybe<Organization>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  includeCSAFee: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type SupplierFilter = {
  AND?: InputMaybe<Array<SupplierFilter>>;
  OR?: InputMaybe<Array<SupplierFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  awardedPositions?: InputMaybe<PositionRelationFilter>;
  candidates?: InputMaybe<CandidateRelationFilter>;
  category?: InputMaybe<StringPredicate>;
  company?: InputMaybe<OrganizationFilter>;
  contractors?: InputMaybe<ContractorRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  hasBeenPartOfAudience?: InputMaybe<PositionRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  includeCSAFee?: InputMaybe<BoolPredicate>;
  matchReport?: InputMaybe<MatchReportRelationFilter>;
  pendingReports?: InputMaybe<PendingReportRelationFilter>;
  positionBids?: InputMaybe<PositionRelationFilter>;
  projects?: InputMaybe<ProjectRelationFilter>;
  proposals?: InputMaybe<ProposalRelationFilter>;
  publishedPositions?: InputMaybe<PositionRelationFilter>;
  report?: InputMaybe<ReportRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<AlakaUserRelationFilter>;
};

export type SupplierGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  awardedPositions?: InputMaybe<PositionGroupByQuery>;
  candidates?: InputMaybe<CandidateGroupByQuery>;
  category?: InputMaybe<Array<GroupByField>>;
  company?: InputMaybe<OrganizationGroupByQuery>;
  contractors?: InputMaybe<ContractorGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  hasBeenPartOfAudience?: InputMaybe<PositionGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  includeCSAFee?: InputMaybe<Array<GroupByField>>;
  matchReport?: InputMaybe<MatchReportGroupByQuery>;
  pendingReports?: InputMaybe<PendingReportGroupByQuery>;
  positionBids?: InputMaybe<PositionGroupByQuery>;
  projects?: InputMaybe<ProjectGroupByQuery>;
  proposals?: InputMaybe<ProposalGroupByQuery>;
  publishedPositions?: InputMaybe<PositionGroupByQuery>;
  report?: InputMaybe<ReportGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  users?: InputMaybe<AlakaUserGroupByQuery>;
};

export type SupplierListResponse = {
  __typename?: 'SupplierListResponse';
  count: Scalars['Int']['output'];
  items: Array<Supplier>;
};

export type SupplierRelationFilter = {
  every?: InputMaybe<SupplierFilter>;
  none?: InputMaybe<SupplierFilter>;
  some?: InputMaybe<SupplierFilter>;
};

export type SupplierSort = {
  category?: InputMaybe<SortOrder>;
  company?: InputMaybe<OrganizationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  includeCSAFee?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TeamInvitationFilter = {
  AND?: InputMaybe<Array<TeamInvitationFilter>>;
  OR?: InputMaybe<Array<TeamInvitationFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  accepted?: InputMaybe<BoolPredicate>;
  acceptedOn?: InputMaybe<DateTimePredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  invitedBy?: InputMaybe<TeamMemberFilter>;
  invitee?: InputMaybe<TeamMemberFilter>;
  lastName?: InputMaybe<StringPredicate>;
  resentOn?: InputMaybe<DateTimePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  uuid?: InputMaybe<StringPredicate>;
};

export type TeamInvitationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  accepted?: InputMaybe<Array<GroupByField>>;
  acceptedOn?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  email?: InputMaybe<Array<GroupByField>>;
  firstName?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  invitedBy?: InputMaybe<TeamMemberGroupByQuery>;
  invitee?: InputMaybe<TeamMemberGroupByQuery>;
  lastName?: InputMaybe<Array<GroupByField>>;
  resentOn?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  uuid?: InputMaybe<Array<GroupByField>>;
};

export type TeamInvitationRelationFilter = {
  every?: InputMaybe<TeamInvitationFilter>;
  none?: InputMaybe<TeamInvitationFilter>;
  some?: InputMaybe<TeamInvitationFilter>;
};

export type TeamMemberFilter = {
  AND?: InputMaybe<Array<TeamMemberFilter>>;
  OR?: InputMaybe<Array<TeamMemberFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<FileFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  isOwner?: InputMaybe<BoolPredicate>;
  receivedTeamInvitations?: InputMaybe<TeamInvitationRelationFilter>;
  roles?: InputMaybe<RoleRelationFilter>;
  sentTeamInvitations?: InputMaybe<TeamInvitationRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type TeamMemberGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  avatar?: InputMaybe<FileGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isOwner?: InputMaybe<Array<GroupByField>>;
  receivedTeamInvitations?: InputMaybe<TeamInvitationGroupByQuery>;
  roles?: InputMaybe<RoleGroupByQuery>;
  sentTeamInvitations?: InputMaybe<TeamInvitationGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type TeamMemberRelationFilter = {
  every?: InputMaybe<TeamMemberFilter>;
  none?: InputMaybe<TeamMemberFilter>;
  some?: InputMaybe<TeamMemberFilter>;
};

export type Template = {
  __typename?: 'Template';
  _description: Maybe<Scalars['String']['output']>;
  boxId: Maybe<Scalars['String']['output']>;
  category: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  group: Maybe<TemplateGroupListResponse>;
  id: Maybe<Scalars['ID']['output']>;
  order: Maybe<Scalars['Int']['output']>;
  required: Maybe<Scalars['Boolean']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type TemplategroupArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TemplateGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<TemplateGroupGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TemplateGroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TemplateGroupSort>>;
};

export type TemplateFilter = {
  AND?: InputMaybe<Array<TemplateFilter>>;
  OR?: InputMaybe<Array<TemplateFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  boxId?: InputMaybe<StringPredicate>;
  category?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  group?: InputMaybe<TemplateGroupRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  order?: InputMaybe<IntPredicate>;
  required?: InputMaybe<BoolPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type TemplateGroup = {
  __typename?: 'TemplateGroup';
  _description: Maybe<Scalars['String']['output']>;
  client: Maybe<ClientListResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  project: Maybe<Project>;
  templates: Maybe<TemplateListResponse>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateGroupclientArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClientFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<ClientGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClientOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClientSort>>;
};

export type TemplateGrouptemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<TemplateGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TemplateOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TemplateSort>>;
};

export type TemplateGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: TemplateGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type TemplateGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  boxId?: InputMaybe<Array<GroupByField>>;
  category?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  group?: InputMaybe<TemplateGroupGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  order?: InputMaybe<Array<GroupByField>>;
  required?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type TemplateGroupFilter = {
  AND?: InputMaybe<Array<TemplateGroupFilter>>;
  OR?: InputMaybe<Array<TemplateGroupFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<ClientRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IDPredicate>;
  project?: InputMaybe<ProjectFilter>;
  templates?: InputMaybe<TemplateRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type TemplateGroupGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: TemplateGroupGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type TemplateGroupGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  client?: InputMaybe<ClientGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  project?: InputMaybe<ProjectGroupByQuery>;
  templates?: InputMaybe<TemplateGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type TemplateGroupListResponse = {
  __typename?: 'TemplateGroupListResponse';
  count: Scalars['Int']['output'];
  items: Array<TemplateGroup>;
};

export type TemplateGroupOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type TemplateGroupRelationFilter = {
  every?: InputMaybe<TemplateGroupFilter>;
  none?: InputMaybe<TemplateGroupFilter>;
  some?: InputMaybe<TemplateGroupFilter>;
};

export type TemplateGroupSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TemplateListResponse = {
  __typename?: 'TemplateListResponse';
  count: Scalars['Int']['output'];
  items: Array<Template>;
};

export type TemplateOrderBy =
  | 'boxId_ASC'
  | 'boxId_DESC'
  | 'category_ASC'
  | 'category_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'order_ASC'
  | 'order_DESC'
  | 'required_ASC'
  | 'required_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type TemplateRelationFilter = {
  every?: InputMaybe<TemplateFilter>;
  none?: InputMaybe<TemplateFilter>;
  some?: InputMaybe<TemplateFilter>;
};

export type TemplateSort = {
  boxId?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  required?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TravelRequirementFilter = {
  AND?: InputMaybe<Array<TravelRequirementFilter>>;
  OR?: InputMaybe<Array<TravelRequirementFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  option?: InputMaybe<StringPredicate>;
  org?: InputMaybe<OrganizationFilter>;
  percentage?: InputMaybe<IntPredicate>;
  positions?: InputMaybe<PositionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type TravelRequirementGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  option?: InputMaybe<Array<GroupByField>>;
  org?: InputMaybe<OrganizationGroupByQuery>;
  percentage?: InputMaybe<Array<GroupByField>>;
  positions?: InputMaybe<PositionGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type TravelRequirementRelationFilter = {
  every?: InputMaybe<TravelRequirementFilter>;
  none?: InputMaybe<TravelRequirementFilter>;
  some?: InputMaybe<TravelRequirementFilter>;
};

export type TravelRequirementSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  option?: InputMaybe<SortOrder>;
  org?: InputMaybe<OrganizationSort>;
  percentage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TrimFunctionArguments = {
  mode?: InputMaybe<StringTrimMode>;
  str: Scalars['String']['input'];
};

export type UpdatedImportStatus = 'FAILED' | 'PARSED';

export type User = {
  __typename?: 'User';
  _description: Maybe<Scalars['String']['output']>;
  alakaUser: Maybe<AlakaUser>;
  birthday: Maybe<Scalars['String']['output']>;
  cellPhone: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  is8base: Maybe<Scalars['Boolean']['output']>;
  language: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  origin: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  workPhone: Maybe<Scalars['String']['output']>;
  workPhoneExt: Maybe<Scalars['String']['output']>;
};

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilter>>;
  OR?: InputMaybe<Array<UserFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  alakaUser?: InputMaybe<AlakaUserFilter>;
  avatar?: InputMaybe<FileFilter>;
  birthday?: InputMaybe<StringPredicate>;
  cellPhone?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  gender?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  is8base?: InputMaybe<BoolPredicate>;
  is_self?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<StringPredicate>;
  lastName?: InputMaybe<StringPredicate>;
  not_self?: InputMaybe<Scalars['Boolean']['input']>;
  origin?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<RoleRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  workPhone?: InputMaybe<StringPredicate>;
  workPhoneExt?: InputMaybe<StringPredicate>;
};

export type UserGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  alakaUser?: InputMaybe<AlakaUserGroupByQuery>;
  avatar?: InputMaybe<FileGroupByQuery>;
  birthday?: InputMaybe<Array<GroupByField>>;
  cellPhone?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  email?: InputMaybe<Array<GroupByField>>;
  firstName?: InputMaybe<Array<GroupByField>>;
  gender?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  is8base?: InputMaybe<Array<GroupByField>>;
  language?: InputMaybe<Array<GroupByField>>;
  lastName?: InputMaybe<Array<GroupByField>>;
  origin?: InputMaybe<Array<GroupByField>>;
  roles?: InputMaybe<RoleGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  timezone?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workPhone?: InputMaybe<Array<GroupByField>>;
  workPhoneExt?: InputMaybe<Array<GroupByField>>;
};

export type UserGroupFilter = {
  AND?: InputMaybe<Array<UserGroupFilter>>;
  OR?: InputMaybe<Array<UserGroupFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  emailTemplate?: InputMaybe<EmailTemplateRelationFilter>;
  id?: InputMaybe<IDPredicate>;
  name?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<AlakaUserRelationFilter>;
};

export type UserGroupGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  emailTemplate?: InputMaybe<EmailTemplateGroupByQuery>;
  emails?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  users?: InputMaybe<AlakaUserGroupByQuery>;
};

export type UserGroupRelationFilter = {
  every?: InputMaybe<UserGroupFilter>;
  none?: InputMaybe<UserGroupFilter>;
  some?: InputMaybe<UserGroupFilter>;
};

export type UserRelationFilter = {
  every?: InputMaybe<UserFilter>;
  none?: InputMaybe<UserFilter>;
  some?: InputMaybe<UserFilter>;
};

export type UserSort = {
  alakaUser?: InputMaybe<AlakaUserSort>;
  avatar?: InputMaybe<FileSort>;
  birthday?: InputMaybe<SortOrder>;
  cellPhone?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is8base?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  origin?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workPhone?: InputMaybe<SortOrder>;
  workPhoneExt?: InputMaybe<SortOrder>;
};

export type WorkArrangementFilter = {
  AND?: InputMaybe<Array<WorkArrangementFilter>>;
  OR?: InputMaybe<Array<WorkArrangementFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  arrangement?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  org?: InputMaybe<OrganizationFilter>;
  positions?: InputMaybe<PositionRelationFilter>;
  shWorkArrangement?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type WorkArrangementGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  arrangement?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  org?: InputMaybe<OrganizationGroupByQuery>;
  positions?: InputMaybe<PositionGroupByQuery>;
  shWorkArrangement?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type WorkArrangementRelationFilter = {
  every?: InputMaybe<WorkArrangementFilter>;
  none?: InputMaybe<WorkArrangementFilter>;
  some?: InputMaybe<WorkArrangementFilter>;
};

export type WorkArrangementSort = {
  arrangement?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  org?: InputMaybe<OrganizationSort>;
  shWorkArrangement?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type WorkingHour = {
  __typename?: 'WorkingHour';
  _description: Maybe<Scalars['String']['output']>;
  client: Maybe<Client>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<User>;
  deletedAt: Maybe<Scalars['Int']['output']>;
  holidaySchedule: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  normalEnd: Maybe<Scalars['DateTime']['output']>;
  normalStart: Maybe<Scalars['DateTime']['output']>;
  otherRules: Maybe<Scalars['JSON']['output']>;
  position: Maybe<PositionListResponse>;
  project: Maybe<Project>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  workingDayDuration: Maybe<Scalars['Int']['output']>;
};

export type WorkingHourpositionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy?: InputMaybe<PositionGroupBy>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PositionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PositionSort>>;
};

export type WorkingHourFilter = {
  AND?: InputMaybe<Array<WorkingHourFilter>>;
  OR?: InputMaybe<Array<WorkingHourFilter>>;
  _fullText?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<ClientFilter>;
  country?: InputMaybe<CountryFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  holidaySchedule?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IDPredicate>;
  normalEnd?: InputMaybe<DateTimePredicate>;
  normalStart?: InputMaybe<DateTimePredicate>;
  position?: InputMaybe<PositionRelationFilter>;
  project?: InputMaybe<ProjectFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  workingDayDuration?: InputMaybe<IntPredicate>;
};

export type WorkingHourGroupBy = {
  first?: InputMaybe<Scalars['Int']['input']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: WorkingHourGroupByQuery;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type WorkingHourGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  client?: InputMaybe<ClientGroupByQuery>;
  country?: InputMaybe<CountryGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  holidaySchedule?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  normalEnd?: InputMaybe<Array<GroupByField>>;
  normalStart?: InputMaybe<Array<GroupByField>>;
  otherRules?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<PositionGroupByQuery>;
  project?: InputMaybe<ProjectGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  workingDayDuration?: InputMaybe<Array<GroupByField>>;
};

export type WorkingHourListResponse = {
  __typename?: 'WorkingHourListResponse';
  count: Scalars['Int']['output'];
  items: Array<WorkingHour>;
};

export type WorkingHourOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'holidaySchedule_ASC'
  | 'holidaySchedule_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'normalEnd_ASC'
  | 'normalEnd_DESC'
  | 'normalStart_ASC'
  | 'normalStart_DESC'
  | 'otherRules_ASC'
  | 'otherRules_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'workingDayDuration_ASC'
  | 'workingDayDuration_DESC';

export type WorkingHourRelationFilter = {
  every?: InputMaybe<WorkingHourFilter>;
  none?: InputMaybe<WorkingHourFilter>;
  some?: InputMaybe<WorkingHourFilter>;
};

export type WorkingHourSort = {
  client?: InputMaybe<ClientSort>;
  country?: InputMaybe<CountrySort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  holidaySchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  normalEnd?: InputMaybe<SortOrder>;
  normalStart?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  updatedAt?: InputMaybe<SortOrder>;
  workingDayDuration?: InputMaybe<SortOrder>;
};

export type fetchRecordQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type fetchRecordQuery = {
  __typename?: 'Query';
  record: {
    __typename?: 'ImportRecord';
    id: string;
    createdAt: string;
    filename: string;
    downloadLink: string;
    status: ImportStatus;
    parsedData: Record<string, any> | null;
  } | null;
};

export type fetchRecordsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  fullText?: InputMaybe<Scalars['String']['input']>;
}>;

export type fetchRecordsQuery = {
  __typename?: 'Query';
  recordsList: {
    __typename?: 'ImportRecordsResponse';
    count: number;
    items: Array<{
      __typename?: 'ImportRecord';
      id: string;
      createdAt: string;
      filename: string;
      downloadLink: string;
      status: ImportStatus;
      parsedData: Record<string, any> | null;
    }>;
    pageInfo: { __typename?: 'PageInfo'; endCursor: string | null };
  };
};

export type onStatusUpdateSubscriptionVariables = Exact<{ [key: string]: never }>;

export type onStatusUpdateSubscription = {
  __typename?: 'Subscription';
  statusUpdated: {
    __typename?: 'StatusUpdatedData';
    id: string;
    status: UpdatedImportStatus;
    parsedData: Record<string, any> | null;
  };
};

export type onNewRecordSubscriptionVariables = Exact<{ [key: string]: never }>;

export type onNewRecordSubscription = {
  __typename?: 'Subscription';
  newRecord: {
    __typename?: 'ImportRecord';
    id: string;
    createdAt: string;
    filename: string;
    downloadLink: string;
    status: ImportStatus;
    parsedData: Record<string, any> | null;
  };
};

export const fetchRecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchRecord' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'record' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parsedData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchRecordQuery, fetchRecordQueryVariables>;
export const fetchRecordsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchRecords' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fullText' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recordsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fullText' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fullText' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parsedData' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'endCursor' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchRecordsQuery, fetchRecordsQueryVariables>;
export const onStatusUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'onStatusUpdate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statusUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parsedData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<onStatusUpdateSubscription, onStatusUpdateSubscriptionVariables>;
export const onNewRecordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'onNewRecord' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newRecord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parsedData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<onNewRecordSubscription, onNewRecordSubscriptionVariables>;
