import i18n from 'translations/i18n';
import getProperty from 'lodash/get';
import orderBy from 'lodash/orderBy';
import meanBy from 'lodash/meanBy';
// services
import client from 'services/Client';
// queries
import { SAVE_SUBMIT_SUBMISSIONS_PROGRESS } from 'queries/PositionSettingQueries';
// services
import { upload } from 'services/Box';
import logger from 'services/Logger';
// helpers
import { boxFileDir, boxFileUrl } from 'helpers/Box';
import { getRateCardLink } from 'helpers/Common';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { ceilNumber } from 'helpers/Number';
import { buildEmailTemplate, hydrateTemplate } from 'helpers/Template';
import { openLink } from 'helpers/Link';
import {
  calculateCandidateGPFromSellPrice,
  calculateCandidateSellPriceFromGP,
} from 'helpers/RatesCalculation';
// constants
import { SUBMIT_CANDIDATES_TO_CLIENT } from 'constants/templates';
import { MIN_BUSINESS_GP_REQUIREMENT, DEFAULT_DGP, DEFAULT_DGP_FP } from 'constants/rates';
import {
  RATES_SUPPLIER_RATE,
  FILE_RELEASABLE_PROPOSAL,
  FILE_RELEASABLE_CV,
  FILE_RELEASABLE_ATTESTATION,
} from 'constants';
import { DOCUMENT_TYPE, SORT_BY } from '../constants';

/**
 * Validate and returns error message for sell rate
 * @param {object} params
 * @param {number} params.partnerRate
 * @param {number} params.sellRate
 * @param {number} params.nteRate
 * @param {number} params.manYearDiscount
 * @param {boolean} params.isDirectSupplier
 * @param {boolean} params.includeCSAFee
 * @returns {string} Error message
 */
export const validateSellRate = ({
  partnerRate,
  sellRate,
  nteRate,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee,
}) => {
  if (!sellRate) {
    return '';
  }
  if (sellRate > nteRate) {
    return i18n.t('errorSellRatesHigherThanNteRate');
  }

  const dGP = calculateCandidateGPFromSellPrice(
    partnerRate,
    sellRate,
    manYearDiscount,
    isDirectSupplier,
    includeCSAFee
  );

  if (dGP < MIN_BUSINESS_GP_REQUIREMENT) {
    return '';
    // validating min dGP is disabled temporarily https://github.ibm.com/defense-intelligence-belgium/alaka-v2.0/issues/798
    // return i18n.t('errorSellRatesBelowMinimalDGP', { minDGP: MIN_BUSINESS_GP_REQUIREMENT });
  }

  return '';
};

/**
 * Validate rates
 * @param {object} params
 * @returns {object[]}
 */
const validateSellRates = ({
  sellRates,
  defaultSellRates,
  partnerRates,
  nteRates,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee,
}) => {
  return nteRates.map(nteRate => {
    const sellRate = sellRates.find(({ year }) => year === nteRate.year);
    const defaultSellRate = defaultSellRates.find(({ year }) => year === nteRate.year);
    const partnerRate = partnerRates.find(({ year }) => year === nteRate.year);
    const errorMessage = validateSellRate({
      sellRate: sellRate.value,
      partnerRate: partnerRate.value,
      nteRate: nteRate.value,
      manYearDiscount,
      isDirectSupplier,
      includeCSAFee,
    });

    return {
      ...sellRate,
      highlighted: sellRate.value !== defaultSellRate.value,
      error: !!errorMessage || !sellRate.value,
      errorMessage,
    };
  });
};

/**
 * @param {object} submission
 * @param {number} manYearDiscount
 * @returns {object[]}
 */
export const getDefaultSellRates = (submission, manYearDiscount) => {
  const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);

  return partnerRates.map(partnerRate => ({
    year: partnerRate.year,
    value: ceilNumber(
      calculateCandidateSellPriceFromGP(
        partnerRate.value,
        submission.isProposal() ? DEFAULT_DGP_FP : DEFAULT_DGP,
        manYearDiscount,
        submission.isDirectPartner(),
        submission.partner.includeCSAFee
      )
    ),
  }));
};

/**
 * Use provided simulated rates or calculate default sell rates (based on default dGP) + validate rates
 * @param {object} submission
 * @param {?object[]} [sellRates]
 * @param {object[]} nteRates
 * @param {number} manYearDiscount
 * @returns {object[]}
 */
export const initSellRates = (submission, sellRates, nteRates, manYearDiscount) => {
  const defaultSellRates = getDefaultSellRates(submission, manYearDiscount);

  return validateSellRates({
    sellRates: sellRates || defaultSellRates,
    defaultSellRates,
    partnerRates: submission.getRates(RATES_SUPPLIER_RATE),
    nteRates,
    manYearDiscount,
    isDirectSupplier: submission.isDirectPartner(),
    includeCSAFee: submission.partner.includeCSAFee,
  });
};

/**
 * @param {string} filename
 * @param {string} [candidate] Candidate name
 * @param {string} rfq
 * @param {string} type Document type
 * @returns {string}
 */
export const formatDocumentName = (filename, candidate, rfq, type) => {
  const fileExtension = filename.split('.').pop();

  if (!candidate) {
    return `${rfq} ${type}.${fileExtension}`;
  }

  return `${rfq} ${candidate} ${type}.${fileExtension}`;
};

/**
 * @param {object} file
 * @param {string} candidate Candidate name
 * @param {string} rfq
 * @param {string} type Document type
 * @returns {object}
 */
const formatFile = (file, candidate, rfq, type) => ({
  boxId: file.boxId,
  filename: formatDocumentName(file.filename, candidate, rfq, type),
});

/**
 * Get releasable submission File and Attestation if is there any
 * @param {object} candidate
 * @param {string} rfq RFQ label
 * @param {boolean} isProposal
 * @returns {object}
 */
export const initDocuments = (submission, rfq, isProposal) => {
  const name = submission.getName();
  const releasableSubmissionFile = isProposal
    ? submission.getDocument(FILE_RELEASABLE_PROPOSAL)
    : submission.getDocument(FILE_RELEASABLE_CV);
  const releasableAttestation = submission.getDocument(FILE_RELEASABLE_ATTESTATION);

  return {
    submissionFile: releasableSubmissionFile
      ? formatFile(releasableSubmissionFile, name, rfq, DOCUMENT_TYPE.CV)
      : null,
    attestation: releasableAttestation
      ? formatFile(releasableAttestation, name, rfq, DOCUMENT_TYPE.ATTESTATION)
      : null,
  };
};

/**
 * Get box folder for position where documents will be uploaded
 * @param {object} position
 * @returns {string}
 */
export const getBoxFolder = position => {
  return boxFileDir({
    client: position.client.name,
    project: position.project.name,
    rfq: position.rfq,
  });
};

/**
 * Upload document
 * @param {object} params
 * @param {File} params.file
 * @param {string} params.folder
 * @param {string} params.rfq
 * @param {string} [params.submission] Submission Name
 * @param {string} params.type Document type
 */
export const uploadDocument = async ({ file, folder, rfq, submission, type }) => {
  const filename = formatDocumentName(file.name, submission, rfq, type);

  try {
    return {
      id: await upload(file, filename, folder),
      filename,
    };
  } catch (error) {
    logger.exception(error, { folder, rfq, submission, type });
    throw error;
  }
};

/**
 * @param {object[]} selectedCandidates
 * @returns {boolean}
 */
export const areSellRatesValid = selectedCandidates => {
  return selectedCandidates.every(candidate => {
    if (!candidate.sellRates) {
      return false;
    }

    return candidate.sellRates.every(sellRate => !sellRate.error);
  });
};

/**
 * @param {number} numberOfSelectedSubmissions
 * @param {number} maximumAllowedSubmissions
 * @param {number} numberOfSubmissionsMade
 * @returns {boolean}
 */
export const isNumberOfSelectedSubmissionsValid = (
  numberOfSelectedSubmissions,
  maximumAllowedSubmission,
  numberOfSubmissionsMade = 0
) => {
  return numberOfSelectedSubmissions + numberOfSubmissionsMade <= maximumAllowedSubmission;
};

/**
 * @param {object[]} selectedCandidates
 * @returns {boolean}
 */
export const areAllReleasableDocumentsFilled = selectedCandidates => {
  return selectedCandidates.every(candidate => {
    const { releasableSubmissionFile, releasableAttestation } = candidate;

    return releasableSubmissionFile && releasableAttestation;
  });
};

/**
 * @param {object} params
 * @param {boolean} params.isProposal
 * @param {object[]} params.selectedSubmissions
 * @param {number} params.maximumAllowedSubmission
 * @param {number} [params.numberOfSubmissionsMade=0]
 * @param {object} params.rateCard
 * @returns {string[]}
 */
export const getErrorMessages = ({
  isProposal,
  selectedSubmissions,
  maximumAllowedSubmissions,
  numberOfSubmissionsMade,
  rateCard,
}) => {
  const errors = [];
  const allReleasableDocumentsAreFilled = areAllReleasableDocumentsFilled(selectedSubmissions);
  const allSellRatesAreValid = areSellRatesValid(selectedSubmissions);
  const selectedSubmissionsExceedMaxAllowedSubmissions = !isNumberOfSelectedSubmissionsValid(
    selectedSubmissions.length,
    maximumAllowedSubmissions,
    numberOfSubmissionsMade || 0
  );

  if (!selectedSubmissions.length) {
    errors.push(i18n.t('candidateSubmissionNoCandidatesSelectedError'));
  }

  if (selectedSubmissionsExceedMaxAllowedSubmissions) {
    errors.push(
      i18n.t('selectedCandidatesExceedMaxAllowedCandidatesError', {
        maximumAllowedSubmissions: maximumAllowedSubmissions - numberOfSubmissionsMade,
      })
    );
  }

  if (!allReleasableDocumentsAreFilled || !allSellRatesAreValid) {
    errors.push(i18n.t('someRequiredFieldsNotFilledError'));
  }

  if (isProposal && !rateCard) {
    errors.push(i18n.t('rateCardMissingError'));
  }

  return errors;
};

/**
 * @param {string} rfq
 * @param {string} projectId
 * @param {string} projectManagerEmail
 * @param {object} user Logged in user
 * @param {string} user.email
 * @param {string} user.name
 * @param {string} user.company
 * @returns {Promise<object>}
 */
export const initEmailTemplate = async (rfq, projectId, projectManagerEmail, user) => {
  const emailTemplate = await buildEmailTemplate(SUBMIT_CANDIDATES_TO_CLIENT, projectId, {
    rfq,
    matchManager: user.name,
    company: user.company,
  });

  const from = getProperty(emailTemplate, 'options.from') || user.email;
  const to = getProperty(emailTemplate, 'options.to') || projectManagerEmail;
  const options = {
    ...emailTemplate.options,
    from,
    to,
  };

  return {
    content: emailTemplate.html,
    options,
  };
};

const buildSubmissionTable = ({
  rfq,
  name,
  availability,
  clearance,
  clearanceValidUntil,
  email,
  phone,
}) => {
  return `
  <table>
    <tr>
      <td><strong>Name</strong></td>
      <td><strong>${name}</strong></td>
    </tr>
    <tr>
      <td>RFQ</td>
      <td>${rfq}</td>
    </tr>
    <tr>
      <td>Availability from the point of selection</td>
      <td>${availability}</td>
    </tr>
    <tr>
      <td>Clearance</td>
      <td>${clearance}</td>
    </tr>
    <tr>
      <td>Clearance valid until</td>
      <td>${clearanceValidUntil}</td>
    </tr>
    <tr>
      <td>Contact Info - Phone and email</td>
      <td>
        <a href="mailto:${email}">${email}</a> / ${phone}
      </td>
    </tr>
  </table>`;
};

/**
 * @param {string} template
 * @param {string} rfq
 * @param {object[]} submissions Selected submissions
 * @return {string}
 */
export const buildEmail = (template, rfq, submissions) => {
  const tableHtml = submissions.map(submission => {
    const clearance = submission.getClearance();
    const clearanceValidUntil = clearance.validUntil ? getTimeYear(clearance.validUntil) : '-';
    const email = submission.getEmail() || '';
    const phone = submission.getPhone() || '-';

    return `<p style="margin-bottom:10px;">${buildSubmissionTable({
      rfq,
      name: submission.getName(),
      availability: submission.availability.label,
      clearance: submission.getClearance().type,
      clearanceValidUntil,
      email,
      phone,
    })}</p>`;
  });

  return hydrateTemplate(template, { table: tableHtml.join('') });
};

/**
 * @param {object} params
 * @param {object[]} params.submissions Selected submissions
 * @param {string} params.positionId
 * @param {string} params.rfq
 * @param {number} params.manYearDiscount
 * @param {boolean} params.isProposal
 * @param {object} params.rateCard
 */
export const getEmailAttachments = ({
  submissions,
  positionId,
  rfq,
  manYearDiscount,
  isProposal,
  rateCard,
}) => {
  return submissions.reduce((attachments, submission) => {
    const sellRates = submission.sellRates.map(sellRate => sellRate.value);
    const rateCardLink = getRateCardLink(sellRates, manYearDiscount, positionId, submission.id);
    const attachmentsList = [
      ...attachments,
      submission.releasableSubmissionFile,
      submission.releasableAttestation,
    ];

    if (!isProposal) {
      // add rate card for candidate submissions
      attachmentsList.push({
        filename: `${rfq} ${submission.getName()} Rate card.xlsx`,
        onClick: () => openLink(rateCardLink),
      });
    } else if (isProposal && rateCard) {
      // add rate card once for proposal
      if (!attachmentsList.find(attachment => attachment.filename === rateCard.filename)) {
        attachmentsList.push({
          filename: rateCard.filename,
          onClick: () => openLink(boxFileUrl(rateCard.boxId)),
        });
      }
    }

    return attachmentsList;
  }, []);
};

/**
 * @param {string} elementId
 */
export const scrollToElement = elementId => {
  const element = document.getElementById(elementId);
  element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
};

/**
 * Sort submissions
 * @param {object[]} submissions
 * @param {string} sortBy option from SORT_BY constant
 * @param {string} order option from ORDER constant
 */
export const sortSubmissions = (submissions, sortBy, order) => {
  switch (sortBy) {
    case SORT_BY.SCORE:
      return orderBy(submissions, 'score', order);
    case SORT_BY.NAME:
      return orderBy(submissions, submission => submission.getName(), order);
    case SORT_BY.RATE:
      return orderBy(
        submissions,
        submission => {
          const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);

          return meanBy(partnerRates, 'value');
        },
        order
      );
    default:
      return submissions;
  }
};

/**
 * Save current progress
 * @param {string} positionId
 * @param {object} selectedSubmissions selected submissions data
 * @param {string} currentStep current step Id
 * @param {object} [rateCard]
 * @returns {Promise}
 */
export const saveProgress = async (positionId, selectedSubmissions, currentStep, rateCard) => {
  try {
    await client.mutate({
      mutation: SAVE_SUBMIT_SUBMISSIONS_PROGRESS,
      variables: {
        position: positionId,
        data: {
          submissions: selectedSubmissions,
          lastStep: currentStep,
          rateCard,
        },
      },
    });
  } catch (error) {
    logger.exception(error, { selectedSubmissions, currentStep });
    throw error;
  }
};

export { default as commitSubmissions } from './commitSubmissions';
