/* eslint-disable import/order */
import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import mapObject from 'lodash/map';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
// queries
import { File, MatchReport, Rate, Revision } from 'queries/Fragments';
import { UPDATE_AUTHOR_FOR_DOCUMENT } from 'queries/UserQueries';
// constants
import {
  REVISION_TYPES,
  REVISION_STATUS,
  NOTE_REVISION_TYPE,
  FILE_ATTESTATION,
  FILE_CV,
  RATES_SUPPLIER_RATE,
} from 'constants';
import { ACTIONS } from '../constants';
// helpers
import { getRevisionStatus } from 'helpers/Revision';
import { getAbsoluteUrl } from 'helpers/Link';
import { getPendingReport, deletePendingReport } from 'helpers/Report';
import { updateMetrics } from 'helpers/MatchMetrics';
import { getPartnerRates } from './common';
import uploadFiles from './uploadFiles';

const getAcceptObject = (type, attachments, revision, cvFileId, userId) => {
  const acceptTemplateObject = {
    filter: { id: '' },
  };

  const { revisionTypeId, type: revisionTypeType } = type;
  const additionalNote = getProperty(type, 'acceptNote.value', '');
  const submissionId = getProperty(revision, 'submission.id');

  setProperty(acceptTemplateObject, 'data.status.update.status', REVISION_STATUS.ACCEPTED);
  setProperty(acceptTemplateObject, 'filter.id', revisionTypeId);

  if (REVISION_TYPES.CV === revisionTypeType) {
    setProperty(acceptTemplateObject, 'data.attachment.connect.id', cvFileId);
  }

  if (REVISION_TYPES.ATTESTATION === revisionTypeType) {
    const fileId = getProperty(attachments, [revisionTypeType, 'boxFileId']);
    const fileName = getProperty(attachments, [revisionTypeType, 'fileName']);
    const fileType = revisionTypeType === REVISION_TYPES.CV ? FILE_CV : FILE_ATTESTATION;

    setProperty(acceptTemplateObject, 'data.attachment.create.boxId', fileId);
    setProperty(acceptTemplateObject, 'data.attachment.create.name', fileName);
    setProperty(acceptTemplateObject, 'data.attachment.create.type', fileType);
    setProperty(acceptTemplateObject, 'data.attachment.create.author.connect.id', userId);
    setProperty(acceptTemplateObject, 'data.attachment.create.submission.connect.id', submissionId);
  }

  if (REVISION_TYPES.RATES === revisionTypeType) {
    const rates = getProperty(type, 'value', {});
    const supplierRates = getPartnerRates(revision);
    const currency = getProperty(supplierRates, '0.currency', '');
    const rateCreateObject = Object.values(rates).map(rate => {
      const startDate = moment(getProperty(rate, 'startDate')).format('YYYY-MM-DD');
      return {
        currency,
        rateType: RATES_SUPPLIER_RATE,
        startDate,
        value: rate.value,
        submission: {
          connect: {
            id: submissionId,
          },
        },
      };
    });

    setProperty(acceptTemplateObject, 'data.submittedRates.create.rates.create', rateCreateObject);
  }

  if (additionalNote) {
    setProperty(acceptTemplateObject, 'data.additionalNote.create.note', additionalNote);
    setProperty(acceptTemplateObject, 'data.additionalNote.create.noteType', NOTE_REVISION_TYPE);
    setProperty(acceptTemplateObject, 'data.additionalNote.create.author.connect.id', userId);
  }

  return acceptTemplateObject;
};

const getRejectObject = (revisionType, userId) => {
  const rejectTemplate = {
    filter: { id: '' },
    data: {
      rejectionNote: {
        create: {
          noteType: 'REVISION_NOTE',
          note: '',
        },
      },
    },
  };
  const { revisionTypeId } = revisionType;
  const note = getProperty(revisionType, 'rejectionNote.value', '');

  setProperty(rejectTemplate, 'filter.id', revisionTypeId);
  setProperty(rejectTemplate, 'data.rejectionNote.create.note', note);
  setProperty(rejectTemplate, 'data.rejectionNote.create.noteType', NOTE_REVISION_TYPE);
  setProperty(rejectTemplate, 'data.rejectionNote.create.author.connect.id', userId);
  setProperty(rejectTemplate, 'data.status.update.status', REVISION_STATUS.REJECTED);

  return rejectTemplate;
};

const parseTypes = async (formData, revision, userId, cvFileId) => {
  const types = mapObject(formData, (value, key) => {
    const revisionType = getProperty(revision, 'types.items', []).find(type => type.type === key);
    return {
      ...value,
      type: key,
      revisionTypeId: revisionType.id,
      status: revisionType.status,
    };
  });

  const promises = [uploadFiles(formData)];
  if (cvFileId) {
    promises.push(
      client.mutate({
        mutation: UPDATE_AUTHOR_FOR_DOCUMENT,
        variables: {
          document: cvFileId,
          author: userId,
        },
      })
    );
  }

  const [filesResponse] = await Promise.all(promises);
  const revisionTypesUpdate = {
    update: [],
  };

  types.forEach(type => {
    const updateTypeObject =
      type.action === ACTIONS.ACCEPT
        ? getAcceptObject(type, filesResponse, revision, cvFileId, userId)
        : getRejectObject(type, userId);

    revisionTypesUpdate.update = [...revisionTypesUpdate.update, updateTypeObject];
  });

  return { types: revisionTypesUpdate };
};

const parseSubmission = (formData, revision, report, supplierId) => {
  const updateObject = {};
  const rates = getProperty(formData, [REVISION_TYPES.RATES]);
  const cv = getProperty(formData, [REVISION_TYPES.CV]);

  if (rates && rates.action === ACTIONS.ACCEPT) {
    const supplierRates = getPartnerRates(revision);
    const ratesUpdate = supplierRates.map(({ id }) => {
      return {
        filter: {
          id,
        },
        data: {
          endDate: moment().format('YYYY-MM-DD'),
        },
      };
    });

    setProperty(updateObject, 'update.rates.update', ratesUpdate);
  }
  if (cv && cv.action === ACTIONS.ACCEPT) {
    const score = getProperty(cv, 'revisedFile.value.score');
    const candidateId = getProperty(revision, 'submission.candidate.id');

    setProperty(updateObject, 'update.score', score);
    setProperty(updateObject, 'update.documents.connect.id', report.cv.id);
    setProperty(updateObject, 'update.reports', {
      create: {
        score,
        supplier: { connect: { id: supplierId } },
        positionReport: { connect: { id: report.positionReport.id } },
        candidateReport: {
          create: {
            shCandidateId: report.shCandidateId,
            cv: { connect: { id: report.cv.id } },
            candidate: { connect: { id: candidateId } },
          },
        },
      },
    });
  }

  return { submission: updateObject };
};

const getReportData = reportId => {
  if (!reportId) return null;
  return getPendingReport(reportId);
};

export const SUBMIT_REVISION = gql`
  mutation updateRevision($id: ID!, $data: RevisionUpdateInput!) {
    revisionUpdate(filter: { id: $id }, data: $data) {
      ...Revision
      submission {
        id
        score
        documents(sort: { createdAt: DESC }) {
          items {
            ...File
          }
        }
        reports(sort: { createdAt: DESC }) {
          items {
            ...MatchReport
          }
        }
        rates(filter: { endDate: { is_empty: true } }) {
          items {
            ...Rate
          }
        }
      }
    }
  }
  ${Revision}
  ${Rate}
  ${File}
  ${MatchReport}
`;

const submitRevision = async (formData, revision, userId, supplierId) => {
  try {
    const reportId = getProperty(formData, [REVISION_TYPES.CV, 'revisedFile', 'value', 'reportId']);
    const reportData = await getReportData(reportId);
    const cvFileId = getProperty(reportData, 'cv.id');
    const submissionId = getProperty(revision, 'submission.id', '');
    const revisionId = getProperty(revision, 'id', '');
    const statuses = getProperty(revision, 'statuses.items', []);
    const currentStatus = getRevisionStatus(statuses);
    const mutationData = {
      ...(await parseTypes(formData, revision, userId, cvFileId)),
      ...parseSubmission(formData, revision, reportData, supplierId),
      statuses: {
        update: {
          filter: {
            id: currentStatus.id,
          },
          data: {
            end: moment().toISOString(),
          },
        },
        create: {
          status: REVISION_STATUS.COMPLETED,
          start: moment().toISOString(),
        },
      },
    };

    await client.mutate({
      mutation: SUBMIT_REVISION,
      variables: {
        id: revisionId,
        data: mutationData,
      },
    });

    // CV was updated, update metrics
    if (reportId) {
      updateMetrics(submissionId).catch(error => logger.exception(error));
      deletePendingReport(reportId).catch(error => logger.exception(error));
    }

    notification.submissionRevisionSubmitted({
      revision: revisionId,
      link: getAbsoluteUrl(`/submission/${submissionId}`),
      createdBy: userId,
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default submitRevision;
