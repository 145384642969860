import { gql } from '@apollo/client';
import {
  Rate,
  Note,
  SubmissionStatus,
  PositionStatus,
  PositionRatesSettings,
} from 'queries/Fragments';

export const GET_POSITIONS_TABLE_VIEW = gql`
  query getPositionsForTableView(
    $filter: NewPositionFilter!
    $first: Int!
    $skip: Int!
    $orderBy: [PositionOrderBy]
    $sort: [PositionSort!]
  ) {
    positionsList(first: $first, skip: $skip, filter: $filter, orderBy: $orderBy, sort: $sort) {
      count
      items {
        id
        title
        rfq {
          id
          name
        }
        location {
          items {
            id
            country {
              id
              name
            }
            city
          }
        }
        contractType
        deliveryOption {
          id
          mode
          description
        }
        dueOn
        supplierDueDate
        duration {
          id
          hours
          start
        }
        rates(filter: { endDate: { is_empty: true } }) {
          items {
            ...Rate
          }
        }
        report {
          id
          shPositionId
        }
        notes {
          items {
            ...Note
          }
        }
        submissions {
          count
          items {
            id
            statuses(sort: { createdAt: DESC }) {
              items {
                ...SubmissionStatus
              }
            }
            candidate {
              supplier {
                id
              }
            }
            hiringProcess {
              id
              purchaseOrder {
                id
                effort
                start
              }
            }
          }
        }
        proposals {
          count
          items {
            id
            statuses(sort: { createdAt: DESC }) {
              items {
                ...SubmissionStatus
              }
            }
            supplier {
              id
            }
            hiringProcess {
              id
              purchaseOrder {
                id
                effort
                start
              }
            }
          }
        }
        project {
          id
          name
          shortName
          config
          timezone
          currency
          client {
            id
            name
            config
          }
        }
        maximumCandidatesAllowed
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
        publishedToAll
        hasBeenPublishedTo {
          items {
            id
            company {
              id
              name
            }
          }
        }
        publishedTo {
          items {
            id
            company {
              id
              name
            }
          }
        }
        publishProgress
        ...PositionRatesSettings
      }
    }
  }
  ${Rate}
  ${Note}
  ${SubmissionStatus}
  ${PositionStatus}
  ${PositionRatesSettings}
`;

export const GET_POSITIONS_CARD_VIEW = gql`
  query getPositionsForCardView(
    $filter: NewPositionFilter!
    $first: Int!
    $skip: Int!
    $orderBy: [PositionOrderBy]
    $sort: [PositionSort!]
  ) {
    positionsList(first: $first, skip: $skip, filter: $filter, orderBy: $orderBy, sort: $sort) {
      count
      items {
        id
        title
        rfq {
          id
          name
        }
        dueOn
        contractType
        supplierDueDate
        maximumCandidatesAllowed
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
        report {
          id
          shPositionId
        }
        duration {
          id
          start
          end
          hours
        }
        deliveryOption {
          id
          mode
          description
        }
        location {
          items {
            id
            country {
              id
              name
            }
            city
          }
        }
        rates(filter: { endDate: { is_empty: true } }) {
          items {
            ...Rate
          }
        }
        clearance {
          id
          name
        }
        project {
          id
          name
          shortName
          config
          currency
          timezone
          client {
            id
            name
            config
          }
        }
        publishedToAll
        hasBeenPublishedTo {
          items {
            id
            company {
              id
              name
            }
          }
        }
        publishedTo {
          items {
            id
            company {
              id
              name
            }
          }
        }
        publishProgress
        notes {
          items {
            ...Note
          }
        }
        submissions {
          count
          items {
            id
            statuses(sort: { createdAt: DESC }) {
              items {
                ...SubmissionStatus
              }
            }
            candidate {
              supplier {
                id
              }
            }
            profile {
              id
              fullName
            }
            hiringProcess {
              id
              purchaseOrder {
                id
                effort
                start
              }
            }
          }
        }
        proposals {
          count
          items {
            id
            name
            statuses(sort: { createdAt: DESC }) {
              items {
                ...SubmissionStatus
              }
            }
            supplier {
              id
            }
            hiringProcess {
              id
              purchaseOrder {
                id
                effort
                start
              }
            }
          }
        }
        ...PositionRatesSettings
      }
    }
  }
  ${Rate}
  ${Note}
  ${SubmissionStatus}
  ${PositionStatus}
  ${PositionRatesSettings}
`;
