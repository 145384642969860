/* eslint-disable import/order */
import React, { useState, useContext, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
import useModalProvider from 'hooks/useModalProvider';
import useIsMounted from 'hooks/useIsMounted';
// context
import ToastContext from 'context/ToastContext';
// services
import logger from 'services/Logger';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE, RATES_TARGET_RATE } from 'constants';
import {
  MAIN_ONBOARDING,
  MAIN_RECRUITING,
  MAIN_SELECTION,
  POSITION_STATUS,
} from 'constants/positionStatus';
// helpers
import { getYear } from 'helpers/RelativeTimeFormatter';
import { filterSubmissionsByStatus } from 'helpers/Submission';
import { isCandidateSubmittableForPosition, fetchPositionStatus } from 'helpers/Position';
// components
import IconDropdown from 'components/molecules/IconDropdown';
import PublishPosition from './PublishPosition';
import ReopenPosition from './ReopenPosition';
import SelectWinner, { ReselectWinner } from './SelectWinner';
import CancelPosition from './CancelPosition';
// helpers
import {
  hasSubmissions,
  showSelectWinner,
  unpublish,
  downloadSubmissionsDocuments,
} from './helpers';
// styles
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  position: PropTypes.object.isRequired,
  actionButton: PropTypes.bool,
  onlySecondaryActions: PropTypes.bool,
};

const defaultProps = {
  actionButton: false,
  onlySecondaryActions: false,
};

const MODAL = {
  PUBLISH_STATUS: 'publishStatus',
  PUBLISH_VIEW_SUMMARY: 'publishViewSummary',
  PUBLISH_EDIT: 'publishEdit',
  CANCEL_POSITION: 'cancelPosition',
  SELECT_WINNER: 'selectWinner',
  RE_SELECT_WINNER: 'reSelectWinner',
  RE_OPEN_POSITION: 'reOpenPosition',
};

const ITEM_KEYS = {
  PUBLISH: 'publish',
  SUBMIT: 'submit',
  SELECT_WINNER: 'selectWinner',
  DOWNLOAD_DOCS: 'downloadDocs',
};

const PositionSettings = ({ position, actionButton, onlySecondaryActions }) => {
  const { id: userId, role, supplierId } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { openModal, closeModal, isModalOpen } = useModalProvider();
  const isMounted = useIsMounted();
  const history = useHistory();
  const location = useLocation();
  const menuRef = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingKey, setLoadingKey] = useState('');
  const [hasDueDatePassed, setDueDatePassed] = useState(position.isAfterDueDate());
  const [publishModalConfig, setPublishModalConfig] = useState({
    isOpen: false,
    isViewSummary: false,
    isEdit: false,
  });

  const { id, report } = position;
  const positionStatus = position.getStatus(role, supplierId);
  const { value: mainStatus } = position.getMainStatus(role, supplierId);
  const targetRates = position.getRates(RATES_TARGET_RATE).map(rate => ({
    value: rate.value,
    year: parseInt(getYear(rate.startDate)),
  }));

  const isItemLoading = key => loading && loadingKey === key;

  const isPositionCancellable = [
    POSITION_STATUS.UNPUBLISHED,
    POSITION_STATUS.PUBLISHED,
    POSITION_STATUS.PENDING_SELECTION,
    POSITION_STATUS.OFFER,
    POSITION_STATUS.INTERVIEW,
    POSITION_STATUS.ONBOARDING,
  ].includes(positionStatus.value);

  const executeAsync = async (fn, successMsg = '', key) => {
    setLoading(true);
    setLoadingKey(key);

    try {
      const response = await fn();

      if (successMsg) {
        addToast.success(successMsg);
      }

      return response;
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      logger.exception(error);

      return false;
    } finally {
      if (isMounted()) {
        setLoading(false);
        setLoadingKey('');
      }
    }
  };

  const redirectToPositionDetail = () => {
    const currentPath = location.pathname;

    // Already on single position page
    if (currentPath.includes('/position/')) return;

    history.push(`/position/${position.id}`);
  };

  const handlePublishClick = async () => {
    // if published -> unpublish
    if (position.isPublished()) {
      await executeAsync(
        () => unpublish(id, positionStatus.id, userId),
        t('unpublishSuccess'),
        ITEM_KEYS.PUBLISH
      );
      redirectToPositionDetail();
      return;
    }

    menuRef.current.closeMenu();
    setPublishModalConfig({
      isOpen: true,
      isViewSummary: false,
      isEdit: false,
    });
    openModal(MODAL.PUBLISH_STATUS);
  };

  const handleSelectWinnerClick = async () => {
    const actualPositionStatus = await executeAsync(
      () => fetchPositionStatus(id),
      null,
      ITEM_KEYS.WINNER
    );

    if (actualPositionStatus !== POSITION_STATUS.PENDING_SELECTION) {
      addToast.error(t('positionNotInSelectionState'));
      return;
    }

    menuRef.current.closeMenu();
    openModal(MODAL.SELECT_WINNER);
  };

  const linkToPage = url => history.push(url);

  const handleDownloadSubmissionsDocs = async () => {
    if (position.getSubmissions().length === 0) {
      addToast.warning(t('noSubmissionsForPosition'));
      return;
    }

    const blob = await executeAsync(
      () => downloadSubmissionsDocuments(position.id),
      null,
      ITEM_KEYS.DOWNLOAD_DOCS
    );

    try {
      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${position.rfqLabel}.zip`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    }
  };

  const handleUploadSubmission = async () => {
    const ableToSubmit = await executeAsync(
      () => isCandidateSubmittableForPosition(id, role),
      null,
      ITEM_KEYS.SUBMIT
    );

    if (!ableToSubmit) {
      addToast.error(t('positionNotPublished'));
      return;
    }

    if (position.isTimeAndMaterials()) {
      linkToPage(`/report/analysis/${report.id}`);
    }
    if (position.isFixedPrice()) {
      linkToPage(`/proposal/create/${position.id}`);
    }
  };

  const checkPositionDueDate = () => {
    if (position.isAfterDueDate() && !hasDueDatePassed) {
      setDueDatePassed(true);
    }
  };

  // Action items
  const uploadSubmissionItem = {
    icon: position.isTimeAndMaterials() ? 'fileAnalysis' : 'proposal',
    id: 'uploadSubmission',
    label: t('uploadSubmission'),
    onClick: handleUploadSubmission,
    stayOpenAfterClick: true,
    loading: isItemLoading(ITEM_KEYS.SUBMIT),
  };

  const editPositionActionItem = {
    icon: 'edit',
    id: 'editPosition',
    onClick: () => linkToPage(`/position/edit/${id}`),
  };

  const downloadSubmissionDocumentsActionItem = {
    icon: 'download',
    id: 'downloadDocuments',
    label: t('downloadSubmissionDocuments'),
    onClick: handleDownloadSubmissionsDocs,
    stayOpenAfterClick: true,
    loading: isItemLoading(ITEM_KEYS.DOWNLOAD_DOCS),
  };

  const cancelPositionActionItem = {
    icon: 'cancel',
    id: 'cancelPosition',
    onClick: () => openModal(MODAL.CANCEL_POSITION),
  };

  const publishPositionItem = {
    icon: 'publish',
    id: 'publishPosition',
    onClick: handlePublishClick,
  };

  const editPublishPositionItem = {
    icon: 'edit',
    id: 'publishEdit',
    onClick: () =>
      setPublishModalConfig({
        isOpen: true,
        isEdit: true,
        isViewSummary: false,
      }),
  };

  const viewPublishInformationItem = {
    icon: 'watch',
    id: 'viewPublishInfo',
    onClick: () =>
      setPublishModalConfig({
        isOpen: true,
        isViewSummary: true,
        isEdit: false,
      }),
  };

  const reselectWinnerMenuItem = {
    icon: 'trophy',
    id: 'reSelectWinner',
    onClick: () => openModal(MODAL.RE_SELECT_WINNER),
  };

  const duplicateItem = {
    icon: 'duplicate',
    id: 'duplicate',
    label: t('duplicate'),
    onClick: () => linkToPage(`/position/duplicate/${id}`),
  };

  const getUnpublishedItems = () => {
    const newSubmissions = filterSubmissionsByStatus(
      position.getSubmissions(role, supplierId),
      SUBMISSION_STATUS.NEW
    );

    return newSubmissions.length > 0 ? [publishPositionItem] : [];
  };

  const getSecondaryMenuItems = () => {
    if (role === SUPPLIER_ROLE) {
      return [];
    }

    if (role === MANAGER_ROLE) {
      if (mainStatus === MAIN_RECRUITING) {
        return [
          editPositionActionItem,
          duplicateItem,
          ...(isPositionCancellable ? [cancelPositionActionItem] : []),
        ];
      }
      if ([MAIN_SELECTION, MAIN_ONBOARDING].includes(mainStatus)) {
        return [
          ...(isPositionCancellable ? [duplicateItem, cancelPositionActionItem] : [duplicateItem]),
        ];
      }

      return [duplicateItem];
    }

    if (role === STAFF_ROLE) {
      if (mainStatus === MAIN_RECRUITING) {
        return [
          ...(!position.isPublished() ? getUnpublishedItems() : []),
          ...(position.isPublished(role, supplierId)
            ? [
                uploadSubmissionItem,
                editPublishPositionItem,
                viewPublishInformationItem,
                {
                  icon: 'publish',
                  id: 'unPublishPosition',
                  onClick: handlePublishClick,
                  stayOpenAfterClick: true,
                  loading: isItemLoading(ITEM_KEYS.PUBLISH),
                },
              ]
            : []),
          downloadSubmissionDocumentsActionItem,
          ...(isPositionCancellable ? [cancelPositionActionItem] : []),
        ];
      }

      if (mainStatus === MAIN_SELECTION) {
        return [
          downloadSubmissionDocumentsActionItem,
          ...(isPositionCancellable ? [cancelPositionActionItem] : []),
        ];
      }

      if (mainStatus === MAIN_ONBOARDING) {
        return [
          reselectWinnerMenuItem,
          downloadSubmissionDocumentsActionItem,
          ...(isPositionCancellable ? [cancelPositionActionItem] : []),
        ];
      }

      if (
        [POSITION_STATUS.NO_SELECTION, POSITION_STATUS.ONBOARDING_CANCELLED].includes(
          positionStatus.value
        )
      ) {
        return [downloadSubmissionDocumentsActionItem, reselectWinnerMenuItem];
      }

      if (
        [POSITION_STATUS.CANCELLED, POSITION_STATUS.NO_SUBMISSIONS].includes(positionStatus.value)
      ) {
        return [
          {
            icon: 'reset',
            id: 'reopen',
            label: t('reopenPosition'),
            onClick: () => openModal(MODAL.RE_OPEN_POSITION),
          },
        ];
      }
    }

    return [];
  };

  const getAllMenuItems = () => {
    const actions = [];

    if (role === SUPPLIER_ROLE) {
      if (mainStatus === MAIN_RECRUITING && position.isPublished(role, supplierId)) {
        actions.push(uploadSubmissionItem);
      }
    }

    if (role === STAFF_ROLE) {
      if (mainStatus === MAIN_RECRUITING) {
        if (!position.isPublished() && !position.hasBeenPublishedBefore()) {
          actions.push(publishPositionItem);
        }
      }

      if (mainStatus === MAIN_SELECTION) {
        if (showSelectWinner(positionStatus, hasDueDatePassed)) {
          actions.push({
            icon: 'trophy',
            id: 'selectWinner',
            onClick: handleSelectWinnerClick,
            stayOpenAfterClick: true,
            loading: isItemLoading(ITEM_KEYS.WINNER),
          });
        }
      }
    }

    return [...actions, ...getSecondaryMenuItems()];
  };

  const getMenuItems = () => {
    if (onlySecondaryActions) {
      return getSecondaryMenuItems();
    }

    return getAllMenuItems();
  };

  return (
    <div className="fs-unmask">
      <IconDropdown
        ref={menuRef}
        menuItems={getMenuItems()}
        iconColor={colors.gray6}
        actionButton={actionButton}
        onOpen={checkPositionDueDate}
      />
      {[STAFF_ROLE, MANAGER_ROLE].includes(role) && isPositionCancellable && (
        <CancelPosition
          isOpen={isModalOpen(MODAL.CANCEL_POSITION)}
          onClose={closeModal}
          position={position}
        />
      )}
      {role === STAFF_ROLE && (
        <React.Fragment>
          {isModalOpen(MODAL.SELECT_WINNER) && (
            <SelectWinner
              isOpen={isModalOpen(MODAL.SELECT_WINNER)}
              hasSubmissions={hasSubmissions(positionStatus)}
              position={position}
              onClose={closeModal}
            />
          )}
          <ReselectWinner
            isOpen={isModalOpen(MODAL.RE_SELECT_WINNER)}
            position={position}
            onClose={closeModal}
          />

          <PublishPosition
            isOpen={publishModalConfig.isOpen}
            position={position}
            isEdit={publishModalConfig.isEdit}
            onClose={() => {
              setPublishModalConfig({
                isOpen: false,
                isViewSummary: false,
                isEdit: false,
              });
            }}
            targetRates={targetRates}
            viewSummary={publishModalConfig.isViewSummary}
          />
          <ReopenPosition
            isOpen={isModalOpen(MODAL.RE_OPEN_POSITION)}
            position={position}
            onClose={closeModal}
          />
        </React.Fragment>
      )}
    </div>
  );
};

PositionSettings.propTypes = propTypes;
PositionSettings.defaultProps = defaultProps;

export default PositionSettings;
