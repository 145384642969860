import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// queries
import { GET_PROJECT_PARTNERS } from 'queries/SearchQueries';
// components
import RadioCustom from 'components/molecules/RadioCustom';
import AutoCompleteSearch from 'components/molecules/AutoCompleteSearch';
import Section from 'components/molecules/FieldSection';
// helpers
import { parseSuppliers } from 'helpers/FormDataParser';
// constants
import { PUBLISH_IDENTIFIERS, PUBLISH_OPTIONS } from './constants';
// styles
import styles from './publishPosition.module.scss';

const propTypes = {
  publishedToAll: PropTypes.bool.isRequired,
  audience: PropTypes.array.isRequired,
  error: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Audience = ({ publishedToAll, audience, onChange, error, projectId, readOnly }) => {
  const { t } = useTranslation();

  const handleOptionChange = item => {
    const isPublishedToAll = item.value === PUBLISH_IDENTIFIERS.ALL_PARTNERS;

    onChange({
      publishedToAll: isPublishedToAll,
      audience: isPublishedToAll ? [] : audience,
    });
  };

  return (
    <Section
      title={t('setPublishAudience')}
      description={t('setPublishAudienceDescription')}
      error={error}
    >
      <RadioCustom
        list={PUBLISH_OPTIONS}
        disabled={readOnly}
        name="publishTo"
        onChange={handleOptionChange}
        selected={publishedToAll ? PUBLISH_IDENTIFIERS.ALL_PARTNERS : PUBLISH_IDENTIFIERS.AUDIENCE}
        className="m-b-15"
      />
      <div className={styles.positionAutocomplete}>
        <AutoCompleteSearch
          disabled={publishedToAll || readOnly}
          customQuery={GET_PROJECT_PARTNERS}
          customQueryVariables={{ projects: [projectId] }}
          customDataParser={parseSuppliers}
          type="suppliersList"
          limitSize={10}
          isMulti
          defaultValue={audience}
          onChange={selectedSuppliers => {
            onChange({
              publishedToAll,
              audience: selectedSuppliers || [],
            });
          }}
          id="supplier"
        />
      </div>
    </Section>
  );
};

Audience.propTypes = propTypes;

export default Audience;
